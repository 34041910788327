<nav class="shadow-class navbar sticky-top navbar-expand-md navbar-light bg-light">
    <div #home class="landing-nav container-fluid">
        <a class="navbar-brand d-flex align-items-center justify-content-center" href="#">
            <img width="50" [src]="sharedService?.companyLogo" alt="Company Logo" class="logo-image">
            <div class="d-flex flex-column p-2">
                <div class="business-header font-20">Creative Hand</div>
                <div class="business-sub-header font-12">Make Your business Digital</div>
            </div>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="d-flex ml-auto flex-basis-50">
                <ul class="navbar-nav">
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="nav-menu cursor-pointer nav-link" aria-current="page" href="#">Home</a>
                    </li>
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="nav-menu cursor-pointer nav-link active">Our Product</a>
                    </li>
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="nav-menu cursor-pointer nav-link" >Contact Us</a>
                    </li>
                </ul>
            </div>
            <div class="ml-auto d-flex align-items-center flex-basis-50 justify-content-end">
                <button class="call-button" style="margin-right: 10px;" mat-button>
                    <mat-icon>call</mat-icon>+91-8208690072</button>
                <button class="whatsapp-button" style="margin-right: 20px" mat-button>
                    <i style="margin-right: 10px; font-size: 18px; color: #128c7e" class="fab fa-whatsapp"></i>+91-9403733265</button>
                <button class="setting-icon-mobile" [matMenuTriggerFor]="menu" mat-mini-fab color="primary" aria-label="Example icon button with a filter list icon">
                    <mat-icon>settings</mat-icon>
                </button>
            </div>
        </div>
    </div>
</nav>


<mat-menu #menu="matMenu">
    <button (click)="goToLogin()" mat-menu-item>
        <mat-icon color="primary">account_circle</mat-icon>Log In</button>
</mat-menu>

<div class="product-container">
    <div class="col-lg-12 row">
        <div class="col-lg-6">
            <div class="pg-left">
                <div class="page-title">
                    <!-- <h1 ><span>Create</span> Your Business Mini Website</h1> -->
                    <h1 class="animate__animated animate__bounceInLeft hero-heading">
                        Unlock the <div class="lined">Potential</div> 
                        <br> of Your Business with Mini Websites
                    </h1>
                 </div>
            
                 <div class="animate__animated animate__bounceInLeft sub-text">
                    <div class="font-16">Our Mini Websites offer a comprehensive set of features to boost your business's online visibility.</div> 
                    <div class="showOnMobile col-lg-6">
                        <div class="animate__animated animate__bounceInRight prod-img-bck">
                            <img src="assets/new_ban.jpg" alt="new banner">
                        </div>
                    </div>
                     <hr>
                     Select from 15 captivating Color Combinations, 8 Regional languages, and 3 meticulously crafted Templates to craft a website that represents your brand with perfection.
                     <div class="mt-4 d-flex justify-content-center">
                        <button (click)="scrollToElement('colorPallete')" class="custom-button" mat-raised-button color="primary">
                            Check Templates 
                            <mat-icon class="icon">keyboard_arrow_down</mat-icon>
                        </button>
                     </div>
                 </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="showOnDesktop animate__animated animate__bounceInRight prod-img-bck">
                <img src="assets/new_ban.jpg" alt="">
            </div>
        </div>
    </div>
    <!-- <div #colorPallete class="d-flex justify-content-center">
        <div class="template-container container-fluid py-2">
            <div class="d-flex flex-row flex-nowrap">
                <div *ngFor="let img of templateImages; let i = index"
                    class="card card-body">
                        <img [style.z-index]="99 - i" class="full-width"
                            src="/assets/template-frames/{{img}}" alt="">
                </div>
            </div>
        </div>
    </div> -->
</div>