<!--<div class="uranus-container">-->
<!--    <div class="com-logo-container">-->
<!--        <div class="left-logo-container">-->
<!--            <div class="logo-circle">-->
<!--                <img src="assets/public/sandhyadeeplogomarkremovebgpreview-1@2x.png" alt="">-->
<!--            </div>-->
<!--            <img class="ellipse-icon" alt="" src="assets/public/ellipse-10.svg" />-->
<!--            <div class="group-child2"></div>-->
<!--        </div>-->
<!--        <div class="right-logo-container">-->
<!--            <div class="logo-title"></div>-->
<!--            <div class="own-name">-->
<!--                <div class="title-name"></div>-->
<!--                <div class="title-qualification"></div>-->
<!--            </div>-->
<!--            <div class="add-button-container">-->

<!--            </div>-->
<!--        </div>-->
<!--        <svg class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">-->
<!--            <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z" [attr.fill]="'var(&#45;&#45;secondary-color)'"/>-->
<!--        </svg>-->
<!--    </div>-->
<!--</div>-->

<mat-drawer-container [class]="themeService.currentTheme" class=" uranus-theme-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="push" position="end">
        <mat-nav-list>
            <div class="d-flex justify-content-center pt-4">
                <button [routerLink]="'/login'" mat-raised-button type="submit"
                        class="Update-btn contact-card-button">
                    <mat-icon>person</mat-icon> Customer Login
                </button>
            </div>
            <div class="enquiry-form">
                <h2 class="col-primary text-center">Enquiry Form</h2>
                <form [formGroup]="enquiryForm">
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Full Name</mat-label>
                                <input #customerName maxlength="30" formControlName="name" type="text" matInput placeholder="Enter your name">
                                <mat-hint align="end">{{customerName.value.length}} / 30</mat-hint>
                                <mat-error>Please Enter Your Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Contact Number</mat-label>
                                <input maxlength="10" #mobileNo formControlName="contactNumber" type="text" matInput placeholder="Enter Phone Number">
                                <mat-icon class="col-primary" matSuffix>call</mat-icon>
                                <mat-hint align="end">{{mobileNo.value.length}} / 10</mat-hint>
                                <mat-error>Please Enter Valid Mobile Number</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Email Address</mat-label>
                                <input type="email" matInput formControlName="email" placeholder="Enter your Email">
                                <mat-error>Please Enter Valid Email</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Leave a comment</mat-label>
                                <textarea rows="5" cols="40" #message maxlength="200" formControlName="message" matInput placeholder="What you feel?"></textarea>
                                <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                                <mat-error>Please Enter Your Message</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center">
                    <button [disabled]="enquiryForm.invalid" (click)="onSubmitEnquiry()" mat-raised-button class="enquiry-btn bg-color-primary">
                        Send Enquiry <i class="fa fa-paper-plane flex-center">
                    </i>
                    </button>
                </div>
            </div>
        </mat-nav-list>
    </mat-drawer>
    <mat-toolbar class="sticky-toolbar" [ngClass]="{'position-fixed': isStickToolbar}">
        <div class="d-flex justify-content-between align-items-center full-width">
            <div class="font-16 d-flex">
                <div><img class="business-icon" src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image"></div>
                <div>{{websiteData?.business?.businessName}}</div>
            </div>
            <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
                    aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="d-flex justify-content-center full-width">

        <div class="frame-parent max-width-450">
            <div class="animate__animated  animate__bounceInLeft rectangle-parent">
                <div class="view-container">{{views?.length}} Views</div>
                <div class="rectangle-div"></div>
                <svg *ngIf="selectedTheme === 'lavenderhill'" #lavenderHill class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" style="stop-color: hsl(337, 43%, 41%);"/>
                            <stop offset="100%" style="stop-color: hsl(346, 100%, 11%);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'calmpostcard'" #calmPostcard class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" style="stop-color: var(--primary-color);"/>
                            <stop offset="100%" style="stop-color: rgb(22, 57, 78);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'berrycustard'" #berryCustard class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" style="stop-color: hsl(213, 58%, 36%);"/>
                            <stop offset="100%" style="stop-color: hsl(210, 57%, 28%);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'butterbeer'" #butterBear class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" style="stop-color: hsl(4, 56%, 29%);"/>
                            <stop offset="98%" style="stop-color: hsl(0, 100%, 10%);"/>
                            <stop offset="100%" style="stop-color: hsl(0, 0%, 0%);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'clearday'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" style="stop-color: hsl(10, 87%, 78%);"/>
                            <stop offset="2%" style="stop-color: hsl(10, 82%, 76%);"/>
                            <stop offset="33%" style="stop-color: hsl(8, 60%, 64%);"/>
                            <stop offset="40%" style="stop-color: hsl(8, 58%, 62%);"/>
                            <stop offset="50%" style="stop-color: hsl(7, 56%, 60%);"/>
                            <stop offset="70%" style="stop-color: hsl(5, 47%, 50%);"/>
                            <stop offset="80%" style="stop-color: hsl(4, 49%, 48%);"/>
                            <stop offset="90%" style="stop-color: hsl(3, 51%, 46%);"/>
                            <stop offset="95%" style="stop-color: hsl(2, 54%, 44%);"/>
                            <stop offset="100%" style="stop-color: hsl(1, 57%, 42%);"/>
                            <stop offset="100%" style="stop-color: hsl(0, 59%, 41%);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'orchidjoy'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="39%" style="stop-color: rgba(94, 80, 126, 1);"/>
                            <stop offset="100%" style="stop-color: rgba(34, 25, 52, 1);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'honeybee' || selectedTheme === 'boldcrimson' || selectedTheme === 'midnightember' || selectedTheme === 'eternaltwilight' || selectedTheme === 'pureforest'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
<!--                            <stop offset="10%" style="stop-color: gray"/>-->
                            <stop offset="100%" style="stop-color: #000;"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'riogrande'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="100%" style="stop-color: #353535;"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'bouncyball'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="39%" style="stop-color: rgba(158, 21, 191, 1);"/>
                            <stop offset="100%" style="stop-color: rgba(177,0,219,1);"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <svg *ngIf="selectedTheme === 'bitenlips'" class="rectangle-icon" xmlns="http://www.w3.org/2000/svg" width="405" height="315" viewBox="0 0 405 315" fill="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="39%" style="stop-color: #e31836;"/>
                            <stop offset="100%" style="stop-color: #9e0f24;"/>
                        </linearGradient>
                    </defs>
                    <path d="M0 70C0 31.3401 31.3401 0 70 0H335C373.66 0 405 31.3401 405 70V150.274C405 188.934 373.66 220.274 335 220.274H70C31.3401 220.274 0 251.614 0 290.274V315V70Z"
                          fill="url(#gradient)"/>
                </svg>
                <div class="vect-container">
                    <div class="vector-parent">
                        <svg class="ellipse-icon" width="54" height="54" viewBox="0 0 54 54" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_1_5)">
                                <path d="M47.2016 32.1788C42.0329 43.4793 28.682 48.45 17.3815 43.2813C6.08108 38.1126 1.11034 24.7617 6.27905 13.4613C11.4478 2.16082 24.7987 -2.80992 36.0991 2.35879C47.3995 7.52751 52.3703 20.8784 47.2016 32.1788Z"
                                      [attr.fill]="'var(--secondary-color)'" fill-opacity="0.8" shape-rendering="crispEdges"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_1_5" x="0.23484" y="0.314575" width="53.0109" height="53.0109"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                                   values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="4"/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_5" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                        <div class="logo-circle">
                            <img src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image">
                        </div>
                        <div class="group-child2"></div>
                    </div>
                    <div class="owner-container">
                        <div class="comp">{{websiteData?.business?.businessName}}</div>
                        <div class="own">{{websiteData?.userDetails?.name}}</div>
                        <div class="prof">{{websiteData?.business?.qualification}}</div>
                        <div>
                            <button  id="downloadButtonNormal"
                                     vcdDownloadVCard
                                     [generateVCardFunction]="generateVCardOnTheFly" mat-raised-button class="color-white contact-card-button">
                                <mat-icon>contacts</mat-icon> PhoneBook
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" group-parent">
                <div class="ellipse-parent animate__animated animate__bounce d-flex justify-content-center align-items-center">
                    <button (click)="onCallAction()" class="contact-icons-container" mat-mini-fab color="var(--primary-color)"
                            aria-label="Example icon button with a menu icon">
                        <mat-icon>call</mat-icon>
                    </button>
                </div>
                <div class="ellipse-parent animate__animated animate__bounce d-flex justify-content-center align-items-center">
                    <button (click)="initiateWhatsapp()" class="contact-icons-container" mat-mini-fab color="var(--primary-color)"
                            aria-label="Example icon button with a menu icon">
                        <i class="font-25 fab fa-whatsapp"></i>
                    </button>
                </div>
                <div *ngIf="websiteData.socialMediaLinks.instagram" class="ellipse-parent animate__animated animate__bounce d-flex justify-content-center align-items-center">
                    <button (click)="instagramLink()" class="contact-icons-container" mat-mini-fab color="var(--primary-color)"
                            aria-label="Example icon button with a menu icon">
                        <i class="font-25 fab fa-instagram"></i>
                    </button>
                </div>
                <div *ngIf="websiteData.socialMediaLinks.facebook" class="ellipse-parent animate__animated animate__bounce d-flex justify-content-center align-items-center">
                    <button (click)="facebookLink()" class="contact-icons-container" mat-mini-fab color="var(--primary-color)"
                            aria-label="Example icon button with a menu icon">
                        <i class="font-25 fab fa-facebook"></i>
                    </button>
                </div>
            </div>

            <div *ngIf="websiteData?.business?.estYear || websiteData?.business?.businessType" class="animate__animated animate__fadeInLeft about-us-container">
                <div class="abt-us-main">
                    <div class="abt-title">
                        Information
                    </div>
                    <div *ngIf="websiteData?.business?.estYear" class="d-flex align-items-center mb-3">
                        <div>
                            <div class="abt-us-icon d-flex justify-content-center align-items-center">
                                <i class="fa fa-suitcase"></i>
                            </div>
                        </div>
                        <div class="abt-us-cont d-flex flex-column m-l-1">
                            <div class="header">Year of Establishment</div>
                            <div class="abt-us-title-subtitle">{{websiteData?.business?.estYear}}</div>
                        </div>
                    </div>
                    <div *ngIf="websiteData?.business?.businessType" class="d-flex align-items-center mb-3">
                        <div>
                            <div class="abt-us-icon d-flex justify-content-center align-items-center">
                                <i class="fa fa-industry"></i>
                            </div>
                        </div>
                        <div class="abt-us-cont d-flex flex-column m-l-1">
                            <div class="header">Business Type</div>
                            <div class="abt-us-title-subtitle">{{websiteData?.business?.businessType}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-content">
                <div *ngFor="let contact of websiteData?.business?.contactNumbers" class="section-tab">
                    <div class="section-tab-container">
                        <div class="section-tab-content">
                            <div class="sec-tab-header"> Contact Number</div>
                            <div class="sec-tab-details">{{contact.number}}</div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center circle-wrapper">
                            <div class="d-flex justify-content-center align-items-center inner-circle">
                                <i class="color-white font-25 fa fa-phone"></i>
                            </div>
                        </div>
                        <svg class="contact-sect-small-dot" width="25" height="26" viewBox="0 0 25 26" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 13C25 20.1797 19.4036 26 12.5 26C5.59644 26 0 20.1797 0 13C0 5.8203 5.59644 0 12.5 0C19.4036 0 25 5.8203 25 13Z"/>
                        </svg>
                    </div>
                </div>
                <div *ngIf="websiteData?.business?.email" class="section-tab">
                    <div class="section-tab-container">
                        <div class="section-tab-content">
                            <div class="sec-tab-header"> Email Address</div>
                            <div class="sec-tab-details">{{websiteData?.business?.email}}</div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center circle-wrapper">
                            <div class="d-flex justify-content-center align-items-center inner-circle">
                                <i class="color-white font-25 fa fa-envelope"></i>
                            </div>
                        </div>
                        <svg class="contact-sect-small-dot" width="25" height="26" viewBox="0 0 25 26" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 13C25 20.1797 19.4036 26 12.5 26C5.59644 26 0 20.1797 0 13C0 5.8203 5.59644 0 12.5 0C19.4036 0 25 5.8203 25 13Z"
                                  [attr.fill]="'var(--secondary-color)'"/>
                        </svg>
                    </div>
                </div>
                <div *ngIf="websiteData?.business?.website" class="section-tab">
                    <div class="section-tab-container">
                        <div class="section-tab-content">
                            <div class="sec-tab-header"> Website</div>
                            <div class="sec-tab-details">
                                <a [attr.href]="websiteData?.business?.website">
                                    {{websiteData?.business?.website}}
                                </a>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center circle-wrapper">
                            <div class="d-flex justify-content-center align-items-center inner-circle">
                                <i class="color-white font-25 fa fa-globe"></i>
                            </div>
                        </div>
                        <svg class="contact-sect-small-dot" width="25" height="26" viewBox="0 0 25 26" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 13C25 20.1797 19.4036 26 12.5 26C5.59644 26 0 20.1797 0 13C0 5.8203 5.59644 0 12.5 0C19.4036 0 25 5.8203 25 13Z"
                                  [attr.fill]="'var(--secondary-color)'"/>
                        </svg>
                    </div>
                </div>
                <div *ngFor="let link of websiteData?.business?.otherLinks" class="section-tab">
                    <div class="section-tab-container">
                        <div class="section-tab-content">
                            <div class="sec-tab-header"> Other Link</div>
                            <div class="sec-tab-details">
                                <a [attr.href]="link">
                                    {{link}}
                                </a>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center circle-wrapper">
                            <div class="d-flex justify-content-center align-items-center inner-circle">
                                <i class="color-white font-25 fa fa-link"></i>
                            </div>
                        </div>
                        <svg class="contact-sect-small-dot" width="25" height="26" viewBox="0 0 25 26" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 13C25 20.1797 19.4036 26 12.5 26C5.59644 26 0 20.1797 0 13C0 5.8203 5.59644 0 12.5 0C19.4036 0 25 5.8203 25 13Z"
                                  [attr.fill]="'var(--secondary-color)'"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div *ngIf="websiteData?.whatsappCatalogue" class="d-flex justify-content-center p-4">
                <div (click)="openWhatsAppCatalogue()" class="whatsapp-button">
                    <img width="30" src="assets/whatsapp_logo.png" alt="Business Image">
                    Open WhatsApp Catalogue
                </div>
            </div>
            <div class="share-buttons-container" [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(190)}" >
                <div class="d-flex justify-content-around">
                    <share-buttons theme="circles-light share-button"
                                   [include]="['copy', 'whatsapp', 'facebook', 'linkedin']"
                                   [showIcon]="true"
                                   [showText]="true"
                                   [url]="shareLink"
                                   [description]="pageDescription" class="share-button-list">
                    </share-buttons>
                </div>
               <div class="d-flex justify-content-around">
                   <share-buttons theme="circles-light share-button"
                                  [include]="['messenger', 'email', 'sms', 'telegram', ]"
                                  [showIcon]="true"
                                  [showText]="true"
                                  [url]="shareLink"
                                  [description]="pageDescription" class="share-button-list">
                   </share-buttons>
               </div>
                <div class="d-flex justify-content-around">
                   <share-buttons theme="circles-light share-button"
                                  [include]="[ 'twitter', 'print', 'pinterest', 'reddit']"
                                  [showIcon]="true"
                                  [showText]="true"
                                  [url]="shareLink"
                                  [description]="pageDescription" class="share-button-list">
                   </share-buttons>
               </div>
            </div>
            <div *ngIf="websiteData?.address?.addressLine || websiteData?.address?.addressMapLink" class="address-content">
                <div class="addr-bl-1">
                    <div class="addr-title">Address</div>
                    <div *ngIf="websiteData?.address?.addressMapLink" class="map-frame" [innerHTML]="mapSrc"></div>
                    <div *ngIf="websiteData?.address?.addressLine" class="addr-cont">
                        {{websiteData?.address?.addressLine}}
                    </div>
                </div>
                <div class="addr-bl-2">
                </div>
            </div>

            <div *ngIf="htmlContent" class="specialities-container pt-4">
                <div class="specialities-main">
                    <div class="specialities-title text-center">
                        Our Specialities
                    </div>
                    <div class="specialities-cont d-flex align-items-center mb-3">
                        <div [innerHtml]="htmlContent"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="websiteData?.upiPayments && websiteData?.upiPayments?.length"
                 class="upi-details-container">
                <div class="upi-main">
                    <div class="upi-title text-center">
                        UPI Details
                    </div>
                    <div class="about-us-content">
                        <div *ngFor="let payment of websiteData?.upiPayments" class="about-us-tab-container">
                            <div class="d-flex full-width">
                                <div class="about-us-tab-image">
                                    <img class="phone-pe-icon" [src]="payment?.imageUrl" alt="phone pe">
                                </div>
                                <div class="about-us-tab-content">
                                    <div class="sec-tab-header">{{payment?.upiUserName}}</div>
                                    <div class="sec-tab-details">{{payment?.upiContact}}</div>
                                </div>
                            </div>
                            <div (click)="sharedService.copyToClipboard(payment?.upiContact)" class="copy-icon">
                                <mat-icon class="d-flex align-items-center mat-icon">content_copy</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="galleryImages && galleryImages.length" class="photo-gallery">
                <div class="gallery-title">Photo Gallery</div>
                <section class="gallery">
                    <div *ngFor="let pic of galleryImages" class="image">
                        <img [src]="pic?.url" alt="image"/>
                    </div>
                </section>
            </div>
            <div *ngIf="websiteData?.products && websiteData?.products.length"
                   style="padding: 0 30px 0 0; width: 100%; margin: 10px 20px" class="d-flex flex-column">
                <!--            <div class="d-flex flex-row flex-nowrap">-->
                <div *ngFor="let product of websiteData.products; let index = index" class="portfolio-card">
                    <img *ngIf="product?.productImageUrl" class="card-img-top" [src]="product?.productImageUrl" alt="Card image">
                    <div style="color: var(--primary-color); padding: 10px" class="card-body">
                        <div>
                            <div *ngIf="product?.productName" class="portfolio-title text-center">{{product?.productName}}</div>
                            <hr *ngIf="product?.productName">
                            <p *ngIf="product?.productDescription" class="portfolio-description">{{product?.productDescription}}</p>
                        </div>
                        <!--                        <hr>-->
                        <div class="d-flex justify-content-around">
                            <button (click)="getProductCard(product?._id)" class="contact-card-button" style="margin-right: 10px" mat-raised-button color="primary">
                                <mat-icon>download</mat-icon>
                                Download
                            </button>
                            <button *ngIf="product?.productName" (click)="getEnquiry(product?.productName)" class="contact-card-button" mat-raised-button color="primary">
                                <mat-icon>help_outline</mat-icon>
                                Enquiry
                            </button>
                        </div>
                        <!--                        <a href="#" class="btn btn-primary">See Profile</a>-->
                    </div>
                </div>
            </div>
            <div *ngIf="sharedService.hasNoEmptyValues(websiteData?.bankDetails)" [ngClass]="{'animate__animated animate__fadeInUp': applyAnimOnScroll(1250)}" style="padding: 20px">
                <app-bank-details-card class="full-width" [bankDetails]="websiteData?.bankDetails"></app-bank-details-card>
            </div>
            <ng-container *ngIf=" websiteData?.videoLinks && websiteData?.videoLinks?.length" >
                <div  *ngFor="let video of websiteData?.videoLinks" class="d-flex justify-content-center youtube-video-container">
                    <youtube-player [width]="330" [height]="180" [videoId]="video.videoId"></youtube-player>
                </div>
            </ng-container>
            <div [ngClass]="{'animate__animated animate__fadeInUp': applyAnimOnScroll(1450)}">
                <app-feedback-form [businessId]="businessId"></app-feedback-form>
            </div>
            <mat-toolbar class="sticky-bottom-toolbar" [ngClass]="{'position-bottom-fixed': isStickToolbar}">
                <div class="bottom-toolbar">
                    © 2023 Creative Hand
                </div>
            </mat-toolbar>
        </div>
    </div>

</mat-drawer-container>
