<div style="padding: 30px 10px 30px 30px;">
    <div class="add-title">
        <h1 class="primary">Edit User</h1>
    </div>
    <hr>
    <div>
        <form [formGroup]="registrationForm" class="registration-form">
            <div class="logo-container">
                <img [src]="sharedService?.companyLogoAnimation" alt="Company Logo" class="logo-image">
            </div>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" type="text" placeholder="Enter your name">
                <mat-error *ngIf="registrationForm.get('name').invalid && registrationForm.get('name').touched">
                    Please enter a valid name
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" type="email" placeholder="Enter your email">
                <mat-error *ngIf="registrationForm.get('email').invalid && registrationForm.get('email').touched">
                    Please enter a valid email
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Contact Number</mat-label>
                <input maxlength="10" matInput formControlName="contactNumber" type="text" placeholder="Enter your Contact Number">
                <mat-error *ngIf="registrationForm.get('contactNumber').invalid && registrationForm.get('contactNumber').touched">
                    Please enter a valid Contact Number
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Role</mat-label>
                <mat-select formControlName="role" placeholder="Select a role">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="registrationForm.get('role').invalid && registrationForm.get('role').touched">
                    Please select a role
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Password</mat-label>
                <input maxlength="10" matInput formControlName="password" type="password" placeholder="Enter your password">
                <mat-error *ngIf="registrationForm.get('password').invalid && registrationForm.get('password').touched">
                    Password should have special characters, number and upper case or lower case character
                </mat-error>
            </mat-form-field>

            <div class="d-flex justify-content-center align-items-center">
                <button mat-raised-button color="primary" (click)="onSubmit()" type="submit">Register</button>
            </div>
        </form>
    </div>
</div>
