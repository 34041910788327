<div *ngIf="customerFeedback && customerFeedback.length" class="feedbacks">
    <div *ngFor="let feedback of customerFeedback" class="single-feedback">
        <div class="header d-flex justify-content-between align-items-center">
            <div>{{ feedback.name | titlecase }}</div>
            <p class="date">{{ feedback.createdAt | date }}</p>
        </div>
        <div class="content">
            <div class="rating">
                <ng-container *ngFor="let star of getRatingStars(feedback.rating)">
                    <span class="rating-icon">★</span>
                </ng-container>
            </div>
            <hr>
            <p class="description">{{ feedback.feedback }}</p>
        </div>
    </div>
</div>
<div class="section-content feedback-content">
    <div class="customer-review-container">
        <div class="emoji-rating-container">
            <div (click)="onSelectRating(1)" class="single-emoji-container">
                <div *ngIf="selectedFeedback === 1; else elseBlock1">
                    <svg [attr.width]="selectedFeedback === 1 ? 55 : 44" [attr.height]="selectedFeedback === 1 ? 55 : 44" viewBox="0 0 44 44" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2_33)">
                            <path d="M16.5 18.3333H16.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 18.3333H27.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.4167 27.9583C18.0141 27.3486 18.7272 26.8642 19.5142 26.5335C20.3012 26.2028 21.1463 26.0324 22 26.0324C22.8537 26.0324 23.6988 26.2028 24.4858 26.5335C25.2728 26.8642 25.9859 27.3486 26.5833 27.9583"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M32.2043 32.2777C31.7043 32.7922 31.3661 33.4422 31.2318 34.147C31.0976 34.8519 31.173 35.5806 31.4489 36.243C31.7248 36.9053 32.1889 37.4722 32.7838 37.8733C33.3788 38.2745 34.0783 38.4922 34.7958 38.4997C35.5132 38.5071 36.2171 38.3039 36.8202 37.9151C37.4233 37.5264 37.8991 36.9693 38.1886 36.3128C38.4782 35.6562 38.5687 34.9292 38.4491 34.2217C38.3294 33.5143 38.0048 32.8575 37.5155 32.3327L34.8333 29.3333L32.2043 32.2777V32.2777Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M38.2525 24.7812C38.414 23.8629 38.4968 22.9324 38.5 22C38.5 18.7366 37.5323 15.5465 35.7193 12.8331C33.9062 10.1197 31.3293 8.00484 28.3143 6.756C25.2993 5.50715 21.9817 5.18039 18.781 5.81705C15.5803 6.45371 12.6403 8.02518 10.3327 10.3327C8.02518 12.6403 6.45371 15.5803 5.81705 18.781C5.18039 21.9817 5.50715 25.2993 6.756 28.3143C8.00484 31.3293 10.1197 33.9062 12.8331 35.7193C15.5465 37.5323 18.7366 38.5 22 38.5C23.265 38.5 24.4933 38.3607 25.6667 38.093"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2_33">
                                <rect width="44" height="44" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ng-template #elseBlock1>
                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2_33)">
                                <path d="M16.5 18.3333H16.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M27.5 18.3333H27.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M17.4167 27.9583C18.0141 27.3486 18.7272 26.8642 19.5142 26.5335C20.3012 26.2028 21.1463 26.0324 22 26.0324C22.8537 26.0324 23.6988 26.2028 24.4858 26.5335C25.2728 26.8642 25.9859 27.3486 26.5833 27.9583"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M32.2043 32.2777C31.7043 32.7922 31.3661 33.4422 31.2318 34.147C31.0976 34.8519 31.173 35.5806 31.4489 36.243C31.7248 36.9053 32.1889 37.4722 32.7838 37.8733C33.3788 38.2745 34.0783 38.4922 34.7958 38.4997C35.5132 38.5071 36.2171 38.3039 36.8202 37.9151C37.4233 37.5264 37.8991 36.9693 38.1886 36.3128C38.4782 35.6562 38.5687 34.9292 38.4491 34.2217C38.3294 33.5143 38.0048 32.8575 37.5155 32.3327L34.8333 29.3333L32.2043 32.2777V32.2777Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M38.2525 24.7812C38.414 23.8629 38.4968 22.9324 38.5 22C38.5 18.7366 37.5323 15.5465 35.7193 12.8331C33.9062 10.1197 31.3293 8.00484 28.3143 6.756C25.2993 5.50715 21.9817 5.18039 18.781 5.81705C15.5803 6.45371 12.6403 8.02518 10.3327 10.3327C8.02518 12.6403 6.45371 15.5803 5.81705 18.781C5.18039 21.9817 5.50715 25.2993 6.756 28.3143C8.00484 31.3293 10.1197 33.9062 12.8331 35.7193C15.5465 37.5323 18.7366 38.5 22 38.5C23.265 38.5 24.4933 38.3607 25.6667 38.093"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2_33">
                                    <rect width="44" height="44" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </ng-template>
                <div [ngClass]="{'emoji-color': selectedFeedback === 1}"
                     class="single-emoji-title">
                    Terrible
                </div>
            </div>
            <div (click)="onSelectRating(2)" class="single-emoji-container">
                <div *ngIf="selectedFeedback === 2; else elseBlock2">
                    <svg [attr.width]="selectedFeedback === 2 ? 55 : 44" [attr.height]="selectedFeedback === 2 ? 55 : 44" viewBox="0 0 44 44" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2_89)">
                            <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M16.5 18.3333H16.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 18.3333H27.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.4167 27.9583C18.0141 27.3486 18.7272 26.8642 19.5142 26.5335C20.3012 26.2028 21.1463 26.0324 22 26.0324C22.8537 26.0324 23.6987 26.2028 24.4858 26.5335C25.2728 26.8642 25.9859 27.3486 26.5833 27.9583"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2_89">
                                <rect width="44" height="44" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ng-template #elseBlock2>
                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2_89)">
                                <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 18.3333H16.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M27.5 18.3333H27.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M17.4167 27.9583C18.0141 27.3486 18.7272 26.8642 19.5142 26.5335C20.3012 26.2028 21.1463 26.0324 22 26.0324C22.8537 26.0324 23.6987 26.2028 24.4858 26.5335C25.2728 26.8642 25.9859 27.3486 26.5833 27.9583"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2_89">
                                    <rect width="44" height="44" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </ng-template>
                <div [ngClass]="{'emoji-color': selectedFeedback === 2}"
                     class="single-emoji-title">
                    Bad
                </div>
            </div>
            <div (click)="onSelectRating(3)" class="single-emoji-container">
                <div *ngIf="selectedFeedback === 3; else elseBlock3">
                    <svg [attr.width]="selectedFeedback === 3 ? 55 : 44" [attr.height]="selectedFeedback === 3 ? 55 : 44" viewBox="0 0 44 44" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2_95)">
                            <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M16.5 18.3333H16.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 18.3333H27.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.5 27.5H27.5" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2_95">
                                <rect width="44" height="44" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ng-template #elseBlock3>
                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2_95)">
                                <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 18.3333H16.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M27.5 18.3333H27.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M16.5 27.5H27.5" [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2_95">
                                    <rect width="44" height="44" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </ng-template>
                <div [ngClass]="{'emoji-color': selectedFeedback === 3}"
                     class="single-emoji-title">
                    Okay
                </div>
            </div>
            <div (click)="onSelectRating(4)" class="single-emoji-container">
                <div *ngIf="selectedFeedback === 4; else elseBlock4">
                    <svg [attr.width]="selectedFeedback === 4 ? 55 : 44" [attr.height]="selectedFeedback === 4 ? 55 : 44" viewBox="0 0 44 44" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2_101)">
                            <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M16.5 18.3333H16.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 18.3333H27.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.4167 27.5C18.0141 28.1098 18.7272 28.5942 19.5142 28.9249C20.3012 29.2556 21.1463 29.4259 22 29.4259C22.8537 29.4259 23.6987 29.2556 24.4858 28.9249C25.2728 28.5942 25.9859 28.1098 26.5833 27.5"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2_101">
                                <rect width="44" height="44" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ng-template #elseBlock4>
                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2_101)">
                                <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 18.3333H16.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M27.5 18.3333H27.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M17.4167 27.5C18.0141 28.1098 18.7272 28.5942 19.5142 28.9249C20.3012 29.2556 21.1463 29.4259 22 29.4259C22.8537 29.4259 23.6987 29.2556 24.4858 28.9249C25.2728 28.5942 25.9859 28.1098 26.5833 27.5"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2_101">
                                    <rect width="44" height="44" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </ng-template>
                <div [ngClass]="{'emoji-color': selectedFeedback === 4}"
                     class="single-emoji-title">
                    Good
                </div>
            </div>
            <div (click)="onSelectRating(5)" class="single-emoji-container">
                <div *ngIf="selectedFeedback === 5; else elseBlock5">
                    <svg [attr.width]="selectedFeedback === 5 ? 55 : 44" [attr.height]="selectedFeedback === 5 ? 55 : 44" viewBox="0 0 44 44" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2_107)">
                            <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M16.5 16.5H16.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 16.5H27.5183" stroke="#FFB300" stroke-width="2.75"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.3334 23.8333H14.6667M14.6667 23.8333C14.6667 25.7783 15.4393 27.6435 16.8146 29.0188C18.1898 30.3941 20.0551 31.1667 22 31.1667C23.9449 31.1667 25.8102 30.3941 27.1855 29.0188C28.5607 27.6435 29.3334 25.7783 29.3334 23.8333H14.6667Z"
                                  stroke="#FFB300" stroke-width="2.75" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2_107">
                                <rect width="44" height="44" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <ng-template #elseBlock5>
                    <div>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2_107)">
                                <path d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 16.5H16.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M27.5 16.5H27.5183" [style.stroke]="'var(--feedback-icon-color)'"
                                      stroke-width="2.75" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M29.3334 23.8333H14.6667M14.6667 23.8333C14.6667 25.7783 15.4393 27.6435 16.8146 29.0188C18.1898 30.3941 20.0551 31.1667 22 31.1667C23.9449 31.1667 25.8102 30.3941 27.1855 29.0188C28.5607 27.6435 29.3334 25.7783 29.3334 23.8333H14.6667Z"
                                      [style.stroke]="'var(--feedback-icon-color)'" stroke-width="2.75"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2_107">
                                    <rect width="44" height="44" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </ng-template>
                <div [ngClass]="{'emoji-color': selectedFeedback === 5}"
                     class="single-emoji-title">
                    Amazing
                </div>
            </div>
        </div>
        <hr>
        <div class="rating-form">
            <div class="rating-textarea">
                <form [formGroup]="ratingForm">
                    <div class="pb-3">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Customer Name</mat-label>
                            <input #customerName maxlength="30" formControlName="name" type="text" matInput placeholder="Enter your name">
                            <mat-hint class="mat-hint-feedback" align="end">{{customerName.value.length}} / 30</mat-hint>
                            <mat-error>Please Enter Your Name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pb-3">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Customer Mobile Number</mat-label>
                            <input #mobileNo maxlength="10" formControlName="contactNumber" type="text" matInput placeholder="Enter your Mobile Number">
                            <mat-hint class="mat-hint-feedback" align="end">{{mobileNo.value.length}} / 10</mat-hint>
                            <mat-error>Please Enter Valid Mobile Number</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pb-3">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Feedback</mat-label>
                            <textarea rows="5" cols="40" #feedback maxlength="200" formControlName="feedback" matInput placeholder="What you feel?"></textarea>
                            <mat-hint class="mat-hint-feedback" align="end">{{feedback.value.length}} / 200</mat-hint>
                            <mat-error>Please Enter Your Feedback</mat-error>
                        </mat-form-field>
                    </div>
                </form>
                <div class="flex-center">
                    <button [disabled]="ratingForm.invalid" (click)="onSubmitRating()"
                            mat-raised-button
                            class="feedback-button width-200-px bg-color-primary">
                        Enter Feedback <i class="fa fa fa-comment-alt flex-center"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
