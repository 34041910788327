import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  Inject, PLATFORM_ID
} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from "@kolkov/ngx-gallery";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../../services/api.service";
import {FormBuilder, Validators} from "@angular/forms";
import {SharedService} from "../../services/shared.service";
import {ThemeService} from "../../services/themes.service";
import {PhoneValidator} from "../../custom-validators/phone-number-validation";
import {EmailValidator} from "../../custom-validators/email-validation";
import { saveAs } from 'file-saver';
declare let particlesJS: any;
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-theme-selene',
  templateUrl: './theme-selene.component.html',
  styleUrls: ['./theme-selene.component.scss']
})
export class ThemeSeleneComponent implements OnInit, OnChanges, AfterViewInit{
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  backgroundColor: any = '';
  toolBarColor: any = '';
  isStickToolbar = false;
  animateDiv1 = false;
  animateDiv2 = false;
  animateDiv3 = false;
  animateDiv4 = false;
  animateDiv5 = false;
  primaryColor: any = '#225675';
  mapSrc: SafeHtml = '';
  htmlContent: SafeHtml = '';
  contactNumbers: any = [];
  // websiteData: any = [];
  userDetails: any;
  pageDescription: any;
  whatsappNumber: any = '';
  businessId: any = '';
  shareLink: any = '';
  selectedTheme: any = '';
  themeDetails: any;
  currentScrollNumber: any;
  @Input() businessUrl: any;
  @Input() websiteData: any = [];
  @Input() views: any = [];
  @Input() phoneBookDetails: any = null;
  @Output() submitEnquiry = new EventEmitter<any>();
  @Output() submitFeedBack = new EventEmitter<any>();
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined' && typeof window !== 'undefined') {
      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isStickToolbar = number >= 38;
      this.currentScrollNumber = number;
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId: object, private snackBar: MatSnackBar, private elementRef: ElementRef, private renderer: Renderer2,
              private apiService: ApiService, private formBuilder: FormBuilder, public sharedService: SharedService, public themeService: ThemeService, private sanitizer: DomSanitizer) {

  }

  enquiryForm = this.formBuilder.group({
    businessId: [''],
    name: ['', Validators.required],
    contactNumber: ['', [Validators.required, PhoneValidator()]],
    email: ['', [EmailValidator(), Validators.required]],
    message: ['', Validators.required],
  });

  applyAnimOnScroll(numberVal ) {
    return (this.currentScrollNumber >= numberVal);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      const canvas = this.elementRef.nativeElement.querySelector('#particles-js-canvas-el');
      if(canvas) {
        const resizeCanvas = () => {
          canvas.width = 'inherit';
          canvas.height = window.innerHeight;
        };
  
        // Call the resizeCanvas function initially
        resizeCanvas();
  
        // Call the resizeCanvas function whenever the window is resized
        this.renderer.listen('window', 'resize', resizeCanvas);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'websiteData' : {
            if(this.websiteData) {
              this.shareLink = this.sharedService.generatePrototypeUrl(this.websiteData.keywordUrl);
              if(this.websiteData && Object.keys(this.websiteData).length > 0) {
                this.userDetails = this.websiteData.userDetails;
                this.themeService.applyTheme(this.websiteData.theme);
                this.selectedTheme = this.websiteData.theme.toLowerCase();
                this.getTheme();
                if(this.websiteData.business) {
                  this.pageDescription = `Check out ${this.websiteData.business.businessName} - your destination for exceptional products/services. Share this link and spread the word of success.`;
                  this.websiteData.business.businessImageUrl = this.websiteData.business.businessImageUrl ?
                      this.sharedService.generateImageUrl(this.websiteData.business.businessImageUrl) :
                      this.sharedService.noImageIcon;
                }

                if(this.websiteData.products) {
                  this.websiteData.products = this.websiteData.products.map((val) => {
                    return {
                      ...val, productImageUrl: this.sharedService.generateImageUrl(val.productImageUrl)
                    }
                  })
                }

                if(this.websiteData.upiPayments) {
                  this.websiteData.upiPayments = this.websiteData.upiPayments.map((val) => {
                    val.imageUrl = `assets/${val.upiType.toLowerCase().split(' ').join('-')}.png`;
                    return val;
                  });
                }

                if (this.websiteData) {
                  this.enquiryForm.get('businessId').setValue(this.websiteData._id);
                  this.businessId = this.websiteData._id;
                }

                if (this.websiteData && this.websiteData.address && this.websiteData.address.addressMapLink) {
                  this.mapSrc = this.sanitizer.bypassSecurityTrustHtml(this.decodeHtmlString(this.websiteData.address.addressMapLink));
                }
                if(this.websiteData.specialitiesData) {
                  this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.decodeHtmlString(this.websiteData.specialitiesData));
                }
                if (this.websiteData && this.websiteData.portfolioImages) {
                  this.galleryImages = this.websiteData.portfolioImages.map((val) => {
                    return {
                      url: this.sharedService.generateImageUrl(val),
                      small: this.sharedService.generateImageUrl(val),
                      medium: this.sharedService.generateImageUrl(val),
                      big: this.sharedService.generateImageUrl(val)
                    }
                  });
                }

                if (this.websiteData && this.websiteData.business && this.websiteData.business.contactNumbers) {
                  this.contactNumbers = this.websiteData.business.contactNumbers.map((val) => {
                    return val.number;
                  });
                  this.whatsappNumber = this.websiteData.business.contactNumbers.find(v => !!v.isPrimary).number;
                }
              }
            }
          }
        }
      }
    }
  }


  decodeHtmlString(htmlString: string): string {
    const parser = new DOMParser();
    const decodedHtml = parser.parseFromString(htmlString, 'text/html');
    return decodedHtml.documentElement.textContent;
  }

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
    this.galleryOptions = [
      {
        breakpoint: 800,
        width: '100%',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        preview: true,
        imageAutoPlay: true,
        previewArrows: false,
        previewAutoPlay: true,
        previewAutoPlayPauseOnHover: true
      },
    ];
  }

  getTheme() {
    this.themeService.fetchThemes().then(themes => {
      if (themes) {
        this.themeDetails = themes.find(t => t.keyword.toLowerCase().trim() === this.selectedTheme);
        this.getParticles();
      }
    })
  }

  getParticles() {
    this.themeService.fetchParticles().then((particleJSON: any) => {
      console.log(this.themeDetails);
      console.log(particleJSON);
      // const particleOptions = {
      //   particles: {
      //     number: {
      //       value: 100, // Adjust the number of particles as needed
      //       density: {
      //         enable: true,
      //         value_area: 800 // Increase or decrease the value to adjust particle density
      //       }
      //     },
      //     line_linked: {
      //       enable: false,
      //     },
      //     color: {
      //       value: '#fdff01' // Set the color of the particles
      //     },
      //     shape: {
      //       type: 'circle' // Set the shape of the particles (e.g., 'circle', 'triangle', 'polygon')
      //     },
      //     opacity: {
      //       value: 0.5, // Set the opacity of the particles
      //       random: true, // Enable random opacity values
      //       anim: {
      //         enable: true,
      //         speed: 1, // Adjust the speed of the opacity animation
      //         opacity_min: 0.1, // Set the minimum opacity value during the animation
      //         sync: false // Allow particles to have different opacity values at the same time
      //       }
      //     },
      //     size: {
      //       value: 3 // Set the size of the particles
      //     },
      //     move: {
      //       enable: true,
      //       speed: 2, // Adjust the speed of the particle movement
      //       direction: 'none', // Set the direction of movement (e.g., 'none', 'top', 'top-right', 'right', etc.)
      //       random: true, // Enable random movement direction
      //       straight: false, // Allow particles to move in straight lines rather than curves
      //       out_mode: 'out', // Define how particles behave when they move out of the canvas
      //       bounce: false // Disable particle bouncing
      //     }
      //   },
      //   interactivity: {
      //     detect_on: 'canvas',
      //     events: {
      //       onhover: {
      //         enable: true,
      //         mode: 'repulse' // Set the interaction mode on hover (e.g., 'grab', 'bubble', 'repulse')
      //       }
      //     }
      //   }
      // };
      const updatedParticles = this.updateParticles(particleJSON, this.themeDetails);
      this.initializeParticles(updatedParticles);
    });
  }

  updateParticles(particles, themeDetails) {
    let updatedParticles = {...particles};
    const secondaryColor = themeDetails.colors['--secondary-color'];
    const primaryColor = themeDetails.colors['--primary-color'];
    this.backgroundColor = themeDetails.seleneThemeBackground;
    this.toolBarColor = themeDetails.seleneThemeBackground.includes('fff') ? primaryColor : '#fff';
    updatedParticles.particles.color.value = secondaryColor;
    return updatedParticles;
  }

  initializeParticles(config) {
    particlesJS('particles-js', config, function() {});
  }

  public generateVCardOnTheFly = (): any  => {
    const { businessName, qualification, address } = this.websiteData.business;
    const { name, organisation, qualification: phoneBookQualification, address: phoneBookAddress } = this.phoneBookDetails || {};
    const formattedName = name || this.userDetails.name;
    const { firstName, lastName } = this.sharedService.getFirstNameAndLastName(formattedName);
    return {
      formattedName: { firstNames: formattedName },
      name: { firstNames: firstName, lastNames: lastName },
      organization: organisation || businessName,
      title: phoneBookQualification || qualification,
      address: [
        {
          street: phoneBookAddress || (address && address.addressLine) || '',
          countryName: 'India',
        },
      ],
      telephone: this.contactNumbers,
      email: [
        {
          value: this.userDetails.email,
          param: { type: 'work' },
        },
      ],
      socialUrls: {
        facebook: this.websiteData.socialMediaLinks.facebook,
        linkedin: this.websiteData.socialMediaLinks.linkedin,
        instagram: this.websiteData.socialMediaLinks.instagram,
      },
    };
  };


  onCallAction() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.location.href = 'tel:' + this.whatsappNumber;
    }
  }

  initiateWhatsapp() {
    const message = 'Hello, I want to send you a message.'; // The pre-filled message
    this.sharedService.sendMessage(message, this.whatsappNumber);
  }

  instagramLink() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      const url = this.websiteData.socialMediaLinks.instagram;
      window.open(url, '_blank');
    }
  }

  facebookLink() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      const url = this.websiteData.socialMediaLinks.facebook;
      window.open(url, '_blank');
    }
  }

  openWhatsAppCatalogue() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.open(this.websiteData.whatsappCatalogue, '_blank');
    }
  }

  onSubmitEnquiry() {
    if(this.enquiryForm.valid) {
      // this.onSubmitFeedback(this.enquiryForm.value);
      this.enquiryForm.reset();
    } else {
      this.enquiryForm.markAllAsTouched();
    }
  }

  getProductCard(productId) {
    this.sharedService.showSpinner.next(true);
    this.apiService.getProductCard(productId, this.businessId).subscribe((res: any) => {
      this.apiService.downloadImageApi(this.sharedService.generateImageUrl(res.imagePath)).subscribe((res) => {
        saveAs(res, 'image.png'); // Set the desired file name
        this.sharedService.showSpinner.next(false);
      });
    })
  }

  getEnquiry(productName) {
    const message = `Hi, I have seen your website and I am looking forward to know More about ${productName}.`;
    this.sharedService.sendMessage(message, this.whatsappNumber);
  }
}
