import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {SharedService} from "../services/shared.service";

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  paymentForm: FormGroup;
  payments: any = [
    {
      name: 'Phone Pay',
      key: 'phone_pay',
    },
    {
      name: 'Google Pay',
      key: 'google_pay',
    },
    {
      name: 'Bharat Pay',
      key: 'bharat_pay',
    },
    {
      name: 'Cash',
      key: 'cash',
    }
  ];

  constructor(
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<AddPaymentComponent>,
      public dialog: MatDialog,
      public apiService: ApiService,
      public sharedService: SharedService,
      @Inject(MAT_DIALOG_DATA) public paymentData: {
        businessId: string
      },
  ) {
  }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      paymentType: ['', [Validators.required]],
      paymentAmount: ['', [Validators.required]],
      paymentDate: [new Date(), [Validators.required, this.sharedService.futureDateValidator]],
      createdBy: ['', [Validators.required]],
      businessId: [''],
    });
    this.paymentForm.get('businessId').setValue(this.paymentData.businessId);
    this.paymentForm.get('createdBy').setValue(this.apiService.userId);
  }

  get paymentDate() {
    return this.paymentForm.get('paymentDate');
  }

  savePayment() {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      this.apiService.showToast('Please Add All Mandatory Fields');
      return
    } else {
      this.apiService.savePayment(this.paymentForm.value).subscribe(
          res => this.savePaymentSuccess(res),
          error => {
            this.apiService.commonError(error);
          }
      );
    }
  }

  savePaymentSuccess(res) {
    this.dialogRef.close();
    this.apiService.showToast('Payment Saved Successfully');
  }

}
