import { Component } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {SharedService} from "../services/shared.service";
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {PhoneValidator} from "../custom-validators/phone-number-validation";
import {StorageService} from "../services/storage.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  roles: any;
  constructor(public storageService: StorageService, public sharedService: SharedService, private formBuilder: FormBuilder, public apiService: ApiService, public router: Router) { }

  ngOnInit() {
    this.forgotPassword();
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.maxLength(10)]],
      confirmPassword: ['', Validators.required],
    }, {
      validators: this.passwordMatchValidator
    });
  }

  forgotPassword() {
    const data = {
      email: this.apiService.userEmail
    }
    console.log(this.apiService.userEmail);
    this.apiService.forgotPassword(data).subscribe(
        res => this.forgotPasswordSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  forgotPasswordSuccess(res) {
    this.storageService.storeValue('refresh-token', res.data.resetPasswordToken);
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const data: any = {
        password: this.changePasswordForm.get('password').value,
      };
      this.apiService.changePassword(data).subscribe(
          res => this.changePasswordSuccess(res),
          error => {
            this.apiService.commonError(error);
          }
      );
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  changePasswordSuccess(res) {
    console.log(res);
    this.apiService.showToast('Password changed Successfully.');
    this.router.navigate(['login']);
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;

    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordMismatch: true };
    }
  }

  goToHome() {
    // Navigate to the home page
  }
}
