export const appConstants = {
    ACCESS_TOKEN_KEY: 'my-access-token',
    REFRESH_TOKEN_KEY: 'my-refresh-token',
    USER_INFO: 'user-info',
    SELECTED_SERVICES: 'selected-services',
    SELECTED_PACKAGES: 'selected-packages',
    googleApiKey: 'AIzaSyBU3AEIpIorZF9EcGUUfYkv_z2taqRA9eI',
    localDomain: 'localhost:4200',
    creativeHandLogo: 'assets/company-logo/logo-symbol-no-background.png',
    creativeHandLogoName: 'assets/company-logo/logo-no-background.png',
    creativeHandLogoAnimation: 'assets/company-logo/creative_hand_logo_animation.gif',

    // baseUrl: 'http://localhost:3000/',
    // baseAuthUrl: 'http://localhost:3000/api/v1/auth/',
    // baseURLAdminAPIs: 'http://localhost:3000/api/v1/',
    // domainUrlApi: 'http://localhost:3000/api/',

    baseUrl: 'https://creativehand.co.in/',
    baseAuthUrl: 'https://creativehand.co.in/api/v1/auth/',
    baseURLAdminAPIs: 'https://creativehand.co.in/api/v1/',
    domainUrlApi: 'https://creativehand.co.in/api/',
};

