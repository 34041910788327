import {Inject, Injectable, InjectDecorator, PLATFORM_ID} from '@angular/core';
import {appConstants} from "../../assets/constants/app-constants";
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';
import {ApiService} from "./api.service";
import {NgxImageCompressService} from "ngx-image-compress";
import {DomSanitizer, Meta, SafeHtml} from "@angular/platform-browser";
import {BehaviorSubject} from "rxjs";
import { Clipboard } from '@angular/cdk/clipboard';
import {FormControl} from "@angular/forms";
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(@Inject(PLATFORM_ID) private platformId: object, private metaService: Meta, public clipboard: Clipboard, public sanitizer: DomSanitizer, private imageCompress: NgxImageCompressService, public router: Router, @Inject(DOCUMENT) private document: any, public apiService: ApiService) { }
  noImageIcon: any = 'assets/no-image.jpeg';
  sizeOfOriginalImage: any = '';
  submitFeedbackSuccess = new BehaviorSubject(false);
  submitEnquirySuccess = new BehaviorSubject(false);
  dashboardMenu$ = new BehaviorSubject('dashboard');
  showSpinner = new BehaviorSubject(false);
  showBrandSpinner = new BehaviorSubject(false);
  companyLogo: any = appConstants.creativeHandLogo;
  companyLogoName: any = appConstants.creativeHandLogoName;
  companyLogoAnimation: any = appConstants.creativeHandLogoAnimation;
  businessLogoName: any = '';
  businessLogoImageLink: any = '';
  public generateVCardOnTheFly = (websiteData, userDetails, contactNumbers): any => {
    return {
      formattedName: {
        firstNames: userDetails.name,
      },
      name: {
        firstNames: userDetails.name,
      },
      organization: websiteData.business.businessName,
      title: websiteData.business.qualification,
      address: [
        {
          street: websiteData.address && websiteData.address.addressLine ? websiteData.address.addressLine : '',
          countryName: 'India',
        },
      ],
      telephone: contactNumbers,
      email: [
        {
          value: userDetails.email,
          param: {type: 'work'},
        },
      ],
      socialUrls: {
        facebook: websiteData.socialMediaLinks.facebook,
        linkedin: websiteData.socialMediaLinks.linkedin,
        instagram: websiteData.socialMediaLinks.instagram,
      },
    };
  };

  futureDateValidator(control: FormControl) {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();

    if (selectedDate.getTime() > currentDate.getTime()) {
      return {futureDate: true};
    }

    return null;
  }

  copyToClipboard(content) {
    this.clipboard.copy(content);
    this.apiService.showToast('Copied to Clipboard');
  }

  getFirstNameAndLastName(fullName: string): { firstName: string, lastName: string } {
    const names = fullName.split(' '); // Split the full name by space
    const firstName = names[0]; // Get the first element as the first name
    const lastName = names.slice(1).join(' '); // Join the remaining elements as the last name

    return { firstName, lastName };
  }

  sendMessage(message, whatsAppNumber) {
    const encodedMessage = encodeURI(message);
    // https://api.whatsapp.com/send?phone=919403733265&text=Hello
    const url = `https://api.whatsapp.com/send?phone=91${whatsAppNumber}&text=${encodedMessage}`;
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.open(url, '_blank');
    }
  }

  setMetaTags(businessDetails, description) {
    const businessName = businessDetails.business.businessName ? businessDetails.business.businessName : '';
    const ownerName = businessDetails.userDetails && businessDetails.userDetails.name
        ? businessDetails.userDetails.name : '';
    const businessImageUrl = businessDetails.business.businessImageUrl ? this.generateImageUrl(businessDetails.business.businessImageUrl) : '';
    const businessType = businessDetails.business.businessType ? businessDetails.business.businessType : '';
    const businessLink = businessDetails.keywordUrl ? this.generatePrototypeUrl(businessDetails.keywordUrl) : '';
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:title', content: businessName });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ property: 'og:image', content: businessImageUrl });
      this.metaService.updateTag({ property: 'og:url', content: businessLink });
      this.metaService.updateTag({ property: 'og:site_name', content: businessName });
      this.metaService.updateTag({ property: 'og:locale', content: 'en_US' });
      this.metaService.updateTag({ property: 'og:type', content: businessType });
      this.metaService.updateTag({ property: 'og:article:author', content: ownerName });
      // Add or update more OG meta tags as needed
  }

  generateImageUrl(path) {
    return `${appConstants.baseUrl}${path}?${new Date().getTime()}`
  }

  generateQueryString(params: any): string {
    const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    return queryString;
  }

  generatePrototypeUrl(path) {
    let domain = this.document.location.hostname;
    if (domain.includes('localhost')) {
      domain = `http://${appConstants.localDomain}/web/${path}`;
    } else {
      domain = `https://${domain}/web/${path}`;
    }
    return domain;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  decodeHtmlString(htmlString: string): string {
    const parser = new DOMParser();
    const decodedHtml = parser.parseFromString(htmlString, 'text/html');
    return decodedHtml.documentElement.textContent;
  }

  decodeHTML(htmlString: string): string {
    const element = document.createElement('div');
    element.innerHTML = htmlString;
    return element.textContent;
  }

  hasNoEmptyValues(obj: any): boolean {
    if (obj === null || typeof obj !== 'object') {
      return false;
    }

    if (Object.keys(obj).length === 0) {
      return false;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value === '' || value === null) {
          return false;
        }
      }
    }

    return true;
  }

  hasNoEmptyValuesInArray(arr: any[]): boolean {
    for (const obj of arr) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value === '' || value == null) {
            return false;
          }
        }
      }
    }
    return true;
  }

  generateRandomPassword(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
    let password = '';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  }

  get isAdmin() {
    return this.apiService.userRole.toLowerCase() === 'admin';
  }

  isValidImageName(fileName) {
    const pattern = /^[a-zA-Z0-9\s-]+\.([a-zA-Z0-9]{1,4})$/;
    return !!(pattern.test(fileName));
  }

  get isExecutive() {
    return this.apiService.userRole.toLowerCase() === 'executive';
  }

  get isCustomer() {
    return this.apiService.userRole.toLowerCase() === 'customer';
  }

  compressFile(image, fileName) {
    return new Promise((resolve, reject) => {
      var orientation = -1;
      const sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
      console.log('Image Original Size', sizeOfOriginalImage);
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
          result => {
            const imgResultAfterCompress = result;
            const base64 = result;
            const sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
            console.log('Size in bytes after compression:', sizeOFCompressedImage);
            const imageName = fileName;
            const imageBlob = this.dataURItoBlob(imgResultAfterCompress.split(',')[1]);
            const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

            const compressedImageData = {
              imageFile: imageFile,
              base64: base64
            };

            resolve(compressedImageData);
          }
      ).catch(error => {
        reject(error);
      });
    });
  }


  dataURItoBlob(dataURI) {
    let byteString = null;
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      byteString = window.atob(dataURI);
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


}
