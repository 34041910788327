import {Component, Input, Output} from '@angular/core';

@Component({
  selector: 'app-bank-details-card',
  templateUrl: './bank-details-card.component.html',
  styleUrls: ['./bank-details-card.component.scss']
})
export class BankDetailsCardComponent {
  @Input() bankDetails: any;
}
