import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {AddCustomerComponent} from "../add-customer/add-customer.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {AddExecutiveComponent} from "../add-executive/add-executive.component";
import {AddPaymentComponent} from "../add-payment/add-payment.component";
import {appConstants} from "../../assets/constants/app-constants";
import {SharedService} from "../services/shared.service";
import {StorageService} from "../services/storage.service";
import {DialogAnimationsExampleDialog} from "../app.component";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    displayedBusinessColumns: string[] = ['businessName', 'ownerName', 'status', 'email', 'contactNumber', 'executiveName', 'siteLink',
        'subscriptionStartDate', 'subscriptionEndDate', 'offerName', 'payment', 'action'];
    displayedUsersColumns: string[] = ['name', 'email', 'contactNumber', 'userRole', 'status', 'action'];
    displayedEnquiriesColumns: string[] = ['contactNumber', 'name', 'email'];
    dataSourceBusiness: MatTableDataSource<any>;
    dataSourceUsers: MatTableDataSource<any>;
    dataSourceWebsiteEnquiries: any = [];
    usersList: any = [];
    executives: any = [];
    selectedMenu: any = '';
    filtersList: any = [
        { name: 'Business Name', value: 'businessName' },
        { name: 'Business Keyword', value: 'keywordUrl' },
        // { name: 'Contact', value: 'contact' },
        // { name: 'Owner Name', value: 'ownerName' },
        // { name: 'Email', value: 'email' },
        { name: 'Status', value: 'status' },
        { name: 'Payment Status', value: 'paymentStatus' },
    ];
    customerFeedbacks: any = [];
    userRole: any = '';
    currentBusinessPage: any = 1;
    currentUserPage: any = 1;
    businessDataTotalLength: any = 0;
    userDataTotalLength: any = 0;
    currentBusinessLimit: any = 10;
    businessPageSizeOptions: any[] = [5, 10, 20];
    businessPageSize: any = 20;
    businessSearchForm: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatPaginator) userPaginator!: MatPaginator;

    constructor(public fb: FormBuilder, public sharedService: SharedService, public storageService: StorageService, public router: Router, public dialog: MatDialog, public apiService: ApiService) {

    }

    onSelectedMenu(event) {
     this.sharedService.dashboardMenu$.next(event);
    }

    searchBusiness() {
        this.currentBusinessPage = 1;
        if (this.businessSearchForm.invalid) {
            this.apiService.showToast('Please enter valid search string.');
        } else {
            this.sharedService.showSpinner.next(true);
            const keyword = this.businessSearchForm.get('keyword').value.trim();
            const searchString = this.businessSearchForm.get('searchString').value.trim();
            let data = {
                limit: this.businessPageSize,
                page: this.currentBusinessPage,
            };
            if (searchString) {
                data[keyword] = searchString;
            }
            const pageQuery = this.sharedService.generateQueryString(data);
            this.apiService.getBusiness(pageQuery).subscribe(
                res => this.getBusinessSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    openExecutiveDialog(id): void {
        const userDetails = this.usersList.find(v => v.id === id);
        const dialogRef = this.dialog.open(AddExecutiveComponent, {
            width: '900px', disableClose: false,
            data: userDetails
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataDecider();
        });
    }

    onBusinessPageChange(event: PageEvent) {
        this.currentBusinessPage = event.pageIndex + 1;
        this.getBusinessData();
    }

    onUserPageChange(event: PageEvent) {
        this.currentUserPage = event.pageIndex + 1;
        this.getUsersData();
    }

    onChangePassword() {
        this.router.navigate(['/change-password']);
    }

    ngOnInit(): void {
        this.sharedService.dashboardMenu$.subscribe((val) => {
            this.selectedMenu = val;
        });
        this.businessSearchForm = this.fb.group({
            keyword: ['', Validators.required],
            searchString: ['']
        });
        this.businessSearchForm.get('keyword').setValue(this.filtersList[0].value);
        this.dataSourceBusiness = new MatTableDataSource([]);
        this.dataSourceBusiness.paginator = this.paginator;
        this.dataSourceUsers = new MatTableDataSource([]);
        this.dataSourceUsers.paginator = this.userPaginator;
        this.userRole = this.apiService.userRole.toLowerCase();
        this.sharedService.showSpinner.next(true);
        this.dataDecider();
        this.getExecutives();
        this.getWebsiteEnquiries();
    }


    getExecutives() {
        const roles = this.storageService.getStorageValue('roles');
        const rolesArray = JSON.parse(roles);
        if (rolesArray) {
            const customer = rolesArray.find(v => v.name.toLowerCase() === 'executive');
            let data = {
                role: customer.id
            };
            const query = this.sharedService.generateQueryString(data);
            this.apiService.getAllUsers(query).subscribe(
                res => this.getCustomersSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    getCustomersSuccess(res) {
        this.executives = res;
    }

    dataDecider() {
        if (this.userRole === 'admin') {
            this.getBusinessData();
            this.getUsersData();
        }

        if (this.userRole === 'executive') {
            const data = {
                executiveId: this.apiService.userId
            };
            const pageQuery = this.sharedService.generateQueryString(data);
            this.apiService.getBusiness(pageQuery).subscribe(
                res => this.getBusinessSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    getWebsiteEnquiries() {
        this.apiService.getWebsiteEnquiries().subscribe(
            res => this.getWebsiteEnquiriesSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    getWebsiteEnquiriesSuccess(res) {
        this.dataSourceWebsiteEnquiries = res.results;
    }

    getUsersData() {
        const data = {
            limit: this.businessPageSize,
            page: this.currentUserPage
        };
        this.sharedService.showSpinner.next(true);
        const pageQuery = this.sharedService.generateQueryString(data);
        this.apiService.getUsers(pageQuery).subscribe(
            res => this.getUsersSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    getUsersSuccess(res) {
        this.usersList = res.results;
        this.usersList = this.usersList.map((val) => {
            return {
                name: val.name,
                id: val.id,
                email: val.email,
                contactNumber: val.contactNumber,
                userRole: val.role.name,
                roleId: val.role.id,
                status: val.status
            }
        });
        this.dataSourceUsers.data = this.usersList;
        this.userDataTotalLength = res.totalResults;
        this.sharedService.showSpinner.next(false);
    }

    getBusinessData(query = '') {
        const data = {
            limit: this.businessPageSize,
            page: this.currentBusinessPage
        };
        const pageQuery = this.sharedService.generateQueryString(data);
        this.sharedService.showSpinner.next(true);
        this.apiService.getBusiness(pageQuery).subscribe(
            res => this.getBusinessSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    modifyBusinessData(res) {
        return res.results.map((val) => {
            return {
                id: val._id,
                businessName: val.business.businessName,
                ownerName: val.customerDetails && val.customerDetails.name ? val.customerDetails.name : '',
                email: val.customerDetails && val.customerDetails.email ? val.customerDetails.email : '',
                payment: val.paymentStatus,
                paymentStatus: val.paymentStatus === 'approval_pending' ? 'Approval Pending' : val.paymentStatus,
                contactNumber: val.business.contactNumbers[0].number,
                status: val.status.toLowerCase(),
                offerName: val.offerDetails && val.offerDetails.name ? val.offerDetails.name : '',
                subscriptionStartDate: val.subscriptionStartDate,
                subscriptionEndDate: val.subscriptionEndDate,
                customerId: val.customerId,
                offerId: val.offerId,
                keywordUrl: val.keywordUrl,
                executiveName: val.executiveDetails && val.executiveDetails.name ? val.executiveDetails.name : '',
                executiveId: val.executiveDetails && val.executiveDetails.id ? val.executiveDetails.id : '',
                siteLink: this.sharedService.generatePrototypeUrl(val.keywordUrl)
            }
        });
    }

    getBusinessSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.businessDataTotalLength = res.totalResults;
        this.dataSourceBusiness.data = this.modifyBusinessData(res);
    }

    openDialogPrompt(title: string, confirmLabel: any, message: string, confirmFunction: (id: string) => void, id: string): void {
        const enterAnimationDuration = '0ms';
        const exitAnimationDuration = '0ms';
        const dialogRef = this.dialog.open(DialogAnimationsExampleDialog, {
            width: '400px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                title: title,
                message: message,
                confirmLabel: confirmLabel,
                businessId: id,
                confirmFunction: confirmFunction
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // Handle the result data here
            console.log('Dialog closed with result:', result);
        });
    }

    openBusinessDialog(id = '', key = ''): void {
        const businessDetails = id ? this.dataSourceBusiness.data.find(v => v.id === id) : null;
        const dialogRef = this.dialog.open(AddCustomerComponent, {
            width: '900px', disableClose: false,
            data: {key: key, businessDetails: businessDetails}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataDecider();
        });
    }

    deactiveUser(businessData): void {
        const data = {
            status: 'de-active'
        };
        this.updateBusiness(businessData.businessId, data);
    }

    updateBusiness(businessId, data) {
        this.sharedService.showSpinner.next(true);
        this.apiService.updateBusinessDetails(businessId, data).subscribe(
            res => this.updateBusinessDetailsSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    updateBusinessDetailsSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.apiService.showToast('User Updated Successfully');
        this.getBusinessData();
    }

    approveRequest(businessData): void {
        const data = {
            status: 'active'
        };
        this.updateBusiness(businessData.businessId, data);
    }

    approvePaymentRequest(businessData): void {
        const data = {
            paymentStatus: 'paid'
        };
        this.updateBusiness(businessData.businessId, data);
    }

    confirmDeactivateUser(businessId): void {
        const title: any = 'Confirmation';
        const confirmLabel: any = 'Deactive';
        const message: any = 'Would you like to deactivate User?';
        this.openDialogPrompt(title, confirmLabel, message, this.deactiveUser.bind(this), businessId);
    }

    confirmApproveRequest(businessId): void {
        const title: any = 'Confirmation';
        const confirmLabel: any = 'Approve';
        const message: any = 'Would you like to Approve the request?';
        this.openDialogPrompt(title, confirmLabel, message, this.approveRequest.bind(this), businessId);
    }

    confirmApprovePayment(businessId) {
        const title: any = 'Confirmation';
        const confirmLabel: any = 'Approve Payment';
        const message: any = 'Would you like to Approve the Payment?';
        this.openDialogPrompt(title, confirmLabel, message, this.approvePaymentRequest.bind(this), businessId);
    }

    openPaymentDialog(businessId): void {
        const dialogRef = this.dialog.open(AddPaymentComponent, {
            width: '900px', disableClose: false, data: {businessId: businessId}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.dataDecider();
        });
    }

    onEditRow(id) {
        this.openExecutiveDialog(id);
    }

    onPayments(id) {
        const businessData = this.dataSourceBusiness.data.find(v => v.id === id);
        console.log(businessData);
    }

    onEditBusinessRow(id) {
        this.router.navigate(['create-website', id]);
    }

    addExtension(id) {
        console.log(id);
    }

    onSettings(id) {
        const businessData = this.dataSourceBusiness.data.find(v => v.id === id);
        console.log(businessData);
        // console.log(data);
        // this.router.navigate(['create-website', id]);
    }

    onLogout() {
        this.apiService.logout().subscribe(
            res => this.logoutSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    logoutSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.router.navigate(['/login']);
        this.apiService.showToast('User Logged out Successfully.');
    }
}

