import {Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import {SharedService} from "../services/shared.service";
import {Meta, Title} from "@angular/platform-browser";
import {appConstants} from "../../assets/constants/app-constants";
import {isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-prototype',
  templateUrl: './prototype.component.html',
  styleUrls: ['./prototype.component.scss']
})
export class PrototypeComponent implements OnInit{
  websiteData: any;
  businessUrl: any = '';
  businessId: any = '';
  views: any = [];
  phoneBookDetails: any = null;
  subscriptionEnded: any = false;
  message: any = '';
  templateName: any = 'cronus';
  constructor(
      @Inject(PLATFORM_ID) private platformId: object,
      private router: Router,
      public meta: Meta,
      public title: Title,
      public route: ActivatedRoute,
      public apiService: ApiService,
      public sharedService: SharedService
  ) {
    this.sharedService.showBrandSpinner.next(true);
  }

  ngOnInit(): void {
    this.businessUrl = this.route.snapshot.paramMap.get('keywordUrl');
    this.getWebsiteDataKeywordUrl();
  }

  onCallAction() {
    const phoneNumber = '8208690072'; // Indian phone number with country code
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.location.href = 'tel:' + phoneNumber;
    }
  }

  getWebsiteDataKeywordUrl() {
    this.apiService.getBusinessByKeyword(this.businessUrl).subscribe(
        res => this.getBusinessByKeywordSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getBusinessByKeywordSuccess(res) {
    const businessImageUrl = res.business.businessImageUrl ? `${appConstants.baseUrl}${res.business.businessImageUrl}` : '';
    this.sharedService.businessLogoImageLink = businessImageUrl;
    this.sharedService.businessLogoName = res.business.businessName;
    setTimeout(() => {
      this.sharedService.showBrandSpinner.next(false);
    }, 2000);
    const businessDescription: any = `Check out ${res.business.businessName} - your destination for exceptional products/services. Share this link and spread the word of success.`;
    this.meta.addTag({ property: 'og:title', content: res.business.businessName });
    this.meta.updateTag({ property: 'title', content: res.business.businessName });
    this.title.setTitle(res.business.businessName);
    this.meta.addTag({ property: 'og:site_name', content: res.business.businessName });
    this.meta.addTag({ property: 'og:description', content: businessDescription });
    this.meta.updateTag({ property: 'description', content: businessDescription });
    this.meta.updateTag({ property: 'og:image', content: businessImageUrl });
    this.message = this.validateSubscription(res.status, res.subscriptionEndDate);
    if(this.message) {
      this.subscriptionEnded = true;
      return;
    }
    this.websiteData = res;
    this.templateName = res.template;
    this.businessId = this.websiteData._id;
    const businessName = this.websiteData.business.businessName;
    const description = `Welcome to our website of ${businessName}! Discover a wide range of products/services that cater to your needs. We strive to provide exceptional quality, excellent customer service, and a seamless user experience. Explore our offerings and find exactly what you're looking for.`;
    this.sharedService.setMetaTags(this.websiteData, description);
    this.updateViews();
    this.getPhoneBookData();
  }

  validateSubscription(userStatus, endDate): string {
    const currentDate = new Date();
    const subscriptionEndDate = new Date(endDate);
    if (userStatus === 'active' && subscriptionEndDate >= currentDate) {
      return ''; // No message
    } else if (!userStatus || subscriptionEndDate >= currentDate) {
      return 'Account is not activated';
    } else if (subscriptionEndDate < currentDate) {
      return 'Subscription has ended';
    } else {
      return '';
    }
  }

  updateViews() {
    const data = {
      pageName: this.businessUrl
    };
    this.apiService.updateViews(this.businessId, data).subscribe(
        res => this.updateViewsSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getLatestViews() {
    this.apiService.getLatestViews(this.businessId).subscribe(
        res => this.getLatestViewsSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getLatestViewsSuccess(res) {
   this.views = res;
  }

  updateViewsSuccess(res) {
    this.getLatestViews();
  }

  onSubmitEnquiry(data) {
    this.apiService.saveBusinessEnquiry(data).subscribe(
        res => this.saveBusinessEnquirySuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  saveBusinessEnquirySuccess(res) {
    this.apiService.showToast('Enquiry Submitted Successfully. We will revert you back soon!');
  }

  getPhoneBookData() {
    this.apiService.getPhoneBook(this.websiteData.userDetails.id).subscribe(
        res => this.getPhoneBookSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getPhoneBookSuccess(res) {
    this.phoneBookDetails = res;
    console.log(res);
  }

}
