import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export function EmailValidator(): ValidatorFn {
    const regex: RegExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;

    return (control: FormControl): { [key: string]: any } | null => {
        const controlName: string = control.value;
        if (controlName && !regex.test(controlName)) {
            return { invalidWebsite: true };
        }
        return null;
    };
}
