<mat-drawer-container [class]="themeService.currentTheme" class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="push" position="end">
        <mat-nav-list>
            <div class="d-flex justify-content-center pt-4">
                <button [routerLink]="'/login'" mat-raised-button type="submit"
                        class="Update-btn contact-card-button">
                    <mat-icon>person</mat-icon> Customer Login
                </button>
            </div>
            <div class="enquiry-form">
                <h2 class="col-primary text-center">Enquiry Form</h2>
                <form [formGroup]="enquiryForm">
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Full Name</mat-label>
                                <input #customerName maxlength="30" formControlName="name" type="text" matInput placeholder="Enter your name">
                                <mat-hint align="end">{{customerName.value.length}} / 30</mat-hint>
                                <mat-error>Please Enter Your Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Contact Number</mat-label>
                                <input maxlength="10" #mobileNo formControlName="contactNumber" type="text" matInput placeholder="Enter Phone Number">
                                <mat-icon class="col-primary" matSuffix>call</mat-icon>
                                <mat-hint align="end">{{mobileNo.value.length}} / 10</mat-hint>
                                <mat-error>Please Enter Valid Mobile Number</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Email Address</mat-label>
                                <input type="email" matInput formControlName="email" placeholder="Enter your Email">
                                <mat-error>Please Enter Valid Email</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Leave a comment</mat-label>
                                <textarea rows="5" cols="40" #message maxlength="200" formControlName="message" matInput placeholder="What you feel?"></textarea>
                                <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                                <mat-error>Please Enter Your Message</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center">
                    <button [disabled]="enquiryForm.invalid" (click)="onSubmitEnquiry()" mat-raised-button class="enquiry-btn bg-color-primary">
                      Send Enquiry <i class="fa fa-paper-plane flex-center">
                      </i>
                    </button>
                </div>
            </div>
        </mat-nav-list>
    </mat-drawer>
    <mat-toolbar class="sticky-toolbar" [ngClass]="{'position-fixed': isStickToolbar}">
        <div class="d-flex justify-content-between align-items-center full-width">
            <div class="font-16 d-flex">
                <div><img class="business-icon" src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image"></div>
                <div>{{websiteData?.business?.businessName}}</div>
            </div>
            <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
                    aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="example-sidenav-content">
        <div class="page-container">
            <div class="theme-container theme-cronus">
                <div class="contact-card animate__animated animate__fadeInUp">
                    <div>
                        <div class="business-image-container">
                                <img class="business-image" src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image">
                        </div>
                    </div>
                    <div class="business-text-container">
                        <div class="business-title">
                            {{websiteData?.business?.businessName}}
                        </div>
                        <div class="owner-name-container">
                            <div class="font-14">{{websiteData?.userDetails?.name}}</div>
                            <div class="col-secondary-light font-12" >{{websiteData?.business?.qualification}}</div>
                        </div>
                    </div>
                    <div class="contact-card-icon-container d-flex full-width flex-column justify-content-around">
                        <div class="d-flex justify-content-center" style="margin-bottom: 15px">
                            <button  id="downloadButtonNormal"
                                     vcdDownloadVCard
                                     [generateVCardFunction]="generateVCardOnTheFly" mat-raised-button class="color-white contact-card-button">
                                <mat-icon>contacts</mat-icon> Add to Phonebook
                            </button>
                        </div>
                        <hr>
                        <div class="d-flex full-width justify-content-around">
                            <div class="example-button-container">
                                <button (click)="onCallAction()" class="contact-card-button" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <mat-icon>call</mat-icon>
                                </button>
                            </div>
                            <div class="example-button-container">
                                <button *ngIf="whatsappNumber" (click)="initiateWhatsapp()" class="contact-card-button" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-whatsapp"></i>
                                </button>
                            </div>
                            <div *ngIf="websiteData?.socialMediaLinks?.instagram" class="example-button-container">
                                <button (click)="instagramLink()" class="contact-card-button" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-instagram"></i>
                                </button>
                            </div>
                            <div *ngIf="websiteData?.socialMediaLinks?.facebook" class="example-button-container">
                                <button (click)="facebookLink()" class="contact-card-button" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-facebook"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="badge-container">
                        <div class="badgescard">
                            <i class="fa fa-eye"></i> &nbsp; {{views?.length}} Views
                        </div>
                    </div>
                </div>
                <div class=" sections-container">
                    <div class="animate__animated animate__fadeInUp opacity-0-custom single-section mt-4">
                        <div class="section-header">
                            📲 Quick Share
                        </div>
                    </div>
                    <div class="share-buttons-container" [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(100)}" >
                        <div class="d-flex justify-content-around">
                            <share-buttons theme="circles-light share-button"
                                           [include]="['copy', 'whatsapp', 'facebook', 'linkedin']"
                                           [showIcon]="true"
                                           [showText]="true"
                                           [url]="shareLink"
                                           [description]="pageDescription" class="share-button-list">
                            </share-buttons>
                        </div>
                        <div class="d-flex justify-content-around">
                            <share-buttons theme="circles-light share-button"
                                           [include]="['messenger', 'email', 'sms', 'telegram', ]"
                                           [showIcon]="true"
                                           [showText]="true"
                                           [url]="shareLink"
                                           [description]="pageDescription" class="share-button-list">
                            </share-buttons>
                        </div>
                        <div class="d-flex justify-content-around">
                            <share-buttons theme="circles-light share-button"
                                           [include]="[ 'twitter', 'print', 'pinterest', 'reddit']"
                                           [showIcon]="true"
                                           [showText]="true"
                                           [url]="shareLink"
                                           [description]="pageDescription" class="share-button-list">
                            </share-buttons>
                        </div>
                    </div>
                </div>
                <div class=" sections-container">
                    <div *ngIf="websiteData?.address?.addressLine || websiteData?.address?.addressMapLink" [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(250)}"  class="opacity-0-custom single-section mt-4">
                        <div class="section-header">
                            📍 Address
                        </div>
                        <div class="section-content address-content">
                            <div class="address-tab">
                                <div class="address-tab-container">
                                    <div *ngIf="websiteData?.address?.addressMapLink" class="address-tab-image" [innerHTML]="mapSrc">
<!--                                        <iframe [src]="mapSrc" width="150" height="150" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
                                    </div>
                                    <div *ngIf="websiteData?.address?.addressLine" class="address-tab-content">
                                        <div class="sec-tab-details">
                                            {{websiteData?.address?.addressLine}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [ngClass]="{'animate__animated animate__fadeInUp ' : applyAnimOnScroll(300)}" *ngIf="websiteData?.business?.estYear || websiteData?.business?.businessType" class="opacity-0-custom single-section">
                        <div class="section-header">
                            🙋‍ About Us
                        </div>
                        <div class="section-content about-us-content">
                            <div *ngIf="websiteData?.business?.estYear" class="about-us-tab-container">
                                <div class="about-us-tab-image">
                                    <i class="fa fa-suitcase"></i>
                                </div>
                                <div class="about-us-tab-content">
                                    <div class="sec-tab-header">Year of Established</div>
                                    <div class="sec-tab-details">{{websiteData?.business?.estYear}}</div>
                                </div>
                            </div>
                            <div *ngIf="websiteData?.business?.businessType" class="about-us-tab-container">
                                <div class="about-us-tab-image">
                                    <i class="fa fa-industry"></i>
                                </div>
                                <div class="about-us-tab-content">
                                    <div class="sec-tab-header"> Business Type</div>
                                    <div class="sec-tab-details">{{websiteData?.business?.businessType}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(300)}" class="opacity-0-custom single-section">
                        <div class="section-header">
                            👤 Information
                        </div>
                        <div class="section-content">
                            <div *ngFor="let contact of websiteData?.business?.contactNumbers" class="section-tab">
                                <div class="section-tab-container">
                                    <div class="section-tab-image">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                    <div class="section-tab-content">
                                        <div class="sec-tab-header"> Contact Number</div>
                                        <div class="sec-tab-details">{{contact.number}}</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="websiteData?.userDetails?.email" class="section-tab">
                                <div class="section-tab-container">
                                    <div class="section-tab-image">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="section-tab-content">
                                        <div class="sec-tab-header"> Email Address</div>
                                        <div class="sec-tab-details">{{websiteData?.userDetails?.email}}</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="websiteData?.business?.website" class="section-tab">
                                <div class="section-tab-container">
                                    <div class="section-tab-image">
                                        <i class="fa fa-globe"></i>
                                    </div>
                                    <div class="section-tab-content">
                                        <div class="sec-tab-header"> Website</div>
                                        <div class="sec-tab-details">
                                            <a [attr.href]="websiteData?.business?.website">
                                                {{websiteData?.business?.website}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let link of websiteData?.business?.otherLinks" class="section-tab">
                                <div class="section-tab-container">
                                    <div class="section-tab-image">
                                        <i class="fa fa-link"></i>
                                    </div>
                                    <div class="section-tab-content">
                                        <div class="sec-tab-header"> Other Link</div>
                                        <div class="sec-tab-details">
                                            <a [attr.href]="link">
                                                {{link}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(400)}" *ngIf="websiteData?.whatsappCatalogue" class="single-section opacity-0-custom mt-4">
                        <div class="section-header">
                            🛒 WhatsApp Catalogue
                        </div>
                        <div class="section-content speciality-content">
                            <div (click)="openWhatsAppCatalogue()" class="whatsapp-button">
                                <img width="30" src="assets/whatsapp_logo.png" alt="wa logo">
                                Open WhatsApp Catalogue
                            </div>
                        </div>
                    </div>

                    <div [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(400)}" *ngIf="htmlContent" class="single-section opacity-0-custom mt-4">
                        <div class="section-header">
                            🚀 What makes us different
                        </div>
                        <div class="section-content speciality-content">
                            <div [innerHtml]="htmlContent" class="speciality-tab">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="websiteData?.products && websiteData?.products.length" class="single-section mt-4">
                        <div class="section-header">
                            📝 Portfolio
                        </div>
                        <div class="section-content photo-content">
                            <div *ngFor="let product of websiteData.products" class="photos-card">
                                <div class="pic-header">
                                    <div *ngIf="product?.productName" class="header-text">
                                        {{product?.productName}}
                                    </div>
                                    <div (click)="getProductCard(product?._id)" class="cursor-pointer dwnld-btn">
                                        <i class="fa fa-download"></i> Download
                                    </div>
                                </div>
                                <div class="pic-container">
                                    <img *ngIf="product?.productImageUrl" [src]="product?.productImageUrl" alt="Product Image">
                                </div>
                                <div *ngIf="product?.productName" class="enq-btn-container">
                                    <div (click)="getEnquiry(product?.productName)" class="cursor-pointer whtsapp-enquiry-btn">
                                        <i class="fab fa-whatsapp"></i> Enquiry
                                    </div>
                                </div>

                                <div *ngIf="product?.productDescription" class="action-container">
                                    {{product?.productDescription}}
                                </div>
                                <div class="icons-tab">
                                    <button (click)="getEnquiry(product?.productName)" class="bg-color-primary" mat-mini-fab color="var(--primary-color)"
                                            aria-label="Example icon button with a menu icon">
                                        <i class="font-20 fab fa-whatsapp flex-center"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="galleryImages && galleryImages.length" class="single-section mt-4">
                        <div class="section-header gallery-header">
                            🖼️ Gallery
                        </div>
                        <div class="section-content">
                            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                        </div>
                    </div>
                    <div *ngIf="sharedService.hasNoEmptyValues(websiteData?.bankDetails)" class="single-section mt-4">
                        <div class="section-header">
                            💰Payment
                        </div>
                        <div class="section-content">
                            <app-bank-details-card class="full-width" [bankDetails]="websiteData?.bankDetails"></app-bank-details-card>
                        </div>
                    </div>
                    <div  *ngIf="websiteData?.upiPayments && websiteData?.upiPayments?.length" class="single-section mt-4">
                        <div class="section-header">
                            💸 UPI
                        </div>
                        <div class="section-content about-us-content">
                            <div *ngFor="let payment of websiteData?.upiPayments" class="about-us-tab-container">
                                <div class="about-us-tab-image">
                                    <img class="phone-pe-icon" [src]="payment?.imageUrl" alt="Phone Pay">
                                </div>
                                <div class="about-us-tab-content">
                                    <div class="sec-tab-header">{{payment?.upiUserName}}</div>
                                    <div class="sec-tab-details">{{payment?.upiContact}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf=" websiteData?.videoLinks && websiteData?.videoLinks?.length" class="single-section mt-4">
                        <div class="section-header">
                            🎥 Videos
                        </div>
                        <div class="section-content youtube-content">
                            <div *ngFor="let video of websiteData?.videoLinks" class="youtube-video-container">
                                <youtube-player [width]="330" [height]="180" [videoId]="video.videoId"></youtube-player>
                            </div>
                        </div>
                    </div>
                    <div class="single-section mt-4">
                        <div class="section-header">
                            📝 Customer Feedback
                        </div>
                        <app-feedback-form [businessId]="businessId"></app-feedback-form>
                    </div>
                </div>
                <mat-toolbar class="sticky-bottom-toolbar" [ngClass]="{'position-bottom-fixed': isStickToolbar}">
                    <div class="bottom-toolbar">
                        © 2023 Creative Hand
                    </div>
                </mat-toolbar>
            </div>
        </div>
    </div>
</mat-drawer-container>

