import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {appConstants} from "../../assets/constants/app-constants";
import {BehaviorSubject, from} from "rxjs";
import {StorageService} from "./storage.service";
import {tap} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {of, switchMap} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    baseURL: string = appConstants.baseURLAdminAPIs;
    baseAuthUrl: string = appConstants.baseAuthUrl;
    currentAccessToken: any = null;
    isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    onLoadToken = new BehaviorSubject<any>('');

    constructor(private router: Router, private http: HttpClient, public storageService: StorageService, public snackBar: MatSnackBar) {
        this.loadToken();
    }

    // Shared Functions

    get userDetails() {
        const userData = this.storageService.getEncryptedStorageValue(appConstants.USER_INFO);
        return userData;
    }

    get userRole() {
        const userData = this.storageService.getEncryptedStorageValue(appConstants.USER_INFO);
        return userData && userData.role ? userData.role.name : '';
    }

    get userId() {
        const userData = this.storageService.getEncryptedStorageValue(appConstants.USER_INFO);
        return userData && userData.id ? userData.id : '';
    }

    get userName() {
        const userData = this.storageService.getEncryptedStorageValue(appConstants.USER_INFO);
        return userData && userData.name ? userData.name : '';
    }

    get userEmail() {
        const userData = this.storageService.getEncryptedStorageValue(appConstants.USER_INFO);
        return userData && userData.email ? userData.email : '';
    }

    downloadImage(imageUrl: string) {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'image.png'; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getNewAccessToken() {
        const refreshToken = from(this.storageService.getStoredValue(appConstants.REFRESH_TOKEN_KEY));
        return refreshToken.pipe(
            switchMap(token => {
                if (token) {
                    const resBody = {
                        refreshToken: token
                    };
                    return this.http.post(`${this.baseAuthUrl}refresh-tokens`, resBody);
                } else {
                    // No stored refresh token
                    return of(null);
                }
            })
        );
    }

    showToast(message: string): void {
        this.snackBar.open(message, 'Close', {
            duration: 3000,
            verticalPosition: 'top',
        });
    }

    // Store a new access token
    storeAccessToken(accessToken: any) {
        this.currentAccessToken = accessToken;
        return from(this.storageService.storeValue(appConstants.ACCESS_TOKEN_KEY, accessToken));
    }

    // Load accessToken on startup
    loadToken() {
        const token = this.storageService.getStorageValue(appConstants.ACCESS_TOKEN_KEY);
        if (token) {
            this.currentAccessToken = token;
            this.isAuthenticated.next(true);
        } else {
            this.isAuthenticated.next(false);
        }
    }

    // End ---

    saveBusinessFeedback(data) {
        return this.http.post(`${this.baseURL}feedback`, data, {});
    }

    saveBusinessEnquiry(data) {
        return this.http.post(`${this.baseURL}enquiry`, data, {});
    }

    updateViews(id, data) {
        return this.http.post(`${this.baseURL}business/views/${id}`, data, {});
    }

    getLatestViews(id) {
        return this.http.get(`${this.baseURL}business/views/${id}`, {});
    }

    getFeedbackByBusinessId(id) {
        return this.http.get(`${this.baseURL}feedback/${id}`, {});
    }

    updateFeedbackByBusinessId(id, data) {
        return this.http.patch(`${this.baseURL}feedback/${id}`, data, {});
    }

    getEnquiryByBusinessId(id) {
        return this.http.get(`${this.baseURL}enquiry/${id}`, {});
    }

    saveBusinessDetails(data) {
        return this.http.post(`${this.baseURL}business`, data, {});
    }

    updateBusinessDetails(id, data) {
        return this.http.patch(`${this.baseURL}business/${id}`, data, {});
    }

    renderDemoBusiness(data) {
        return this.http.post(`${this.baseURL}business/renderDemoBusiness`, data, {});
    }

    contactUs(data) {
        return this.http.post(`${this.baseURL}business/contactUs`, data, {});
    }

    getWebsiteEnquiries() {
        return this.http.get(`${this.baseURL}business/getWebsiteEnquiries`, {});
    }

    updateProductImage(id, data) {
        return this.http.patch(`${this.baseURL}business/products/image/${id}`, data, {});
    }

    updatePortfolioImage(id, data) {
        return this.http.patch(`${this.baseURL}business/portfolio/image/${id}`, data, {});
    }

    deletePortfolioImage(id, data) {
        return this.http.post(`${this.baseURL}business/portfolio/image/${id}`, data, {});
    }

    updateMultiplePortfolioImages(id, data) {
        return this.http.patch(`${this.baseURL}business/portfolio/multiImages/${id}`, data, {});
    }

    getBusiness(query) {
        return this.http.get(`${this.baseURL}business?${query}`, {});
    }

    getAllUsers(query) {
        return this.http.get(`${this.baseURL}users/all?${query}`, {});
    }

    getOffers() {
        return this.http.get(`${this.baseURL}offers`, {});
    }

    getBusinessById(id) {
        return this.http.get(`${this.baseURL}business/${id}`, {});
    }

    getBusinessByKeyword(keyword) {
        return this.http.get(`${this.baseURL}business/keyword/${keyword}`, {});
    }

    uploadSingleFile(data) {
        return this.http.post(`${this.baseURL}upload-file/single`, data, {});
    }

    uploadBusinessImage(id, data) {
        return this.http.post(`${this.baseURL}business/image/${id}`, data, {});
    }

    login(data) {
        return this.http.post(`${this.baseAuthUrl}login`, data, {}).pipe(
            tap((response: any) => {
                if (response.tokens && response.user) {
                    this.storageService.storeEncryptedValue(appConstants.USER_INFO, response!.user);
                    this.storageService.storeValue(appConstants.ACCESS_TOKEN_KEY, response.tokens.access.token);
                    this.storageService.storeValue(appConstants.REFRESH_TOKEN_KEY, response.tokens.refresh.token);
                    this.loadToken();
                    this.isAuthenticated.next(true);
                }
            })
        );
    }

    logout() {
        const data = {
            refreshToken: this.storageService.getStorageValue(appConstants.REFRESH_TOKEN_KEY)
        };
        return this.http.post(`${this.baseAuthUrl}logout`, data, {}).pipe(
            tap((response: any) => {
                this.isAuthenticated.next(false);
                this.storageService.removeStoredItem(appConstants.REFRESH_TOKEN_KEY);
                this.storageService.removeStoredItem(appConstants.ACCESS_TOKEN_KEY);
                this.storageService.removeStoredItem(appConstants.USER_INFO);
            })
        );
    }

    register(data) {
        return this.http.post(`${this.baseAuthUrl}register`, data, {});
    }

    changePassword(data) {
        const token = this.storageService.getStorageValue('refresh-token');
        return this.http.post(`${this.baseAuthUrl}reset-password?token=${token}`, data, {});
    }

    forgotPassword(data) {
        return this.http.post(`${this.baseAuthUrl}forgot-password`, data, {});
    }

    getRoles() {
        return this.http.get(`${this.baseURL}users/roles`, {});
    }

    uploadPortfolioImages(id, data) {
        return this.http.patch(`${this.baseURL}business/portfolio/images/${id}`, data, {});
    }

    uploadMultiFile(data) {
        return this.http.post(`${this.baseURL}upload-file/multi`, data, {});
    }

    createUser(data) {
        return this.http.post(`${this.baseURL}users`, data, {});
    }

    updateUser(userId, data) {
        return this.http.patch(`${this.baseURL}users/${userId}`, data, {});
    }

    getUsers(query = '') {
        return this.http.get(`${this.baseURL}users?${query}`, {});
    }

    getBusinessByUserId(userId) {
        return this.http.get(`${this.baseURL}users/business/customer/${userId}`, {});
    }

    getBusinessByExecutiveId(executiveId) {
        return this.http.get(`${this.baseURL}users/business/executive/${executiveId}`, {});
    }

    savePayment(data) {
        return this.http.post(`${this.baseURL}payments`, data, {});
    }

    savePhoneBook(data, id) {
        return this.http.post(`${this.baseURL}users/phoneBook/${id}`, data, {});
    }

    deletePhoneBookByUserId(id) {
        return this.http.delete(`${this.baseURL}users/phoneBook/${id}`, {});
    }

    getPhoneBook(id) {
        return this.http.get(`${this.baseURL}users/phoneBook/${id}`, {});
    }

    getProductCard(productId, BusinessId) {
        return this.http.get(`${this.baseURL}business/getProductCard/${productId}/${BusinessId}`, {});
    }

    downloadImageApi(imageUrl) {
        const data = {
            imageUrl: imageUrl
        };
        return this.http.post(`${this.baseURL}business/downloadImage`, data, { responseType: 'blob' });
    }

    commonError(err: any) {
        const errCode = err.status;
        if (err && err.error && err.error.message) {
            this.showToast(err.error.message);
        } else if (err && err.message) {
            this.showToast(err.message);
        }

        switch (errCode) {
            case 401: {
                this.logout().subscribe((res) => {
                    this.showToast('Logged out due to authentication mismatch');
                    this.router.navigate(['/login']);
                });
                break;
            }

            case 400: {
                this.showToast('Bad Request');
                break;
            }

            case 403: {
                console.log('here');
                // this.logout().subscribe((res) => {
                //     this.showToast('Logged out due to authentication mismatch');
                //     this.router.navigate(['/login']);
                // });
                break;
            }

            case 0: {
                let errorMessage = err.error.message;
                errorMessage = 'No Internet Connection';
                break;
            }
        }
    }
}
