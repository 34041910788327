<nav class="shadow-class navbar sticky-top navbar-expand-md navbar-light bg-light">
    <div #home class="landing-nav container-fluid">
        <a class="navbar-brand d-flex align-items-center justify-content-center" href="#">
            <img width="50" [src]="sharedService?.companyLogo" alt="Company Logo" class="logo-image">
            <div class="d-flex flex-column p-2">
                <div class="business-header font-20">Creative Hand</div>
                <div class="business-sub-header font-12">Make Your business Digital</div>
            </div>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="d-flex ml-auto flex-basis-70">
                <ul class="navbar-nav">
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="cursor-pointer nav-link" aria-current="page">Home</a>
                    </li>
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="cursor-pointer nav-link" (click)="scrollToElement('templateElement')">Product</a>
                    </li>
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="cursor-pointer nav-link" (click)="scrollToElement('multi-lingual')">Multi Language</a>
                    </li>
                    <li style="margin: 0 20px" class="nav-item">
                        <a class="cursor-pointer nav-link" (click)="scrollToElement('contactUs')">Register Your Business</a>
                    </li>
                </ul>
            </div>
            <div class="ml-auto d-flex align-items-center flex-basis-30 justify-content-end">
                <!-- <button class="call-button" style="margin-right: 10px;" mat-button>
                    <mat-icon>call</mat-icon>+91-8208690072</button> -->
                <button class="whatsapp-button" style="margin-right: 20px" mat-button>
                    <i style="margin-right: 10px; font-size: 18px; color: #128c7e"
                        class="fab fa-whatsapp"></i>+91-9403733265</button>
                <button class="setting-icon-mobile" [matMenuTriggerFor]="menu" mat-mini-fab color="primary"
                    aria-label="Example icon button with a filter list icon">
                    <mat-icon>settings</mat-icon>
                </button>
            </div>
        </div>
    </div>
</nav>


<mat-menu #menu="matMenu">
    <button (click)="goToLogin()" mat-menu-item>
        <mat-icon color="primary">account_circle</mat-icon>Log In</button>
</mat-menu>


<div class="overflow-hidden">
    <div class="hero-banner-seven lg-container">
        <div class="container">
            <div class="illustration-container animate__animated animate__bounceInDown">
                <img src="assets/landing-page/ils_20.svg" alt="website creator">
            </div>
            <div class="row">
                <div class="col-lg-7 mr-auto">
                    <h1 class="animate__animated animate__bounceInLeft hero-heading"><span>Create</span> Your Business
                        Mini Website</h1>
                    <div class="animate__animated animate__bounceInLeft text-center hero-sub-heading">
                        <div class="mb-4 mb-md-0">
                            Delivering the Best-in-Class Business Websites
                            <br>
                            Empowering You to Manage Your Business with Ease
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="200">
                            <div class="d-flex justify-content-center p-md-4 p-sm-4">
                                <form [formGroup]="enquiryForm" style="width: 100%">
                                    <input formControlName="contactNumber" maxlength="10" type="text"
                                        placeholder="Enter your Phone Number">
                                    <button (click)="sendEnquiry()">Enquiry</button>
                                </form>
                            </div> <!-- /.newsletter -->
                        </div>
                        <div
                            class="mobile-call-drawer mt-5 flex-column justify-content-start d-flex align-items-center">
                            <button style="background: var(--primary-landing-page); color: white"
                                (click)="onCallAction()" class="width-220-px m-2 p-2 font-16" mat-raised-button>
                                <mat-icon>call</mat-icon>+91-8208690072</button>
                            <button style="background: var(--primary-landing-page); color: white"
                                (click)="initiateWhatsapp()" class="width-220-px m-2 font-16 p-2" mat-raised-button>
                                <i style="margin-right: 10px; margin-top: 5px; font-size: 18px; color: #fff"
                                    class="fab fa-whatsapp"></i>+91-9403733265
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="animate__animated animate__fadeInUp"
        class="fancy-feature-fifteen mt-120 md-mt-80">
        <div class="bg-wrapper">
            <div class="container">
                <div class="sldier-wrapper">
                    <div id="screenSlider" class="carousel slide" data-ride="carousel" data-interval="200000">
                        <div class="row">
                            <div class="col-lg-8 m-auto">
                                <ol class="carousel-indicators justify-content-between">
                                    <li [ngClass]="{'active': selectedTab === 'tab1'}" (click)="onSelectTab('tab1')"
                                        data-target="#screenSlider" class="cursor-pointer">
                                        <div class="d-flex align-items-center">
                                            <!--                                        <img src="images/icon/90.svg" alt="">-->
                                            <h2>Sample Templates</h2>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'active': selectedTab === 'tab2'}" (click)="onSelectTab('tab2')"
                                        data-target="#screenSlider" class="cursor-pointer">
                                        <div class="d-flex align-items-center">
                                            <!--                                        <img src="images/icon/90.svg" alt="">-->
                                            <h2>Connect and Engage</h2>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'active': selectedTab === 'tab3'}" (click)="onSelectTab('tab3')"
                                        data-target="#screenSlider" class="cursor-pointer">
                                        <div class="d-flex align-items-center">
                                            <!--                                        <img src="images/icon/92.svg" alt="">-->
                                            <h2>Hassle-Free Maintenance</h2>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div style="margin-top: 50px" class="carousel-inner mt-60 sm-mt-40">
                            <div [ngClass]="{'active': selectedTab === 'tab1'}" *ngIf="selectedTab === 'tab1'"
                                class="carousel-item animate__animated animate__fadeInLeft">
                                <div class="d-flex flex-column">
                                    <div #templateElement class="template-container container-fluid py-2">
                                        <!-- <h2 class="font-weight-light">Bootstrap 4 Horizontal Scrolling Cards with Flexbox</h2> -->
                                        <div class="d-flex flex-row flex-nowrap">
                                            <div *ngFor="let img of templateImages; let i = index"
                                                class="card card-body">
                                                <ng-container>
                                                    <img [style.z-index]="99 - i" class="full-width"
                                                        src="/assets/template-frames/{{img}}" alt="template-frames-{{i}}">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-column justify-content-center">
                                        <div class="font-12 color-gray"><h2>Color Palette Name</h2></div>
                                        <div class="font-16 font-weight-bold">{{selecedThemeName}}</div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="color-palette">
                                            <div *ngFor="let colorPalette of colors; let colIndex = index"
                                                class="color-combo">
                                                <div (click)="scrollElement(colIndex, colorPalette.name)"
                                                    [style.background-image]="colorPalette.colorBall" class="color-box">
                                                    <i *ngIf="selecedThemeName === colorPalette.name"
                                                        class="fa fa-check"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex justify-content-center">
                                        <img width="50%"  class="object-fit-contain carousel-image" src="assets/landing-page/dash_transparent.png" alt="">
                                    </div> -->
                                    <div class="carousel-description text-center">
                                        Choose from a collection of 15 captivating color combinations, 8 regional
                                        languages, and 3 meticulously crafted templates to create a website that
                                        flawlessly represents your brand.
                                    </div>
                                    <div>
                                        <div style="margin: 0 auto;"
                                            class="flex-wrap d-flex width-80 justify-content-around features-container">
                                            <div *ngFor="let feature of allFeatures" class=" mb-3 feature flex-basis-18 flex-column d-flex align-items-center justify-content-center">
                                                <div>
                                                    <mat-icon style="color: #128c7e; transform: scale(1.1);">check_circle</mat-icon>
                                                </div>
                                                <div class="text-center mt-2 font-1">
                                                   {{feature}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="{'active': selectedTab === 'tab2'}" *ngIf="selectedTab === 'tab2'"
                                class="carousel-item animate__animated animate__fadeInRight">
                                <div class="d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <img width="60%" class="object-fit-contain carousel-image"
                                            src="assets/landing-page/ils_09.svg" alt="Webiste Creator">
                                    </div>
                                    <div class="carousel-description text-center">
                                        Seamlessly integrate your mini website with your social media profiles, email
                                        marketing campaigns, and other online platforms. Connect and engage with your
                                        audience on multiple channels, expanding your reach and driving more traffic to
                                        your business. Maximize your online presence and build strong connections with
                                        potential customers.
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="{'active': selectedTab === 'tab3'}" *ngIf="selectedTab === 'tab3'"
                                class="carousel-item animate__animated animate__fadeInUp">
                                <div class="d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <img width="30%" class="object-fit-contain carousel-image"
                                            src="assets/landing-page/ils-08.png" alt="Website Creator">
                                    </div>
                                    <div class="carousel-description text-center">
                                        With our mini websites, you have the power to make updates and changes
                                        effortlessly. Our user-friendly dashboard allows you to easily modify content,
                                        add new products or services, and keep your mini website up to date. Say goodbye
                                        to the hassle of complex website maintenance and enjoy the convenience of
                                        managing your online presence with ease.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- /.sldier-wrapper -->
            </div>
            <img src="assets/landing-page/168.svg" alt="shape 1" class="shapes shape-one">
            <img src="assets/landing-page/169.svg" alt="shape 2" class="shapes shape-two">
        </div> <!-- /.bg-wrapper -->
    </div>

    <div #container1 class="fancy-feature-sixteen mt-200 md-mt-100" id="feature">
        <div class="container">
            <div style="margin-bottom: 90px;" class="block-style-eighteen pb-5 pt-5">
                <div class=" row align-items-center">
                    <div
                        class="col-lg-5 animate__animated animate__fadeInUp animate__animated animate__fadeInLeft">
                        <div class="text-wrapper">
                            <h6>App Integration</h6>
                            <h3 class="title">Connect with <span>Social Media</span></h3>
                            <p>On Single Click, you can connect your business with social media like, Instagram,
                                Facebook, Google Maps, Whatsapp, Google Pay, Phoane Pay.</p>
                        </div> <!-- /.text-wrapper -->
                    </div>
                    <div
                        class=" col-lg-7 animate__animated animate__fadeInUp">
                        <div class="screen-holder-one d-flex align-items-center justify-content-center">
                            <div class="round-bg d-flex align-items-center justify-content-center"
                                style="width:193px; height: 193px;"><img width="60%" src="assets/landing-page/gmap.png"
                                    alt="gmap"></div>
                            <div class="round-bg d-flex align-items-center justify-content-center shapes logo-one"
                                style="width:65px; height: 65px;"><img width="70%" src="assets/landing-page/phonepe.png"
                                    alt="phone-pe"></div>
                            <div class="round-bg d-flex align-items-center justify-content-center shapes logo-two"
                                style="width:105px; height: 105px;"><img src="assets/landing-page/logo-28.png" alt="logo=28">
                            </div>
                            <div class="round-bg d-flex align-items-center justify-content-center shapes logo-three"
                                style="width:81px; height: 81px;"><img width="70%" src="assets/landing-page/gpay.png"
                                    alt="g-pay"></div>
                            <div class="round-bg d-flex align-items-center justify-content-center shapes logo-four"
                                style="width:148px; height: 148px;"><img width="70%"
                                    src="assets/landing-page/facebook.png" alt="fb"></div>
                            <div class="round-bg d-flex align-items-center justify-content-center shapes logo-five"
                                style="width:105px; height: 105px;"><img width="70%"
                                    src="assets/landing-page/whatsapp.png" alt="wa"></div>
                        </div>
                    </div>
                </div>
            </div> <!-- /.block-style-eighteen -->
            <div
                class="block-style-eighteen animate__animated animate__fadeInUp mt-200 pt-50 md-mt-80 pb-lg-5 pt-5">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="screen-holder-two">
                            <img width="100%" src="assets/landing-page/mini-web-tab.png" alt="mini-web-tab">
                            <img src="assets/landing-page/creative-hand-mob.png" alt="creative-hand-mob" class="shapes screen-one">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="text-wrapper">
                            <h6>Mobile Optimized</h6>
                            <h3 class="title"><span>Mini</span> <br> Business Website</h3>
                            <p>Go paperless. Hosted on a secure and reliable platform. Focus on growing your business
                                while we take care of the technical aspects</p>
                        </div> <!-- /.text-wrapper -->
                    </div>
                </div>
            </div>
            <div #multiLang
                class="animate__animated animate__fadeInUp block-style-eighteen mt-200 pt-50 md-mt-80 pb-5 pt-5">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="text-wrapper pb-4">
                            <h6>Make In India</h6>
                            <h3 class="title"><span>Regional</span>Languages Support</h3>
                            <p>Application supports all Indian Languages such as मराठी, हिंदी, ગુજરાતી, বাঙ্গালী,
                                ਪੰਜਾਬੀ, தமிழ், ಕನ್ನಡ, etc..
                                <br>
                                Try these amazing transitions!

                            </p>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <form [formGroup]="regionalForm">
                                        <div class="col-12">
                                            <mat-form-field style="width: 100%" appearance="outline">
                                                <mat-label>Select Theme</mat-label>
                                                <mat-select formControlName="themeName">
                                                    <mat-option *ngFor="let theme of themes" [value]="theme">{{theme |
                                                        titlecase}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field style="width: 100%" appearance="outline">
                                                <mat-label>Select Color</mat-label>
                                                <mat-select formControlName="color">
                                                    <mat-option *ngFor="let color of colors"
                                                        [value]="color.keyword">{{color?.name | titlecase}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field style="width: 100%" appearance="outline">
                                                <mat-label>Select Language</mat-label>
                                                <mat-select formControlName="language">
                                                    <mat-option *ngFor="let lang of languages"
                                                        [value]="lang.value">{{lang?.name | titlecase}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </form>
                                    <div class="col-12">
                                        <div class="d-flex justify-content-end">
                                            <button (click)="saveRegionalChanges()" mat-raised-button
                                                color="primary">Apply Changes</button>
                                            <button style="margin-left: 20px" *ngIf="!valueChanges"
                                                (click)="checkChanges()" mat-raised-button color="accent">Preview
                                                Demo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> <!-- /.text-wrapper -->
                    </div>
                    <div class="col-lg-7">
                        <div class="screen-holder-three d-flex align-items-center justify-content-center">
                            <div style="width: 450px">
                                <div class="iphone-bezel">
                                    <iframe id="myFrame" [src]="livePreview"></iframe>
                                    <div class="button"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #container2 class="fancy-feature-seventeen mt-150 md-mt-90">
        <div class="bg-wrapper">
            <div class="container">
                <div class="row">
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fas fa-cogs"></i></div>
                            <h4>Easy Website Creation</h4>
                            <p>Create a stunning mini website for your business in minutes. No coding skills required.
                            </p>
                        </div> <!-- /.block-meta -->
                    </div>
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200"
                        data-aos-delay="100">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fas fa-paint-brush"></i></div>
                            <h4>Customizable Templates</h4>
                            <p>Choose from a variety of professionally designed templates and customize them to match
                                your brand.</p>
                        </div> <!-- /.block-meta -->
                    </div>
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200"
                        data-aos-delay="200">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fas fa-columns"></i></div>
                            <h4>User-Friendly Dashboard</h4>
                            <p>Manage all your website content easily using our intuitive and user-friendly dashboard.
                            </p>
                        </div> <!-- /.block-meta -->
                    </div>
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fas fa-mobile-alt"></i></div>
                            <h4>Mobile-Optimized Design</h4>
                            <p>Your mini website will look great and function perfectly on all devices, including mobile
                                phones and tablets.</p>
                        </div> <!-- /.block-meta -->
                    </div>
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200"
                        data-aos-delay="100">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fab fa-facebook"></i></div>
                            <h4>Social Media Integration</h4>
                            <p>Easily connect your mini website with your social media profiles to expand your reach and
                                engage with your audience.</p>
                        </div> <!-- /.block-meta -->
                    </div>
                    <div
                        class="animate__animated animate__fadeInUp col-lg-4 col-sm-6 aos-init" data-aos="fade-up" data-aos-duration="1200"
                        data-aos-delay="200">
                        <div class="block-meta">
                            <div class="icon d-flex justify-content-center"><i class="fas fa-lock"></i></div>
                            <h4>Secure Hosting</h4>
                            <p>Rest assured knowing that your mini website is hosted on secure servers, providing
                                reliability and data protection.</p>
                        </div> <!-- /.block-meta -->
                    </div>
                </div>
            </div>
        </div> <!-- /.bg-wrapper -->
    </div>

    <!--<div #container3 class="pricing-section-six mt-200 md-mt-100" id="pricing">-->
    <!--    <div class="pricing-table-area-six">-->
    <!--        <div class="tab-content">-->
    <!--            <div class="tab-pane active" id="month">-->
    <!--                <div class="row justify-content-center">-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">-->
    <!--                        <div class="pr-table-wrapper">-->
    <!--                            <div class="pack-name" style="background:#FFECEC;">Basic</div>-->
    <!--                            <div class="price">Free</div>-->
    <!--                            <div class="pack-details">TAKE GREAT NOTES</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Sync up to 2 devices </li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note </li>-->
    <!--                                <li>60MB monthly upload limit</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">-->
    <!--                        <div class="pr-table-wrapper active">-->
    <!--                            <div class="pack-name" style="background:#E3F8EF;">Reguler</div>-->
    <!--                            <div class="price">$8.99</div>-->
    <!--                            <div class="pack-details">BE MORE PRODUCTIVE</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Sync up to 2 devices </li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Apply rich formatting</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note size</li>-->
    <!--                                <li>Access notes offline</li>-->
    <!--                                <li>10GBmonthly upload limit</li>-->
    <!--                                <li>Annotate PDFs</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">-->
    <!--                        <div class="pr-table-wrapper">-->
    <!--                            <div class="pack-name" style="background:#FBF3E5;">Business</div>-->
    <!--                            <div class="price">$17.99</div>-->
    <!--                            <div class="pack-details">Get more with team</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Everthing is premium</li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Apply rich formatting</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note size</li>-->
    <!--                                <li>Access notes offline</li>-->
    <!--                                <li>18GB monthly upload limit</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="tab-pane fade" id="year">-->
    <!--                <div class="row justify-content-center">-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">-->
    <!--                        <div class="pr-table-wrapper">-->
    <!--                            <div class="pack-name" style="background:#FFECEC;">Basic</div>-->
    <!--                            <div class="price">Free</div>-->
    <!--                            <div class="pack-details">TAKE GREAT NOTES</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Sync up to 2 devices </li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note </li>-->
    <!--                                <li>60MB monthly upload limit</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">-->
    <!--                        <div class="pr-table-wrapper active">-->
    <!--                            <div class="pack-name" style="background:#E3F8EF;">Reguler</div>-->
    <!--                            <div class="price">$68.99</div>-->
    <!--                            <div class="pack-details">BE MORE PRODUCTIVE</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Sync up to 2 devices </li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Apply rich formatting</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note size</li>-->
    <!--                                <li>Access notes offline</li>-->
    <!--                                <li>10GBmonthly upload limit</li>-->
    <!--                                <li>Annotate PDFs</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-4 col-sm-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">-->
    <!--                        <div class="pr-table-wrapper">-->
    <!--                            <div class="pack-name" style="background:#FBF3E5;">Business</div>-->
    <!--                            <div class="price">$189.99</div>-->
    <!--                            <div class="pack-details">Get more with team</div>-->
    <!--                            <ul class="pr-feature">-->
    <!--                                <li>Everthing is premium</li>-->
    <!--                                <li>Find notes fast with search</li>-->
    <!--                                <li>and tags</li>-->
    <!--                                <li>Apply rich formatting</li>-->
    <!--                                <li>Clip web pages</li>-->
    <!--                                <li>25MB maximum note size</li>-->
    <!--                                <li>Access notes offline</li>-->
    <!--                                <li>18GB monthly upload limit</li>-->
    <!--                            </ul>-->
    <!--                            <a href="#" class="trial-button">Try it Free</a>-->
    <!--                            <div class="trial-text">No card required, cancel any time</div>-->
    <!--                        </div> &lt;!&ndash; /.pr-table-wrapper &ndash;&gt;-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div> &lt;!&ndash; /.pricing-table-area-six &ndash;&gt;-->
    <!--</div>-->

    <footer #contactUs class="theme-footer-seven mt-120 md-mt-100">
        <div class="lg-container">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center" data-aos="fade-up"
                        data-aos-duration="1200">
                        <div class="footer-logo">
                            <img width="100%" [src]="sharedService?.companyLogo" alt="Company Logo" class="logo-image">
                            <div class="text-center">
                                <div class="business-header font-30">Creative Hand</div>
                                <div class="business-sub-header font-10">Make Your business Digital</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1200"
                        data-aos-delay="200">
                        <!--                        <div class="newsletter p-md-4 p-sm-4">-->
                        <!--                            <h5 class="title">Contact Us</h5>-->
                        <!--                            <div class="font-16 pb-3 sub-title">Join to make your business Successful</div>-->
                        <!--                            <form [formGroup]="enquiryForm" action="#">-->
                        <!--                                <input formControlName="contactNumber" type="text" maxlength="10" placeholder="Enter your Phone Number">-->
                        <!--                                <button (click)="sendEnquiry()">Enquiry</button>-->
                        <!--                            </form>-->
                        <!--                            <div class="info sub-title">We will reach you soon!</div>-->
                        <!--                        </div> &lt;!&ndash; /.newsletter &ndash;&gt;-->
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="col-12">
                                    <div class="font-25 business-header link-text">Link Your Business with Us</div>
                                </div>
                                <form [formGroup]="enquiryForm">
                                    <div class="col-12">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Name</mat-label>
                                            <input #businessInput formControlName="name" maxlength="35" type="text"
                                                matInput placeholder="Enter your Name">
                                            <mat-hint align="end">{{businessInput.value.length}} / 35</mat-hint>
                                            <mat-error>Please enter your Name</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Contact Number</mat-label>
                                            <input #contactNumber formControlName="contactNumber" maxlength="10"
                                                type="text" matInput placeholder="Enter Contact Number">
                                            <mat-hint align="end">{{contactNumber.value.length}} / 10</mat-hint>
                                            <mat-error>Please enter Contact Number</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Email</mat-label>
                                            <input formControlName="email" maxlength="40" type="text" matInput
                                                placeholder="Enter Email">
                                            <mat-error>Please enter email</mat-error>
                                        </mat-form-field>
                                    </div>
                                </form>
                                <div class="col-12">
                                    <div class="d-flex justify-content-end">
                                        <button (click)="sendEnquiry()" mat-raised-button color="primary">Send
                                            Enquiry</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="bottom-footer">
                    <div class="row">
                        <div class="col-lg-12 order-lg-2 mb-20">
                            <p class="copyright text-center">Copyright @2023 Creative Hand Co. India</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- /.lg-container -->

    </footer>
</div>
<button (click)="scrollToTop()" *ngIf="scrollBar > 30" class="scroll-top">
    <i class="fa fa-angle-up" aria-hidden="true"></i>
</button>