import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';

export class ValidateAddress {
    static GMapImageRequirement(abstractControl: AbstractControl) {
        const addressMapLink = abstractControl.get('addressMapLink').value;
        const addressLine = abstractControl.get('addressLine').value;

        // Check if addressImageUrl has a value
        if ((addressMapLink && !addressLine) || (!addressMapLink && addressLine)) {
            // Check if either addressMapLink or addressLink has a value
            abstractControl.get('addressLine').setErrors({
                MatchPassword: true
            });
        } else {
            return null;
        }
        // Return null if the validation passes
    }
}

export function GmapLinkValidation(): ValidatorFn {
    // const regex: RegExp = /^https:\/\/www\.google\.com\/maps\/embed\?pb=!.+$/;
    const regex: RegExp = /^<iframe src="https:\/\/www\.google\.com\/maps\/embed\?pb=.+" width="\d+" height="\d+" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"><\/iframe>$/;

    return (control: FormControl): { [key: string]: any } | null => {
        const controlName: string = control.value;
        if (controlName && !regex.test(controlName)) {
            return { invalidWebsite: true };
        }
        return null;
    };
}

export function whatsappCatalogueValidation(): ValidatorFn {
    // const regex: RegExp = /^https:\/\/www\.google\.com\/maps\/embed\?pb=!.+$/;
    const regex: RegExp = /^https:\/\/wa\.me\/c\/\d+(\?text=.*)?$/;

    return (control: FormControl): { [key: string]: any } | null => {
        const controlName: string = control.value;
        if (controlName && !regex.test(controlName)) {
            return { invalidCatalogue: true };
        }
        return null;
    };
}
