import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themesUrl = 'assets/json_files/themes.json';
  private particlesUrl = 'assets/particle-config/particles.json';
  private languagesUrl = 'assets/json_files/languages.json';
  public currentTheme = '';
  constructor(private http: HttpClient) { }

  applyTheme(themeName: string): void {
    this.fetchThemes().then(themes => {
      const theme = themes.find(t => t.keyword === themeName);
      if (theme) {
        this.updateThemeClass(theme.className, theme.colors);
      }
    });
  }

  fetchThemes(): Promise<any[]> {
    return this.http.get<any[]>(this.themesUrl).toPromise();
  }

  fetchParticles(): Promise<any[]> {
    return this.http.get<any[]>(this.particlesUrl).toPromise();
  }

  fetchLanguages(): Promise<any[]> {
    return this.http.get<any[]>(this.languagesUrl).toPromise();
  }

  private updateThemeClass(className: string, colors: any): void {
    const body = document.querySelector('body');
    this.currentTheme = className;
    const existingThemeClasses = Array.from(body.classList).filter(c => c.startsWith('color-theme-'));
    body.classList.remove(...existingThemeClasses);
    body.classList.add(className);

    for (const [variable, value] of Object.entries(colors)) {
      body.style.setProperty(variable, value as string); // Explicitly cast value to string
    }
  }
}
