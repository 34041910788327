import {Component, EventEmitter, HostListener, Inject, PLATFORM_ID, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from "@kolkov/ngx-gallery";
import {FormBuilder, Validators} from "@angular/forms";
import {DomSanitizer, SafeHtml, SafeResourceUrl} from "@angular/platform-browser";
import {SharedService} from "../../services/shared.service";
import {PhoneValidator} from "../../custom-validators/phone-number-validation";
import {ApiService} from "../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EmailValidator} from "../../custom-validators/email-validation";
import {ThemeService} from "../../services/themes.service";
import { saveAs } from 'file-saver';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-theme-cronus',
    templateUrl: './theme-cronus.component.html',
    styleUrls: ['./theme-cronus.component.scss']
})
export class ThemeCronusComponent implements OnInit, OnChanges {
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    isStickToolbar = false;
    primaryColor: any = '#225675';
    mapSrc: SafeHtml = '';
    htmlContent: SafeHtml = '';
    contactNumbers: any = [];
    // websiteData: any = [];
    userDetails: any;
    currentScrollNumber: any;
    whatsappNumber: any = '';
    businessId: any = '';
    shareLink: any = '';
    pageDescription: any = '';
    @Input() businessUrl: any;
    @Input() websiteData: any = [];
    @Input() phoneBookDetails: any = null;
    @Input() views: any = [];
    @Output() submitEnquiry = new EventEmitter<any>();
    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            this.isStickToolbar = number >= 38;
            this.currentScrollNumber = number;
            console.log(number);
        }
    }

    constructor(private snackBar: MatSnackBar, @Inject(PLATFORM_ID) private platformId: object,
                private apiService: ApiService, private formBuilder: FormBuilder, public sharedService: SharedService, public themeService: ThemeService, private sanitizer: DomSanitizer) {

    }

    enquiryForm = this.formBuilder.group({
        businessId: [''],
        name: ['', Validators.required],
        contactNumber: ['', [Validators.required, PhoneValidator()]],
        email: ['', [EmailValidator(), Validators.required]],
        message: ['', Validators.required],
    });


    applyAnimOnScroll(numberVal ) {
        return (this.currentScrollNumber >= numberVal);
    }

    openWhatsAppCatalogue() {
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            window.open(this.websiteData.whatsappCatalogue, '_blank');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'websiteData' : {
                        if(this.websiteData) {
                            this.pageDescription = `Check out ${this.websiteData.business.businessName} - your destination for exceptional products/services. Share this link and spread the word of success.`;
                            this.shareLink = this.sharedService.generatePrototypeUrl(this.websiteData.keywordUrl);
                            if(this.websiteData && Object.keys(this.websiteData).length > 0) {
                                this.userDetails = this.websiteData.userDetails;
                                this.themeService.applyTheme(this.websiteData.theme);
                                if(this.websiteData.business) {
                                    this.websiteData.business.businessImageUrl = this.websiteData.business.businessImageUrl ?
                                        this.sharedService.generateImageUrl(this.websiteData.business.businessImageUrl) :
                                        this.sharedService.noImageIcon;
                                }

                                if(this.websiteData.products) {
                                    this.websiteData.products = this.websiteData.products.map((val) => {
                                        return {
                                            ...val, productImageUrl: this.sharedService.generateImageUrl(val.productImageUrl)
                                        }
                                    })
                                }

                                if(this.websiteData.upiPayments) {
                                    this.websiteData.upiPayments = this.websiteData.upiPayments.map((val) => {
                                        val.imageUrl = `assets/${val.upiType.toLowerCase().split(' ').join('-')}.png`;
                                        return val;
                                    });
                                }

                                if (this.websiteData) {
                                    this.enquiryForm.get('businessId').setValue(this.websiteData._id);
                                    this.businessId = this.websiteData._id;
                                }

                                if (this.websiteData && this.websiteData.address && this.websiteData.address.addressMapLink) {
                                    this.mapSrc = this.sanitizer.bypassSecurityTrustHtml(this.decodeHtmlString(this.websiteData.address.addressMapLink));
                                }
                                if(this.websiteData.specialitiesData) {
                                    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.decodeHtmlString(this.websiteData.specialitiesData));
                                }
                                if (this.websiteData && this.websiteData.portfolioImages) {
                                    this.galleryImages = this.websiteData.portfolioImages.map((val) => {
                                        return {
                                            url: this.sharedService.generateImageUrl(val),
                                            small: this.sharedService.generateImageUrl(val),
                                            medium: this.sharedService.generateImageUrl(val),
                                            big: this.sharedService.generateImageUrl(val)
                                        }
                                    });
                                }

                                if (this.websiteData && this.websiteData.business && this.websiteData.business.contactNumbers) {
                                    this.contactNumbers = this.websiteData.business.contactNumbers.map((val) => {
                                        return val.number;
                                    });
                                    this.whatsappNumber = this.websiteData.business.contactNumbers.find(v => !!v.isPrimary).number;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    decodeHtmlString(htmlString: string): string {
        const parser = new DOMParser();
        const decodedHtml = parser.parseFromString(htmlString, 'text/html');
        return decodedHtml.documentElement.textContent;
    }

    ngOnInit(): void {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
        this.galleryOptions = [
            {
                breakpoint: 800,
                width: '100%',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                preview: true,
                imageAutoPlay: true,
                previewArrows: false,
                previewAutoPlay: true,
                previewAutoPlayPauseOnHover: true
            },
        ];
    }

    public generateVCardOnTheFly = (): any  => {
        const { businessName, qualification, address } = this.websiteData.business;
        const { name, organisation, qualification: phoneBookQualification, address: phoneBookAddress } = this.phoneBookDetails || {};
        const formattedName = name || this.userDetails.name;
        const { firstName, lastName } = this.sharedService.getFirstNameAndLastName(formattedName);
        return {
            formattedName: { firstNames: formattedName },
            name: { firstNames: firstName, lastNames: lastName },
            organization: organisation || businessName,
            title: phoneBookQualification || qualification,
            address: [
                {
                    street: phoneBookAddress || (address && address.addressLine) || '',
                    countryName: 'India',
                },
            ],
            telephone: this.contactNumbers,
            email: [
                {
                    value: this.userDetails.email,
                    param: { type: 'work' },
                },
            ],
            socialUrls: {
                facebook: this.websiteData.socialMediaLinks.facebook,
                linkedin: this.websiteData.socialMediaLinks.linkedin,
                instagram: this.websiteData.socialMediaLinks.instagram,
            },
        };
    };


    onCallAction() {
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            window.location.href = 'tel:' + this.whatsappNumber;
        }
    }

    initiateWhatsapp() {
        const message = 'Hello, I want to send you a message.'; // The pre-filled message
        this.sharedService.sendMessage(message, this.whatsappNumber);
    }

    instagramLink() {
        const url = this.websiteData.socialMediaLinks.instagram;
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            window.open(url, '_blank');
        }
    }

    facebookLink() {
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            const url = this.websiteData.socialMediaLinks.facebook;
            window.open(url, '_blank');
        }
    }

    onSubmitEnquiry() {
        if(this.enquiryForm.valid) {
            this.submitEnquiry.emit(this.enquiryForm.value);
            this.enquiryForm.reset();
        } else {
            this.enquiryForm.markAllAsTouched();
        }
    }

    getProductCard(productId) {
        this.sharedService.showSpinner.next(true);
        this.apiService.getProductCard(productId, this.businessId).subscribe((res: any) => {
            this.apiService.downloadImageApi(this.sharedService.generateImageUrl(res.imagePath)).subscribe((res) => {
                saveAs(res, 'image.png'); // Set the desired file name
                this.sharedService.showSpinner.next(false);
            });
        })
    }

    getEnquiry(productName) {
        const message = `Hi, I have seen your website and I am looking forward to know More about ${productName}.`;
        this.sharedService.sendMessage(message, this.whatsappNumber);
    }
}
