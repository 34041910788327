import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PhoneValidator} from "../custom-validators/phone-number-validation";
import {ApiService} from "../services/api.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {AddPaymentComponent} from "../add-payment/add-payment.component";
import {SharedService} from "../services/shared.service";
import {StorageService} from "../services/storage.service";
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-add-customer',
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
    public addCustomerForm: FormGroup;
    public paymentForm: FormGroup;
    breakpoint: any;
    offers: any = [];
    key: any;
    extensions: any = [
        {
            name: 'One Month Extension',
            days: 30
        },
        {
            name: '11 Months Extension',
            days: 335
        },
        {
            name: '13 Months Extension',
            days: 395
        }
    ];
    btnTitle: any = '';
    title: any = '';
    editMode: any = false;
    payments: any = [
        {
            name: 'Phone Pay',
            key: 'phone_pay',
        },
        {
            name: 'Google Pay',
            key: 'google_pay',
        },
        {
            name: 'Bharat Pay',
            key: 'bharat_pay',
        },
        {
            name: 'Cash',
            key: 'cash',
        }
    ];
    customers: any = [];
    executives: any = [];
    businessId: any = '';

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<AddCustomerComponent>,
        public dialog: MatDialog,
        public apiService: ApiService,
        public storageService: StorageService,
        public sharedService: SharedService,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(MAT_DIALOG_DATA) public modalData,
    ) {

    }

    getGridColumns(): number {
        if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
            return 1;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
            return 2;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
            return 3;
        } else {
            return 3; // Default number of columns for larger screens
        }
    }

    public ngOnInit(): void {
        const currentDate = new Date();
        const subscriptionEndDate = new Date();
        const businessDetails = this.modalData.businessDetails;
        this.addCustomerForm = this.fb.group({
            businessName: ['', [Validators.required]],
            customerId: ['', [Validators.required]],
            executiveId: [''],
            email: [null, [Validators.required, Validators.email]],
            contactNumber: [null, [Validators.required, PhoneValidator()]],
            subscriptionStartDate: [currentDate, [Validators.required, this.sharedService.futureDateValidator]],
            subscriptionEndDate: [subscriptionEndDate, [Validators.required]],
            offerId: null,
            keywordUrl: '',
            extension: '',
            theme: 'CalmPostCard',
            template: 'cronus',
        });
        this.key = this.modalData.key;
        switch(this.key) {
            case 'edit':
                this.btnTitle = 'Edit';
                this.title = 'Edit Customer';
                break;
            case 'add':
                this.btnTitle = 'Add';
                this.title = 'Add Customer';
                break;
            case 'extend':
                this.btnTitle = 'Extend';
                this.title = 'Extend Subscription';
                this.addCustomerForm.get('businessName').disable();
                this.addCustomerForm.get('customerId').disable();
                this.addCustomerForm.get('executiveId').disable();
                this.addCustomerForm.get('keywordUrl').disable();
                this.addCustomerForm.get('subscriptionStartDate').disable();
                this.addCustomerForm.get('subscriptionEndDate').disable();
                break;
        }
        subscriptionEndDate.setTime(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000));
        this.addCustomerForm.get('subscriptionEndDate').disable();
        if (businessDetails) {
            this.businessId = businessDetails.id;
            this.addCustomerForm.get('businessName').setValue(businessDetails.businessName);
            this.addCustomerForm.get('email').setValue(businessDetails.email);
            this.addCustomerForm.get('contactNumber').setValue(businessDetails.contactNumber);
            this.addCustomerForm.get('customerId').setValue(businessDetails.customerId);
            this.addCustomerForm.get('executiveId').setValue(businessDetails.executiveId);
            this.addCustomerForm.get('offerId').setValue(businessDetails.offerId);
            this.addCustomerForm.get('subscriptionStartDate').setValue(businessDetails.subscriptionStartDate);
            this.addCustomerForm.get('subscriptionEndDate').setValue(businessDetails.subscriptionEndDate);
            this.addCustomerForm.get('keywordUrl').setValue(businessDetails.keywordUrl);
            this.editMode = true;
            this.addCustomerForm.get('subscriptionEndDate').enable();
        }
        this.getExecutives();

        this.paymentForm = this.fb.group({
            paymentType: ['', [Validators.required]],
            paymentAmount: ['', [Validators.required]],
            paymentDate: [new Date(), [Validators.required, this.sharedService.futureDateValidator]],
            createdBy: ['', [Validators.required]],
            businessId: [''],
        });
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            this.breakpoint = window.innerWidth <= 600 ? 1 : 3;
        }
        this.addCustomerForm.get('contactNumber').disable();
        this.addCustomerForm.get('email').disable();
        this.getCustomers();
        this.getOffers();
        // Add a value change listener on the offerId form control
        this.addCustomerForm.get('offerId').valueChanges.subscribe((offerId) => {
            if (offerId) {
                const offerDetails = this.offers.find(v => v.id === offerId);
                const offerDays = offerDetails.days;
                const startDate = this.addCustomerForm.get('subscriptionStartDate').value;
                let subscriptionEndDate = new Date(startDate);
                subscriptionEndDate.setDate(subscriptionEndDate.getDate() + offerDays);
                this.addCustomerForm.get('subscriptionEndDate').setValue(subscriptionEndDate);
            } else {
                const startDate = this.addCustomerForm.get('subscriptionStartDate').value;
                let subscriptionEndDate = new Date(startDate);
                subscriptionEndDate.setDate(subscriptionEndDate.getDate() + 365);
                this.addCustomerForm.get('subscriptionEndDate').setValue(subscriptionEndDate);
            }
        });
        this.addCustomerForm.get('extension').valueChanges.subscribe(extension => {
            const startDate = this.addCustomerForm.get('subscriptionStartDate').value;
            let subscriptionEndDate = new Date(startDate);
            subscriptionEndDate.setDate(subscriptionEndDate.getDate() + extension);
            this.addCustomerForm.get('subscriptionEndDate').setValue(subscriptionEndDate);
        });
    }

    onSelectingCustomer(event) {
        const customerId = event.value;
        const customerDetails = this.customers.find(v => v.id === customerId);
        this.addCustomerForm.get('contactNumber').setValue(customerDetails.contactNumber);
        this.addCustomerForm.get('email').setValue(customerDetails.email);
    }

    get subscriptionStartDate() {
        return this.addCustomerForm.get('subscriptionStartDate');
    }

    get subscriptionEndDate() {
        return this.addCustomerForm.get('subscriptionEndDate');
    }

    get paymentDate() {
        return this.paymentForm.get('paymentDate');
    }

    public onResize(event: any): void {
        this.breakpoint = event.target.innerWidth <= 600 ? 1 : 3;
    }

    onEditBusiness() {
        console.log('editing');
    }

    openPaymentDialog(businessId): void {
        const dialogRef = this.dialog.open(AddPaymentComponent, {
            width: '900px', disableClose: false, data: {businessId: businessId}
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    businessAction() {
        if (this.addCustomerForm.valid) {
            if (!this.addCustomerForm.get('offerId').value) {
                this.openPaymentDialog(this.businessId);
                this.apiService.showToast('Please Add Offer to avoid Payment');
                return;
            }
            const keywordUrl = this.addCustomerForm.get('keywordUrl').value.toLowerCase().trim();
            const executiveId = this.addCustomerForm.get('executiveId').value ? this.addCustomerForm.get('executiveId').value : this.apiService.userId;
            let data = {
                business: {
                    businessName: this.addCustomerForm.get('businessName').value,
                    keywordUrl: keywordUrl,
                    contactNumbers: [{
                        number: this.addCustomerForm.get('contactNumber').value,
                        isPrimary: true
                    }],
                },
                ...this.addCustomerForm.value,
                subscriptionEndDate: this.addCustomerForm.get('subscriptionEndDate').value,
                subscriptionStartDate: this.addCustomerForm.get('subscriptionStartDate').value,
                executiveId: executiveId, // loggedIn User
            };

            if (this.editMode) {
                data.id = this.businessId;
            }

            if(this.editMode) {
                this.apiService.updateBusinessDetails(this.businessId, data).subscribe(
                    res => this.saveBusinessDetailsSuccess(res),
                    error => {
                        this.apiService.commonError(error);
                    }
                );
            } else {
                this.apiService.saveBusinessDetails(data).subscribe(
                    res => this.saveBusinessDetailsSuccess(res),
                    error => {
                        this.apiService.commonError(error);
                    }
                );
            }
        } else {
            this.apiService.showToast('Please select mandatory fields');
            this.addCustomerForm.markAllAsTouched();
        }

    }

    saveBusinessDetailsSuccess(res) {
        this.apiService.showToast('Business Created Successfully');
        this.dialogRef.close();
    }

    savePayment() {
        this.apiService.savePayment(this.paymentForm.value).subscribe(
            res => this.savePaymentSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    savePaymentSuccess(res) {
        this.dialogRef.close();
        this.apiService.showToast('Business Created and Payment Saved Successfully');
    }

    getCustomers() {
        const roles = this.storageService.getStorageValue('roles');
        const rolesArray = JSON.parse(roles);
        if (rolesArray) {
            const customer = rolesArray.find(v => v.name.toLowerCase() === 'customer');
            let data = {
                role: customer.id
            };
            const query = this.sharedService.generateQueryString(data);
            this.apiService.getAllUsers(query).subscribe(
                res => this.getCustomersSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    getCustomersSuccess(res) {
        this.customers = res;
    }

    getExecutives() {
        const roles = this.storageService.getStorageValue('roles');
        const rolesArray = JSON.parse(roles);
        if (rolesArray) {
            const customer = rolesArray.find(v => v.name.toLowerCase() === 'executive');
            let data = {
                role: customer.id
            };
            const query = this.sharedService.generateQueryString(data);
            this.apiService.getAllUsers(query).subscribe(
                res => this.getExecutivesSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    getExecutivesSuccess(res) {
        this.executives = res;
    }

    getOffers() {
        this.apiService.getOffers().subscribe(
            res => this.getOffersSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }


    getOffersSuccess(res) {
        this.offers = res;
    }
}
