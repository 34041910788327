<div *ngIf="sharedService.showBrandSpinner | async" style="background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    top: 0;
    justify-content: center;">
  <div style="max-width: 450px;" class="d-flex flex-column full-width">
    <div class="pb-2">
      <ngx-skeleton-loader count="1" [theme]="{ 
        height: '60px',
        width: '100%',
        'background-color': '#e5e5e5',
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div class="full-width d-flex justify-content-center align-items-center">
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '50%',
          height: '150px',
          width: '150px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '5px',
          height: '20px',
          width: '200px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '5px',
          height: '20px',
          width: '300px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '5px',
          height: '20px',
          width: '150px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '5px',
          height: '20px',
          width: '100px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
    </div>
    <div class="item full-width d-flex justify-content-around">
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '50%',
          height: '50px',
          width: '50px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '50%',
          height: '50px',
          width: '50px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '50%',
          height: '50px',
          width: '50px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '50%',
          height: '50px',
          width: '50px',
          'background-color': '#e5e5e5',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
    </div>
    <div class="full-width d-flex justify-content-center pt-4 pb-2">
      <ngx-skeleton-loader count="1" [theme]="{ 
        height: '100px',
        width: '300px',
        'background-color': '#e5e5e5',
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader count="1" [theme]="{ 
        height: '30px',
        width: '100%',
        'background-color': '#e5e5e5',
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
  </div>
</div>