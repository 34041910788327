<mat-drawer-container style="height: 100vh; background: #3f51b538 !important;" autosize>
    <mat-toolbar style="font-size: 14px; font-weight: 400; height: 75px; box-shadow: 0 3px 11px 0 #6c757d3d;">
        <div class="col-lg-12 col-12 row d-flex justify-content-between align-items-center">
            <div class="cursor-pointer col-lg-6 col-6 d-flex justify-content-start align-items-center">
                <div style="width: 55px; margin-right: 10px; border-radius: 50%; object-fit: cover">
<!--                    <img style="border-radius: 50%; object-fit: cover;" width="100%" src="assets/creativeHand/white-background/png/logo-no-background.png" alt="">-->
                    <img style="min-width: 45px; border-radius: 50%; object-fit: cover;" width="100%" [src]="sharedService?.companyLogo" alt="Company Logo">
                </div>
                <div style="line-height: 1.2">
                    <div class="business-header font-20">Creative Hand</div>
                    <div class="business-sub-header">Making Your Business Digital</div>
                </div>
            </div>
            <div class="cursor-pointer col-lg-6 col-6 d-flex justify-content-end">
                <div [matMenuTriggerFor]="userMenu" #menuTrigger class="d-flex justify-content-center align-items-center">
                    <div class="user-icon">
                        <button style="margin-right: 10px" mat-mini-fab color="primary"
                                aria-label="Example icon button with a plus one icon">
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>
                    <div class="user-name-box"><b>Hello, <br> {{apiService?.userName}}</b></div>
                </div>
                <mat-menu #userMenu="matMenu">
                    <ng-template matMenuContent let-id="id">
                        <button mat-menu-item
                                (click)="onChangePassword()">Change Password
                            <mat-icon color="primary">lock</mat-icon>
                        </button>
                        <button mat-menu-item
                                (click)="onLogout()">Log Out
                            <mat-icon color="primary">logout</mat-icon>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>

    </mat-toolbar>

    <div class="row col-12 col-lg-12 col-md-12 dash-container">
        <div class="col-lg-3 col-sm-12 col-md-6 row">
            <div class="col-12">
                <div class="left-tab background-white">
                    <div style="width: 100%; padding: 20px 0 " class="user-image d-flex justify-content-center">
                        <img style="width: 150px" src="assets/business-profile.png" alt="Business Profile">
                    </div>
                    <div class="user-details" style="padding-bottom: 10px; text-align: center; border-bottom: 1px solid lightgray">
                        <div style="padding-bottom: 10px; font-size: 18px;">
                            {{apiService.userName}}
                        </div>
                    </div>
                    <div class="user-information d-flex justify-content-center flex-column">
                        <div class="user-details-tab d-flex justify-content-start align-items-center">
                            <div class="user-details-icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div style="line-height: 1.3; font-size: 14px">
                                <div style="color: gray; font-size: 12px">Contact Number</div>
                                <div>{{apiService.userDetails?.contactNumber}}</div>
                            </div>
                        </div>

                        <div class="user-details-tab d-flex justify-content-start align-items-center">
                            <div class="user-details-icon">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div style="line-height: 1.3; font-size: 14px">
                                <div style="color: gray; font-size: 12px">Email</div>
                                <div style="word-break: break-all">{{apiService.userDetails?.email}}</div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <button (click)="openPhonebookDialog()" mat-raised-button color="primary">
                                <mat-icon>contacts</mat-icon>
                                Update Phonebook</button>
                        </div>
                        <div *ngIf="sharedService.hasNoEmptyValues(phoneBookDetails)" style="margin: 30px 0 10px" class=" d-flex justify-content-center align-items-center flex-column">
                            <button mat-button color="primary">
                                <mat-icon style="color: green">check_circle</mat-icon> Phone Book is Added
                            </button>
                        </div>
                        <div *ngIf="sharedService.hasNoEmptyValues(phoneBookDetails)" class="d-flex justify-content-center align-items-center">
                            <button (click)="deletePhoneBookDetails()" mat-flat-button color="warn"> <mat-icon>delete</mat-icon> Delete PhoneBook</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-6 row d-flex justify-content-center align-items-center">
            <div class="col-lg-12 row">
                <ng-container *ngFor="let business of businessList">
                    <mat-radio-group class="col-lg-4 col-md-12" [(ngModel)]="selectedBusiness">
                        <div  style="margin-bottom: 25px">
                            <div class="single-tab background-white d-flex flex-column justify-content-between">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="flex-basis-80 d-flex flex-column" style="line-height: 1.4">
                                        <div style="font-size: 14px; font-weight: 400; color: gray">Business</div>
                                        <div class="cursor-pointer d-flex align-items-center" (click)="selectBusiness(business?._id)" style="font-size: 18px; color: #666">
                                            <div>{{business?.business?.businessName | titlecase}}</div> <mat-radio-button color="primary" [value]="business?._id"></mat-radio-button>
                                        </div>
                                        <div class="d-flex {{business?.status === 'active' ? 'active-status' : 'de-active-status'}} status-tab">
                                            <div class="cursor-pointer status-tab-cont d-flex justify-content-center align-items-center">
                                                {{business?.status | titlecase}}
                                                <div class="status-tab-dot"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="cursor: pointer; text-align: center"><mat-icon [matMenuTriggerData]="business" [matMenuTriggerFor]="businessMenu">more_vert</mat-icon></div>
                                    <mat-menu #businessMenu="matMenu">
                                        <ng-template matMenuContent let-status="status" let-id="_id">
                                            <button *ngIf="status === 'active'" mat-menu-item (click)="confirmUserAction(id, 'de-active')">Deactivate
                                                <mat-icon color="primary">delete</mat-icon>
                                            </button>
                                            <button *ngIf="status === 'de-active'" mat-menu-item (click)="confirmUserAction(id, 'active')">Activate
                                                <mat-icon color="primary">check</mat-icon>
                                            </button>
                                            <button mat-menu-item (click)="onEditBusinessRow(id)">Edit
                                                <mat-icon color="primary">edit</mat-icon>
                                            </button>
                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div>
                                    <div class="d-flex flex-column" style="line-height: 1.4; margin-bottom: 15px">
                                        <div style="font-size: 12px; font-weight: 400; color: gray">Start Date</div>
                                        <div style="color: #666; font-size: 14px">
                                            {{business?.subscriptionStartDate | date: 'd MMM y'}}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column" style="line-height: 1.4">
                                        <div style="font-size: 12px; font-weight: 400; color: gray">End Date</div>
                                        <div style="color: #666; font-size: 14px">
                                            {{business?.subscriptionEndDate | date: 'd MMM y'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                </ng-container>
            </div>
        </div>
        <div class="col-12 row">
            <div class="col-12">
                <div class="col-12">
                    <div class="bottom-tab background-white">
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                            <mat-tab label="Feedbacks">
                                <div style="padding: 20px" *ngIf="dataSourceFeedback && dataSourceFeedback.length > 0" class="table-container">
                                    <table mat-table [dataSource]="dataSourceFeedback" class="mat-elevation-z8">

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef> Name </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.name}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="contactNumber">
                                            <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.contactNumber}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="rating">
                                            <th mat-header-cell *matHeaderCellDef> Rating </th>
                                            <td mat-cell class="table-column-100" *matCellDef="let element"> {{element.rating | titlecase}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef> Status </th>
                                            <td mat-cell class="table-column-100" *matCellDef="let element"> {{element.status | titlecase}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="feedback">
                                            <th mat-header-cell *matHeaderCellDef> Feedback </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.feedback | titlecase}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action" stickyEnd>
                                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                                            <td [matMenuTriggerData]="element" [matMenuTriggerFor]="userFeedbackMenu" #menuTrigger mat-cell *matCellDef="let element">
                                                <div style="cursor: pointer; text-align: center"><mat-icon>more_vert</mat-icon></div>
                                            </td>
                                        </ng-container>
                                        <mat-menu #userFeedbackMenu="matMenu">
                                            <ng-template matMenuContent let-status="status" let-id="id">
                                                <button *ngIf="status === 'reject' || status === 'pending'" mat-menu-item (click)="onFeedbackAction(id, 'approve')">Approve
                                                    <mat-icon color="primary">check</mat-icon>
                                                </button>
                                                <button *ngIf="status === 'approve' || status === 'pending'" mat-menu-item (click)="onFeedbackAction(id, 'reject')">Reject
                                                    <mat-icon color="primary">cancel</mat-icon>
                                                </button>
                                            </ng-template>
                                        </mat-menu>

                                        <tr mat-header-row *matHeaderRowDef="displayedFeedbackColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedFeedbackColumns;"></tr>
                                    </table>
                                </div>
                                <div class="no-data" *ngIf="dataSourceFeedback.length === 0">
                                    <i class="fa fa-info-circle"></i> No Data Available
                                </div>
                            </mat-tab>
                            <mat-tab label="Enquiry">
                                <div style="padding: 20px" *ngIf="dataSourceEnquiry && dataSourceEnquiry.length > 0" class="table-container">
                                    <table mat-table [dataSource]="dataSourceEnquiry" class="mat-elevation-z8">

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef> Name </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.name}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="contactNumber">
                                            <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.contactNumber}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="email">
                                            <th mat-header-cell *matHeaderCellDef> Email </th>
                                            <td mat-cell class="table-column-200 p-3" *matCellDef="let element"> {{element.email | titlecase}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="message">
                                            <th mat-header-cell *matHeaderCellDef> Enquiry Message </th>
                                            <td mat-cell class="table-column-300" *matCellDef="let element"> {{element.message | titlecase}} </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedEnquiryColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedEnquiryColumns;"></tr>
                                    </table>
                                </div>
                                <div class="no-data" *ngIf="dataSourceEnquiry.length === 0">
                                    <i class="fa fa-info-circle"></i> No Data Available
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-drawer-container>
