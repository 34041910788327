<div class="registration-container">
    <form [formGroup]="changePasswordForm" class="registration-form">
        <div class="logo-container">
            <img [src]="sharedService?.companyLogo" alt="Company Logo" class="logo-image">
            <div class="text-center">
                <div class="business-header font-30">Creative Hand</div>
                <div class="business-sub-header font-10">Make Your business Digital</div>
            </div>
        </div>

        <div style="margin: 15px 10px; font-size: 14px; color: gray" class="text-center">
            Hello <b>{{apiService.userName}}</b>, please change your password with at least one capital letter, special character, and digit in the input box below.
        </div>

        <mat-form-field appearance="outline" class="pb-2 form-field">
            <mat-label>New Password</mat-label>
            <input maxlength="10" matInput formControlName="password" type="password" placeholder="Enter your password">
            <mat-error *ngIf="changePasswordForm.get('password').invalid && changePasswordForm.get('password').touched">
                Password should have special characters, number and upper case or lower case character
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pb-2 form-field">
            <mat-label>Confirm Password</mat-label>
            <input maxlength="10" matInput formControlName="confirmPassword" type="password" placeholder="Confirm your password">
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').invalid && changePasswordForm.get('confirmPassword').touched">
                Please confirm your password
            </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center align-items-center">
            <button mat-raised-button color="primary" (click)="onSubmit()" type="submit">Change Password</button>
        </div>
    </form>
</div>
