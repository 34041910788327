<mat-drawer-container [class]="themeService.currentTheme" class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="push" position="end">
        <mat-nav-list>
            <div class="d-flex justify-content-center pt-4">
                <button [routerLink]="'/login'" mat-raised-button type="submit"
                        class="Update-btn contact-card-button">
                    <mat-icon>person</mat-icon> Customer Login
                </button>
            </div>
            <div class="enquiry-form">
                <h2 class="col-primary text-center">Enquiry Form</h2>
                <form [formGroup]="enquiryForm">
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Full Name</mat-label>
                                <input #customerName maxlength="30" formControlName="name" type="text" matInput placeholder="Enter your name">
                                <mat-hint align="end">{{customerName.value.length}} / 30</mat-hint>
                                <mat-error>Please Enter Your Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Contact Number</mat-label>
                                <input maxlength="10" #mobileNo formControlName="contactNumber" type="text" matInput placeholder="Enter Phone Number">
                                <mat-icon class="col-primary" matSuffix>call</mat-icon>
                                <mat-hint align="end">{{mobileNo.value.length}} / 10</mat-hint>
                                <mat-error>Please Enter Valid Mobile Number</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Email Address</mat-label>
                                <input type="email" matInput formControlName="email" placeholder="Enter your Email">
                                <mat-error>Please Enter Valid Email</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-lg-12">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Leave a comment</mat-label>
                                <textarea rows="5" cols="40" #message maxlength="200" formControlName="message" matInput placeholder="What you feel?"></textarea>
                                <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                                <mat-error>Please Enter Your Message</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center">
                    <button [disabled]="enquiryForm.invalid" (click)="onSubmitEnquiry()" mat-raised-button class="enquiry-btn bg-color-primary">
                        Send Enquiry <i class="fa fa-paper-plane flex-center">
                    </i>
                    </button>
                </div>
            </div>
        </mat-nav-list>
    </mat-drawer>
    <mat-toolbar class="sticky-toolbar">
        <div class="d-flex justify-content-between align-items-center full-width">
            <div class="font-16 d-flex">
                <div><img class="business-icon" src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image"></div>
                <div>{{websiteData?.business?.businessName}}</div>
            </div>
            <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
                    aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="background-test selene selene-theme-container">
        <div class="selene" id="particles-js"></div>
        <div class="selene-theme-main-container">
            <div class="selene-theme-container">
                <div class="d-flex flex-column top-hex-decor">
                    <div class="hex-container">
                        <div class="hex-row width-80">
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                        </div>
                        <div class="hex-middle-row full-width">
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                        </div>
                        <div class="hex-row width-80">
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="selene-theme-container">
                <div class="d-flex flex-column card-container">
                    <div class="d-flex justify-content-center selene-business-image">
                        <div class="d-flex justify-content-center align-items-center hexagon mt-3">
                            <div class="image-container d-flex justify-content-center align-items-center">
                                <img src="{{websiteData?.business?.businessImageUrl}}" alt="Business Image">
                            </div>
                        </div>
                    </div>
                    <div class="m-3 flex-column text-center line-height-12 name-container d-flex justify-content-center">
                        <div class="company-title">{{websiteData?.business?.businessName}}</div>
                        <div class="phone-book-container">
                            <div class="font-16 phonebook-user-name">
                                {{websiteData?.userDetails?.name}}
                            </div>
                            <div class="col-secondary-light font-12 pt-2">{{websiteData?.business?.qualification}}</div>
                            <div style="margin: 10px 0">
                                <button id="downloadButtonNormal"
                                        vcdDownloadVCard
                                        [generateVCardFunction]="generateVCardOnTheFly" mat-raised-button class="color-white contact-card-button">
                                    <mat-icon>contacts</mat-icon> Add to Phonebook
                                </button>
                            </div>
                            <div class="d-flex justify-content-center m-3">
                                <div class="view-container">{{views?.length}} Views</div>
                            </div>
                        </div>
                    </div>
                    <div class="hex-container">
                        <div class="hex-row width-80">
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                            <div class="hexa-small"></div>
                        </div>
                        <div class="hex-middle-row full-width">
                            <div class="hexa-small d-flex justify-content-center align-items-center">
                                <button (click)="onCallAction()" class="hexa-icon-btn" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <mat-icon>call</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="whatsappNumber" class="hexa-small d-flex justify-content-center align-items-center">
                                <button (click)="initiateWhatsapp()" class="hexa-icon-btn" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-whatsapp"></i>
                                </button>
                            </div>
                            <div *ngIf="websiteData.socialMediaLinks.instagram" class="hexa-small d-flex justify-content-center align-items-center">
                                <button (click)="instagramLink()" class="hexa-icon-btn" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-instagram"></i>
                                </button>
                            </div>
                            <div *ngIf="websiteData.socialMediaLinks.facebook" class="hexa-small d-flex justify-content-center align-items-center">
                                <button (click)="facebookLink()" class="hexa-icon-btn" mat-mini-fab color="var(--primary-color)"
                                        aria-label="Example icon button with a menu icon">
                                    <i class="font-25 fab fa-facebook"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="websiteData?.whatsappCatalogue" class="whatsapp-btn-container">
                <div (click)="openWhatsAppCatalogue()" class="whatsapp-button">
                    <img width="30" src="assets/whatsapp_logo.png" alt="wa logo">
                    Open WhatsApp Catalogue
                </div>
            </div>
            <div class="section-content-contact">
                <div class="d-flex full-width overflow-scroll">
                    <div *ngFor="let contact of websiteData?.business?.contactNumbers" class="section-tab">
                        <div class="section-tab-container">
                            <div class="section-tab-image">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div class="section-tab-content">
                                <div class="sec-tab-header"> Contact Number</div>
                                <div class="sec-tab-details">{{contact.number}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="websiteData?.userDetails?.email" class="section-tab">
                        <div class="section-tab-container">
                            <div class="section-tab-image">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div class="section-tab-content">
                                <div class="sec-tab-header"> Email Address</div>
                                <div class="sec-tab-details">{{websiteData?.userDetails?.email}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="websiteData?.business?.website" class="section-tab">
                        <div class="section-tab-container">
                            <div class="section-tab-image">
                                <i class="fa fa-globe"></i>
                            </div>
                            <div class="section-tab-content">
                                <div class="sec-tab-header"> Website</div>
                                <div class="sec-tab-details">
                                    <a [attr.href]="websiteData?.business?.website">
                                        {{websiteData?.business?.website}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let link of websiteData?.business?.otherLinks" class="section-tab">
                        <div class="section-tab-container">
                            <div class="section-tab-image">
                                <i class="fa fa-link"></i>
                            </div>
                            <div class="section-tab-content">
                                <div class="sec-tab-header"> Other Link</div>
                                <div class="sec-tab-details">
                                    <a [attr.href]="link">
                                        {{link}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="selene-theme-container position-relative d-flex flex-column">
                <div class="hex-container">
                    <div class="hex-row width-80">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row full-width">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row width-80">
                        <div class="hexa-small"></div>
                        <!--                    <div class="hexa-small"></div>-->
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                </div>
                <div class="about-us-container">
                    <div class="abt-us-main">
                        <div *ngIf="websiteData?.business?.estYear" class="d-flex align-items-center mb-4">
                            <div>
                                <div class="abt-us-icon d-flex justify-content-center align-items-center">
                                    <i class="fa fa-suitcase"></i>
                                </div>
                            </div>
                            <div class="abt-us-cont d-flex flex-column m-l-1">
                                <div class="header">Year of Establishment</div>
                                <div class="abt-us-title-subtitle">{{websiteData?.business?.estYear}}</div>
                            </div>
                        </div>
                        <div *ngIf="websiteData?.business?.businessType" class="d-flex align-items-center">
                            <div>
                                <div class="abt-us-icon d-flex justify-content-center align-items-center">
                                    <i class="fa fa-industry"></i>
                                </div>
                            </div>
                            <div class="abt-us-cont d-flex flex-column m-l-1">
                                <div class="header">Business Type</div>
                                <div class="abt-us-title-subtitle">{{websiteData?.business?.businessType}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="share-buttons-container" [ngClass]="{'animate__animated animate__fadeInUp' : applyAnimOnScroll(190)}" >
                <div class="d-flex justify-content-around">
                    <share-buttons theme="circles-light share-button"
                                   [include]="['copy', 'whatsapp', 'facebook', 'linkedin']"
                                   [showIcon]="true"
                                   [showText]="true"
                                   [url]="shareLink"
                                   [description]="pageDescription" class="share-button-list">
                    </share-buttons>
                </div>
                <div class="d-flex justify-content-around">
                    <share-buttons theme="circles-light share-button"
                                   [include]="['messenger', 'email', 'sms', 'telegram', ]"
                                   [showIcon]="true"
                                   [showText]="true"
                                   [url]="shareLink"
                                   [description]="pageDescription" class="share-button-list">
                    </share-buttons>
                </div>
                <div class="d-flex justify-content-around">
                    <share-buttons theme="circles-light share-button"
                                   [include]="[ 'twitter', 'print', 'pinterest', 'reddit']"
                                   [showIcon]="true"
                                   [showText]="true"
                                   [url]="shareLink"
                                   [description]="pageDescription" class="share-button-list">
                    </share-buttons>
                </div>
            </div>
            <div *ngIf="websiteData?.address?.addressLine || websiteData?.address?.addressMapLink" class="selene-theme-container position-relative d-flex flex-column">
                <div class="hex-container">
                    <div class="hex-row width-80">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row full-width">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row width-80">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row full-width">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                    <div class="hex-row width-80">
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                        <div class="hexa-small"></div>
                    </div>
                </div>
                <div class="address-container d-flex flex-column justify-content-center align-items-center">
                    <div *ngIf="websiteData?.address?.addressMapLink" [innerHTML]="mapSrc" class="map-link-container">
                    </div>
                    <div *ngIf="websiteData?.address?.addressLine" class="addr-container">
                        {{websiteData?.address?.addressLine}}
                    </div>
                </div>
            </div>
            <div *ngIf="htmlContent" class="specialities-container pt-4">
                <div class="specialities-main">
                    <div class="specialities-title text-center">
                        Our Specialities
                    </div>
                    <div class="specialities-cont d-flex align-items-center mb-3">
                        <div [innerHtml]="htmlContent"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="galleryImages && galleryImages.length" class="photo-gallery">
                <div class="gallery-title">Photo Gallery</div>
                <section class="gallery">
                    <div *ngFor="let pic of galleryImages" class="image">
                        <img [src]="pic?.url" alt="image"/>
                    </div>
                </section>
            </div>
            <div *ngIf="websiteData?.upiPayments && websiteData?.upiPayments?.length"
                 class="upi-details-container">
                <div class="upi-main">
                    <div class="upi-title text-center">
                        UPI Details
                    </div>
                    <div class="about-us-content">
                        <div *ngFor="let payment of websiteData?.upiPayments" class="about-us-tab-container">
                            <div class="d-flex full-width">
                                <div class="about-us-tab-image">
                                    <img class="phone-pe-icon" [src]="payment?.imageUrl" alt="paymemt image">
                                </div>
                                <div class="about-us-tab-content">
                                    <div class="sec-tab-header">{{payment?.upiUserName}}</div>
                                    <div class="sec-tab-details">{{payment?.upiContact}}</div>
                                </div>
                            </div>
                            <div (click)="sharedService.copyToClipboard(payment?.upiContact)" class="copy-icon">
                                <mat-icon class="d-flex align-items-center mat-icon">content_copy</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="websiteData?.products && websiteData?.products.length"
                  class="d-flex flex-column products-container">
                <div *ngFor="let product of websiteData.products; let index = index" class="portfolio-card">
                    <img *ngIf="product?.productImageUrl" class="card-img-top" [src]="product?.productImageUrl" alt="Card image">
                    <div style=" padding: 10px" class="card-body">
                        <div>
                            <div *ngIf="product?.productName" class="portfolio-title text-center">{{product?.productName}}</div>
                            <hr *ngIf="product?.productName">
                            <p *ngIf="product?.productDescription" class="portfolio-description">{{product?.productDescription}}</p>
                        </div>
                        <div class="d-flex justify-content-around mt-4">
                            <button (click)="getProductCard(product?._id)" class="contact-card-button border-2px-secondary" style="margin-right: 10px" mat-raised-button color="primary">
                                <mat-icon>download</mat-icon>
                                Download
                            </button>
                            <button *ngIf="product?.productName" (click)="getEnquiry(product?.productName)" class="contact-card-button border-2px-secondary" mat-raised-button color="primary">
                                <mat-icon>help_outline</mat-icon>
                                Enquiry
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="sharedService.hasNoEmptyValues(websiteData?.bankDetails)" style="padding: 20px; z-index: 99;">
                <app-bank-details-card class="full-width" [bankDetails]="websiteData?.bankDetails"></app-bank-details-card>
            </div>
            <ng-container *ngIf=" websiteData?.videoLinks && websiteData?.videoLinks?.length" >
                <div  *ngFor="let video of websiteData?.videoLinks" class="d-flex justify-content-center youtube-video-container">
                    <youtube-player [width]="330" [height]="180" [videoId]="video.videoId"></youtube-player>
                </div>
            </ng-container>
            <div style="z-index: 99" [ngClass]="{'animate__animated animate__fadeInUp': applyAnimOnScroll(1450)}">
                <app-feedback-form [businessId]="businessId"></app-feedback-form>
            </div>
            <mat-toolbar class="sticky-bottom-toolbar" [ngClass]="{'position-bottom-fixed': isStickToolbar}">
                <div class="bottom-toolbar">
                    © 2023 Creative Hand
                </div>
            </mat-toolbar>
        </div>
    </div>
</mat-drawer-container>

