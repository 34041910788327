import {Component, Input, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneValidator} from "../../custom-validators/phone-number-validation";
import {ApiService} from "../../services/api.service";
import {SharedService} from "../../services/shared.service";

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent {
  customerFeedback: any = [];
  @Input() businessId: any = null;
  ratingForm: FormGroup;
  constructor(public formBuilder: FormBuilder, public apiService: ApiService, public sharedService: SharedService) {
    this.ratingForm = this.formBuilder.group({
      businessId: [''],
      name: ['', Validators.required],
      contactNumber: ['', [Validators.required, PhoneValidator()]],
      feedback: ['', Validators.required],
      rating: [0, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'businessId' : {
            if(this.businessId) {
              this.ratingForm.get('businessId').setValue(this.businessId);
              this.getFeedbackByBusinessId();
            }
          }
        }
      }
    }
  }

  getRatingStars(rating: number): number[] {
    return Array.from({ length: rating }, (_, index) => index + 1);
  }

  onSelectRating(rating) {
    this.ratingForm.get('rating').setValue(rating);
  }

  get selectedFeedback() {
    return this.ratingForm.get('rating').value;
  }

  onSubmitRating() {
    if(this.ratingForm.get('rating').value === 0) {
      this.apiService.showToast('Please select Rating');
      return;
    }

    if(this.ratingForm.valid) {
      this.apiService.saveBusinessFeedback(this.ratingForm.value).subscribe(
          res => this.saveBusinessFeedbackSuccess(res),
          error => {
            this.apiService.commonError(error);
          }
      );
      this.ratingForm.reset();
    } else {
      this.ratingForm.markAllAsTouched();
    }
  }

  saveBusinessFeedbackSuccess(res) {
    this.apiService.showToast('Thanks for feedback. We will verify and update soon');
    this.sharedService.submitFeedbackSuccess.next(true);
  }

  getFeedbackByBusinessId() {
    this.apiService.getFeedbackByBusinessId(this.businessId).subscribe(
        res => this.getFeedbackByBusinessIdSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getFeedbackByBusinessIdSuccess(res) {
    this.customerFeedback = res.data.feedbacks;
    this.customerFeedback = this.customerFeedback.filter(v => v.status === 'approve');
  }
}
