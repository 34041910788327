
<ng-container [ngSwitch]="templateName">
    <div *ngSwitchCase="'cronus'">
        <app-theme-cronus [phoneBookDetails]="phoneBookDetails"
                          (submitEnquiry)="onSubmitEnquiry($event)"
                          [views]="views"
                          [websiteData]="websiteData">
        </app-theme-cronus>
    </div>
    <div *ngSwitchCase="'uranus'">
        <app-theme-uranus [phoneBookDetails]="phoneBookDetails"
                          (submitEnquiry)="onSubmitEnquiry($event)"
                          [views]="views"
                          [websiteData]="websiteData">
        </app-theme-uranus>
    </div>
    <div *ngSwitchCase="'selene'">
        <app-theme-selene [phoneBookDetails]="phoneBookDetails"
                          (submitEnquiry)="onSubmitEnquiry($event)"
                          [views]="views"
                          [websiteData]="websiteData">
        </app-theme-selene>
    </div>
</ng-container>





<div *ngIf="subscriptionEnded" class="subscription-overlay">
    <div class="subscription-message">
        <h1><b>{{message}}</b></h1>
        <div>Please contact the administrator</div>
        <div>Call us now at <span class="phone-number">8208690072</span></div>
        <div class="company-advertisement">
            <img src="assets/company-logo/logo-symbol-no-background.png" alt="Your Company Logo">
            <div>
                <div class="business-header font-25">Creative Hand</div>
                <div class="business-sub-header">Make Your Business Digital</div>
            </div>
            <p>We create websites that leave a lasting impression! Call us now and get started on your next project.</p>
            <p class="contact-info d-flex flex-column align-items-center">
                <button class="mb-3" (click)="onCallAction()" mat-raised-button color="primary">Call Now</button>
                <a target="_blank" href="https://creativehand.co.in">Click here for more information</a>
            </p>
        </div>
    </div>
</div>
