import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export function PhoneValidator(): ValidatorFn {
    const regex: RegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

    return (control: FormControl): { [key: string]: any } | null => {
        const controlName: string = control.value;
        if (controlName && !regex.test(controlName)) {
            return { invalidWebsite: true };
        }
        return null;
    };
}
