<div style="padding: 30px;">
    <div class="add-title d-flex justify-content-between">
        <div>
            <h1 class="primary">Add Phonebook</h1>
        </div>
        <div>
            <button mat-dialog-close mat-raised-button type="button" class="Discard-btn">Cancel</button>
            <button (click)="savePhoneBook()" mat-raised-button type="submit" color="primary" class="Update-btn">
                Add
            </button>
        </div>
    </div>
    <hr>
    <div>
        <ng-container>
            <form [formGroup]="phoneBookForm">
                <mat-grid-list cols="1" rowHeight="105px" rowWidth="200px">
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Customer Name</mat-label>
                                <input #customerName type="text" maxlength="30" matInput placeholder="Customer Name"
                                       formControlName="name">
                                <mat-hint align="end">{{customerName.value.length}} / 30</mat-hint>
                                <mat-error>Please Enter Valid Customer Name</mat-error>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Organisation</mat-label>
                                <input type="text" maxlength="35" matInput placeholder="Organisation Name"
                                       formControlName="organisation">
                                <mat-hint align="end">{{customerName.value.length}} / 35</mat-hint>
                                <mat-error>Please Enter Valid Organisation Name</mat-error>

                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Business Title</mat-label>
                                <input #title type="text" maxlength="45" matInput placeholder="Owner Title"
                                       formControlName="qualification">
                                <mat-hint align="end">{{title.value.length}} / 45</mat-hint>
                                <mat-error>Please Enter Valid Business Title</mat-error>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <textarea #addressLine maxLength="100" formControlName="address"
                                          matInput placeholder="Address Line 1"></textarea>
                                <mat-hint align="end">{{addressLine.value.length}} / 100</mat-hint>
                                <mat-error>Please Enter Business Address</mat-error>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>

                </mat-grid-list>
            </form>
        </ng-container>
    </div>
</div>
