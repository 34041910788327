import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-brand-spinner',
  templateUrl: './brand-spinner.component.html',
  styleUrls: ['./brand-spinner.component.scss']
})
export class BrandSpinnerComponent {
  constructor(public sharedService: SharedService) {}
}
