import {inject, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {ThemeCronusComponent} from "./themes/theme-cronus/theme-cronus.component";
import {CreateWebsiteComponent} from "./create-website/create-website.component";
import {PrototypeComponent} from "./prototype/prototype.component";
import {LoginComponent} from "./login/login.component";
import {RegistrationPageComponent} from "./registration-page/registration-page.component";
import {AuthService} from "./services/auth.service";
import {CustomerDashboardComponent} from "./customer-dashboard/customer-dashboard.component";
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import { OurProductsComponent } from './our-products/our-products.component';

const routes: Routes = [
  { path:  '', component:  LandingPageComponent, data: { title: 'Home - Creative Hand | Make Your Business Digital' }},
  { path:  'our-product', component:  OurProductsComponent},
  { path:  'login', component:  LoginComponent, canActivate: [async () => await inject(AuthService).userBeforeLoggedIn()]},
  { path:  'register', component:  RegistrationPageComponent},
  { path:  'create-website/:id', component:  CreateWebsiteComponent, canActivate: [async () => await inject(AuthService).isUserLoggedIn()] },
  {
    path: 'web/:keywordUrl',
    component: PrototypeComponent,
    data: {
      title: 'business'
    }
  },
  { path:  'dashboard', component:  DashboardComponent, canActivate: [async () => await inject(AuthService).isUserLoggedIn()] },
  { path:  'theme-cronus', component:  ThemeCronusComponent, canActivate: [async () => await inject(AuthService).isUserLoggedIn()] },
  { path:  'customer-dashboard', component:  CustomerDashboardComponent, canActivate: [async () => await inject(AuthService).isUserLoggedIn()] },
  { path:  'change-password', component:  ChangePasswordComponent, canActivate: [async () => await inject(AuthService).isUserLoggedIn()] },
  { path:  '**', component:  LandingPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
