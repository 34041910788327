import { AbstractControl, FormGroup } from '@angular/forms';

export class ValidateUPI {
    static CheckAllValues(abstractControl: AbstractControl) {
        const upiType = abstractControl.get('upiType').value;
        const upiUserName = abstractControl.get('upiUserName').value;
        const upiContact = abstractControl.get('upiContact').value;

        // Check if upiType has a value
        if (upiType) {
            // Check if either upiUserName or addressLink has a value
            if (!upiUserName && !upiContact) {
                abstractControl.get('upiUserName').setErrors({
                    MatchPassword: true
                });
                abstractControl.get('upiContact').setErrors({
                    MatchPassword: true
                });
            }
        } else {
            return null;
        }
        // Return null if the validation passes
    }
}
