<div class="cc">
    <div class="cc__front">
        <div class="cc__balance-text">
            <div class="bank-cc-container">
                <div class="bank-cc-logo">
                    <i class="fa fa-university"></i>
                </div>
                <div class="back-cc-header-container">
                    <div class="bank-cc-header">Bank Name</div>
                    <div class="bank-cc-content">{{bankDetails?.bankName}}</div>
                </div>
            </div>
            <div class="bank-cc-container">
                <div class="bank-cc-logo">
                    <i class="fa fa-user"></i>
                </div>
                <div class="back-cc-header-container">
                    <div class="bank-cc-header">Account Name</div>
                    <div class="bank-cc-content">{{bankDetails?.accountName}}</div>
                </div>
            </div>
            <div class="bank-cc-container">
                <div class="bank-cc-logo">
                    <i class="fa fa-credit-card"></i>
                </div>
                <div class="back-cc-header-container">
                    <div class="bank-cc-header">Account Number</div>
                    <div class="bank-cc-content">{{bankDetails?.accountNumber}}</div>
                </div>
            </div>
            <div class="bank-cc-container">
                <div class="bank-cc-logo">
                    <i class="fa fa-code"></i>
                </div>
                <div class="back-cc-header-container">
                    <div class="bank-cc-header">IFSC Code</div>
                    <div class="bank-cc-content">{{bankDetails?.IFSCCode}}</div>
                </div>
            </div>
            <div class="mb-0  bank-cc-container">
                <div class="bank-cc-logo">
                    <i class="fa fa-money-check"></i>
                </div>
                <div class="back-cc-header-container">
                    <div class="bank-cc-header">Account Type</div>
                    <div class="bank-cc-content">{{bankDetails?.accountType}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
