import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Renderer2,
    ViewChild,
    Inject, PLATFORM_ID
} from '@angular/core';
import {Editor, Toolbar} from "ngx-editor";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../services/api.service";
import {
    GmapLinkValidation,
    ValidateAddress
} from "../custom-validators/address-validation";
import {websiteValidator} from "../custom-validators/website-validation";
import {ValidateProduct} from "../custom-validators/product-validation";
import {ValidateUPI} from "../custom-validators/upi-validation";
import {EmailValidator} from "../custom-validators/email-validation";
import {PhoneValidator} from "../custom-validators/phone-number-validation";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SharedService} from "../services/shared.service";
import {ThemeService} from "../services/themes.service";
import {NgxImageCompressService} from "ngx-image-compress";
import {appConstants} from "../../assets/constants/app-constants";
import {StorageService} from "../services/storage.service";
import {DialogAnimationsExampleDialog} from "../app.component";
import {MatDialog} from "@angular/material/dialog";
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-create-website',
    templateUrl: './create-website.component.html',
    styleUrls: ['./create-website.component.scss']
})
export class CreateWebsiteComponent implements AfterViewInit {

    submitted = false;
    businessCategory: any = 'business';
    livePreview: any = '';
    keywordUrl: any = '';
    companyLogo: any = appConstants.creativeHandLogo;
    // City names
    City: any = ['Florida', 'South Dakota', 'Tennessee', 'Michigan'];
    fruits: any = [];
    templates: any = [
        'cronus', 'uranus', 'selene'
    ];
    upiTypes: any = [
        {upiName: "Google Pay", assetLink: "assets/google-pay.png"},
        {upiName: "Phone Pe", assetLink: "assets/phone-pe.png"},
        {upiName: "Bharat Pay", assetLink: "assets/bharat_pay.png"},
        {upiName: "Amazon Pay", assetLink: "assets/amazon_pay.png"},
        {upiName: "Apple Pay", assetLink: "assets/apple_pay.png"}
    ];

    businessTypes: string[] = ["Manufacturer", "Software Company", "Retailer", "Wholesaler", "Distributor", "Exporter/importer",
        "Beauty Salon", "Cosmetologist",
        "Service provider", "Restaurant", "Hotel", "Consultancy Services", "Architect and Interior Designer",
        "Digital marketing", "Education", "Food", "Property Buyer/Seller", "jewellers", "Other"];
    editor: Editor;
    html: '';
    yearsList = [
        '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012',
        '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000',
        '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988',
        '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976',
        '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964',
        '1963', '1962', '1961', '1960', '1959', '1958', '1957'
    ];
    themes: any = [];
    customers: any = [];
    selectedTemplate: 'cronus';
    selectedTheme: 'cronus';
    randomQueryParameter: any = 1;
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        public fb: FormBuilder,
        private sanitizer: DomSanitizer,
        public apiService: ApiService,
        private cd: ChangeDetectorRef,
        public route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private router: Router,
        public sharedService: SharedService,
        public storageService: StorageService,
        public themeService: ThemeService,
        public renderer: Renderer2,
        public dialog: MatDialog,
        private imageCompress: NgxImageCompressService
    ) {
        
    }

    ngAfterViewInit() {
        // this.setZoomLevel(80);
    }

    setZoomLevel(zoom: number) {
        const element = document.documentElement;

        this.renderer.setStyle(element, 'zoom', `${zoom}%`);
        this.renderer.setStyle(element, '-moz-transform', `scale(${zoom / 100})`);
        this.renderer.setStyle(element, '-webkit-transform', `scale(${zoom / 100})`);
        this.renderer.setStyle(element, '-ms-transform', `scale(${zoom / 100})`);
        this.renderer.setStyle(element, 'transform', `scale(${zoom / 100})`);
    }

    defaultBusinessImage: any = 'assets/business-profile.png';
    noImageIcon: any = 'assets/no-image.jpeg';
    businessId: any = null;
    /*##################### Registration Form #####################*/
    registrationForm = this.fb.group({
        business: this.fb.group({
            businessImage: [null],
            businessImageUrl: [''],
            businessImageBase64: [''],
            businessName: ['', [Validators.required, Validators.minLength(2)]],
            businessType: ['', [Validators.required]],
            estYear: [''],
            ownerName: ['', [Validators.required, Validators.minLength(2)]],
            qualification: [''],
            contactNumbers: this.fb.array([
                this.fb.group({
                    number: ['', [PhoneValidator()]],
                    isPrimary: [{value: false}],
                })
            ]),
            email: ['', [EmailValidator()]],
            website: ['', [websiteValidator()]],
            otherLinks: this.fb.array([
                this.fb.control(''),
                // this.fb.control('', websiteValidator()),
                // this.fb.control('', [Validators.pattern('^[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$')])
            ]),
        }),
        address: this.fb.group({
            addressMapLink: ['', GmapLinkValidation()],
            addressLine: [''],
        }),
        // , {
        //     validator: ValidateAddress.GMapImageRequirement
        // }),
        whatsappCatalogue: [''],
        socialMediaLinks: this.fb.group({
            instagram: ['', websiteValidator()],
            facebook: ['', websiteValidator()],
            linkedin: ['', websiteValidator()],
        }),
        specialities: [''],
        products: this.fb.array([
            this.fb.group({
                productImage: [''],
                productImageUrl: [''],
                productImageUrlOnUI: [''],
                productImageBase64: [''],
                productName: [''],
                productDescription: [''],
            })
            // , {
            //     validator: ValidateProduct.FormControlRequirement
            // }),
        ]),
        portfolioImages: this.fb.array([]),
        theme: [''],
        template: [''],
        bankDetails: this.fb.group({
            bankName: [''],
            accountName: [''],
            accountNumber: [''],
            IFSCCode: [''],
            accountType: [''],
        }),
        upiPayments: this.fb.array([
            this.fb.group({
                upiType: '',
                upiUserName: '',
                upiContact: ['', PhoneValidator()],
            }, {
                validator: ValidateUPI.CheckAllValues
            })
        ]),
        videoLinks: this.fb.array([
            this.fb.group({
                videoLink: ['', websiteValidator()],
                videoId: '',
                isVerified: false,
            })
        ]),
    });


    /*########################## File Upload ########################*/
    @ViewChild('fileInput') el: ElementRef;
    businessImage: any = '';
    imageUrl: any = 'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
    editFile: boolean = true;
    removeUpload: boolean = false;
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    saveBusinessData() {
        let registrationFormValue = this.registrationForm.value;
        delete registrationFormValue.business.businessImageUrl;
        delete registrationFormValue.business.businessImage;
        registrationFormValue.portfolioImages = registrationFormValue.portfolioImages.map((image: any) => {
            return image.imageUrl;
        });
        registrationFormValue.products = registrationFormValue.products.map((image: any) => {
            return {
                productImageUrl: image.productImageUrl,
                productName: image.productName,
                productDescription: image.productDescription,
            }
        });
        this.apiService.updateBusinessDetails(this.businessId, registrationFormValue).subscribe(
            res => this.cancelBookingSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    openDialogPrompt(title: string, confirmLabel: any, message: string, confirmFunction: (id: string) => void, id: string, portfolioId: string): void {
        const enterAnimationDuration = '0ms';
        const exitAnimationDuration = '0ms';
        const dialogRef = this.dialog.open(DialogAnimationsExampleDialog, {
            width: '400px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                title: title,
                message: message,
                confirmLabel: confirmLabel,
                businessId: id,
                portfolioId: portfolioId,
                confirmFunction: confirmFunction
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // Handle the result data here
            console.log('Dialog closed with result:', result);
        });
    }

    getCustomers() {
        const roles = this.storageService.getStorageValue('roles');
        const rolesArray = JSON.parse(roles);
        if (rolesArray) {
            const customer = rolesArray.find(v => v.name.toLowerCase() === 'customer');
            let data = {
                role: customer.id
            };
            const query = this.sharedService.generateQueryString(data);
            this.apiService.getAllUsers(query).subscribe(
                res => this.getCustomersSuccess(res),
                error => {
                    this.apiService.commonError(error);
                }
            );
        }
    }

    getCustomersSuccess(res) {
        this.customers = res;
    }

    onSelectingCustomer(event) {
        const customerDetails = this.customers.find(v => v.id === event.value);
        this.businessForm.get('email').setValue(customerDetails.email);
    }

    updateProductImage(index) {
        let formData = new FormData();
        const imageFile = this.products.controls[index].get('productImage').value;
        formData.append('file', imageFile, imageFile.name);
        this.apiService.updateProductImage(this.businessId, formData).subscribe(
            res => this.updateProductImageSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    updateProductImageSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.showToast('Product Image Updated Successfully.');
        this.getBusinessById(this.businessId);
    }

    uploadPortFolioMultipleImages(formData) {
        this.apiService.updateMultiplePortfolioImages(this.businessId, formData).subscribe(
            res => this.updatePortfolioImageSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    updatePortfolioImage(index) {
        let formData = new FormData();
        const imageFile = this.portfolioImages.controls[index].get('imageFile').value;
        formData.append('file', imageFile, imageFile.name);
        this.apiService.updatePortfolioImage(this.businessId, formData).subscribe(
            res => this.updatePortfolioImageSuccess(res),
            error => {
                this.sharedService.showSpinner.next(false);
                this.apiService.commonError(error);
            }
        );
    }

    updatePortfolioImageSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.getBusinessById(this.businessId);
        this.showToast('Business Portfolio Image Updated Successfully.');
    }

    cancelBookingSuccess(res) {
        this.sharedService.showSpinner.next(false);
        this.livePreview = this.sanitizer.bypassSecurityTrustResourceUrl(this.sharedService.generatePrototypeUrl(res.keywordUrl));
        this.livePreview = `${this.livePreview}?rand=${Math.floor(Math.random() * 9) + 1};`
        this.showToast('User Updated Successfully.');
    }

    ngOnInit(): void {
        this.getCustomers();
        this.businessForm.get('email').disable();
        this.editor = new Editor();
        this.themeService.fetchThemes().then(themes => {
            this.themes = themes;
        });
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
        this.businessId = this.route.snapshot.paramMap.get('id');
        if (this.businessId) {
            this.getBusinessById(this.businessId);
        }
    }

    getBusinessById(id) {
        this.apiService.getBusinessById(id).subscribe(
            res => this.getBusinessByIdSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    get businessForm() {
        return this.registrationForm.get('business');
    }

    get productForm() {
        return this.registrationForm.get('products');
    }

    get addressForm() {
        return this.registrationForm.get('address');
    }

    get socialMediaLinksForm() {
        return this.registrationForm.get('socialMediaLinks');
    }

    getBusinessByIdSuccess(res) {
        const data = res;
        this.patchBusinessForm(res);
        if (res && res.address && Object.keys(res.address).length) {
            if (res.address.addressMapLink) {
                const addressMapLink = res.address.addressMapLink;
                // logic to decode the html again
                res.address.addressMapLink = this.sharedService.decodeHTML(addressMapLink);
            }
            this.addressForm.setValue(res.address);
        }

        if (res && res.socialMediaLinks && Object.keys(res.socialMediaLinks).length) {
            this.socialMediaLinksForm.setValue(res.socialMediaLinks);
        }

        if (res && res.specialities) {
            this.registrationForm.get('specialities').setValue(this.sharedService.decodeHtmlString(res.specialities));
        }

        if (res && res.bankDetails && Object.keys(res.bankDetails).length) {
            this.registrationForm.get('bankDetails').setValue(res.bankDetails);
        }

        console.log(this.registrationForm.value);
    }


    patchBusinessForm(res) {
        this.businessForm.get('businessName').setValue(res.business.businessName);
        this.clearContactsFormArray();
        this.keywordUrl = res.keywordUrl;
        this.livePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.sharedService.generatePrototypeUrl(res.keywordUrl));
        res.business.contactNumbers.forEach((ctrl) => {
            this.addContactNumber(ctrl.number, ctrl.isPrimary);
        });
        this.businessForm.get('ownerName').setValue(res.business.ownerName);
        const imagePath = res.business.businessImageUrl ? this.sharedService.generateImageUrl(res.business.businessImageUrl) : this.noImageIcon;
        this.businessForm.get('businessImageUrl').setValue(imagePath);
        this.businessForm.get('businessImageBase64').setValue('');
        this.businessForm.get('businessType').setValue(res.business.businessType);
        this.businessForm.get('qualification').setValue(res.business.qualification);
        this.businessForm.get('website').setValue(res.business.website);
        this.businessForm.get('estYear').setValue(res.business.estYear);
        this.businessForm.get('email').setValue(res.business.email);
        this.selectedTemplate = res.template;
        this.selectedTheme = res.theme;
        this.clearOtherLinksArray();
        res.business.otherLinks.forEach((ctrl) => {
            this.addOtherLink(ctrl);
        });
        this.clearUpiPaymentsArray();
        if (res.upiPayments) {
            res.upiPayments.forEach((val, index) => {
                this.addUpiPayments(val.upiType, val.upiUserName, val.upiContact);
            });
        }
        this.clearVideoLinksArray();
        if (res.videoLinks) {
            res.videoLinks.forEach((val, index) => {
                const videoId = this.getVideoId(val.videoLink);
                this.addVideoLinks(val.videoLink, videoId, true);
            });
        }

        this.clearProductsArray();
        if (res.products) {
            res.products.forEach((val, index) => {
                this.addProductsForm(val.productName, val.productImageUrl,
                    this.sharedService.generateImageUrl(val.productImageUrl),
                    '',
                    val.productDescription);
            });
        }

        this.clearPortfolioArray();
        if (res.portfolioImages) {
            res.portfolioImages.forEach((val, index) => {
                this.portfolioImages.push(this.fb.group({
                    imageUrl: val,
                    imageUrlOnUI: this.sharedService.generateImageUrl(val),
                    imageFile: null,
                    imageBase64: ''
                }))
            });
        }
        const customerDetails = this.customers.find(v => v.id === res.customerId);
        this.businessForm.get('ownerName').setValue(res.customerId);
        if (customerDetails && customerDetails.email) {
            this.businessForm.get('email').setValue(customerDetails.email);
        }
    }

    clearContactsFormArray() {
        while (this.contactNumbers.length !== 0) {
            this.contactNumbers.removeAt(0)
        }
    }

    addPortfolio() {
        this.portfolioImages.push(this.fb.group({
            imageUrl: '',
            imageUrlOnUI: '',
            imageBase64: '',
            imageFile: '',
        }));
    }

    clearOtherLinksArray() {
        while (this.otherLinks.length !== 0) {
            this.otherLinks.removeAt(0)
        }
    }

    clearUpiPaymentsArray() {
        while (this.upiPayments.length !== 0) {
            this.upiPayments.removeAt(0)
        }
    }

    clearVideoLinksArray() {
        while (this.videoLinks.length !== 0) {
            this.videoLinks.removeAt(0)
        }
    }

    clearProductsArray() {
        while (this.products.length !== 0) {
            this.products.removeAt(0)
        }
    }

    clearPortfolioArray() {
        while (this.portfolioImages.length !== 0) {
            this.portfolioImages.removeAt(0)
        }
    }

    getUPIImage(upiType) {
        let noImage = "assets/no-image.png";
        const upiCheck = upiType.split(' ').join('_');
        switch (upiCheck) {
            case 'google_pay': {
                noImage = "assets/google-pay.png";
                break;
            }
            case 'phone_pay': {
                noImage = "assets/phone-pe.png";
                break;
            }
            case 'bharat_pay': {
                noImage = "assets/bharat_pay.png";
                break;
            }
            case 'apple_pay': {
                noImage = "assets/apple_pay.png";
                break;
            }
            case 'amazon_pay': {
                noImage = "assets/amazon_pay.png";
                break;
            }
        }
        return noImage;
    }

    onChangeTheme(event) {
        this.registrationForm.get('theme').setValue(event.target.value);
    }

    onChangeTemplate(event) {
        this.registrationForm.get('template').setValue(event.target.value);
        this.selectedTemplate = event.target.value;
    }

    get upiPayments(): FormArray {
        return this.registrationForm.get('upiPayments') as FormArray;
    }

    get products(): FormArray {
        return this.registrationForm.get('products') as FormArray;
    }

    get contactNumbers(): FormArray {
        return this.registrationForm.get('business.contactNumbers') as FormArray;
    }

    get otherLinks(): FormArray {
        return this.registrationForm.get('business.otherLinks') as FormArray;
    }

    get videoLinks(): FormArray {
        return this.registrationForm.get('videoLinks') as FormArray;
    }

    onChangePrimary(index) {
        this.contactNumbers.controls.forEach((ctrl, ctrlIndex) => {
            if (ctrlIndex !== index) {
                ctrl.get('isPrimary').setValue(false);
            }
        });
    }

    addUpiPayments(upiType = '', upiUserName = '', upiContact = '') {
        this.upiPayments.push(this.fb.group({
            'upiType': [upiType],
            'upiUserName': [upiUserName],
            'upiContact': [upiContact, [Validators.required, Validators.pattern('^(\\+91[\\-\\s]?)?[0]?(91)?[789]\\d{9}$')]],
        }));
    }

    addProductsForm(productName = '', productImageUrl = '', productImageUrlOnUI = '', productImageBase64 = '', productDescription = '') {
        this.products.push(this.fb.group({
            productImage: [''],
            productImageBase64: [productImageBase64],
            productImageUrl: [productImageUrl],
            productImageUrlOnUI: [productImageUrlOnUI],
            productName: [productName],
            productDescription: [productDescription],
        }));
    }

    addContactNumber(number = '', isPrimary = false) {
        this.contactNumbers.push(
            this.fb.group({
                'number': [number, [Validators.required, Validators.pattern('^(\\+91[\\-\\s]?)?[0]?(91)?[789]\\d{9}$')]],
                'isPrimary': [isPrimary],
            })
        ); // Add a new FormControl to the FormArray
    }

    addOtherLink(link = '') {
        this.otherLinks.push(
            this.fb.control(link)
        ); // Add a new FormControl to the FormArray
    }

    addVideoLinks(videoLink = '', videoId = '', isVerified = false) {
        this.videoLinks.push(this.fb.group({
            videoLink: videoLink,
            videoId: videoId,
            isVerified: isVerified,
        })); // Add a new FormControl to the FormArray
    }

    removeUpiPayment(i) {
        (this.registrationForm.get('upiPayments') as FormArray).removeAt(i)
    }

    removeProductsForm(i) {
        (this.registrationForm.get('products') as FormArray).removeAt(i)
    }

    removeContactNumber(i) {
        (this.registrationForm.get('business.contactNumbers') as FormArray).removeAt(i)
    }

    removeOtherLinks(i) {
        (this.registrationForm.get('business.otherLinks') as FormArray).removeAt(i)
    }

    removeVideoLinks(i) {
        (this.registrationForm.get('videoLinks') as FormArray).removeAt(i)
    }

    verifyVideo(link, index) {
        const videoId = this.getVideoId(link);
        this.videoLinks.controls[index].get('videoId').setValue(videoId);
        this.videoLinks.controls[index].get('isVerified').setValue(true);
    }

    getVideoId(url: string): string | null {
        const pattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)(?:&.*)?$/;
        const match = url.match(pattern);
        if (match && match[1]) {
            return match[1];
        }
        return null;
    }


    async uploadFile(event) {
        let reader = new FileReader();
        let image = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            const fileSizeInMB = image.size / (1024 * 1024);
            if (fileSizeInMB > 35) {
                this.apiService.showToast('File size should not exceed 35 MB');
                return;
            }
            this.sharedService.showSpinner.next(true);
            reader.readAsDataURL(image);
            reader.onload = (onLoadEvent) => {
                const fileName = `business-profile-${this.businessId.toString().toLowerCase()}.png`;
                const localUrl = onLoadEvent.target.result;
                this.sharedService.compressFile(localUrl, fileName).then((compressedImage: any) => {
                    const base64: string = compressedImage.base64;
                    this.businessImageFile.setValue(compressedImage.imageFile);
                    this.businessImageBase64.setValue(base64);
                    this.uploadBusinessImage();
                });
            }
        }
    }


    compressFile(image, fileName) {
        var orientation = -1;
        const sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
        console.warn('Size in bytes is now:', sizeOfOriginalImage);

        this.imageCompress.compressFile(image, orientation, 50, 50)
            .then(result => {
                const imgResultAfterCompress = result;
                console.log(result);
                const sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
                console.warn('Size in bytes after compression:', sizeOFCompressedImage);
                const imageName = fileName;
                const imageBlob = this.dataURItoBlob(imgResultAfterCompress.split(',')[1]);
                const imageFile = new File([result], imageName, {type: 'image/jpeg'});
                console.log("file size:", imageFile['size'] / (1024 * 1024));
            })
            .catch(error => {
                console.error('Error occurred during compression:', error);
            });
    }


    dataURItoBlob(dataURI) {
        let byteString = null;
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            byteString = window.atob(dataURI);
        }
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], {type: 'image/jpeg'});
        return blob;
    }

    uploadBusinessImage() {
        let formData = new FormData();
        const imageFile = this.businessImageFile.value;
        formData.append('file', imageFile, imageFile.name);
        // console.log(fileName, 'fileName check');
        this.apiService.uploadBusinessImage(this.businessId, formData).subscribe((res) => {
            this.businessImageBase64.setValue(null);
            this.getBusinessById(this.businessId);
            this.sharedService.showSpinner.next(false);
            this.showToast('Business Image Updated Successfully.');
        });
    }

    uploadProductImage(index) {

    }

    uploadMultiImagesOnServer() {
        const formData = new FormData();
        // Iterate over each portfolio image
        const portfolioImages = this.registrationForm.get('portfolioImages') as FormArray;
        for (let i = 0; i < portfolioImages.length; i++) {
            const imageFile = portfolioImages.at(i).get('imageFile').value;
            const fileName = `business-${this.businessId.toLowerCase().trim()}-portfolioImages-${i}.${imageFile.name.split('.')[1]}`;
            formData.append('files', imageFile, fileName);
        }
        this.apiService.uploadPortfolioImages(this.businessId, formData).subscribe((res) => {
            console.log(res);
        });

    }

    get businessImageUrlControl() {
        return this.registrationForm.get('business.businessImageUrl');
    }

    get businessImageFile() {
        return this.registrationForm.get('business.businessImage');
    }

    get businessImageBase64() {
        return this.registrationForm.get('business.businessImageBase64');
    }

    getProductImageUrlControl(index) {
        const productForm = this.products.controls[index] as FormGroup;
        const productImageUrlControl = productForm.get('productImageUrl');

        if (productImageUrlControl) {
            return productImageUrlControl.value;
        }

        return null; // or a default value if needed
    }


    get portfolioImages() {
        return (this.registrationForm.get('portfolioImages') as FormArray);
    }

    portfolioMultipleImagesAction(event) {
        const MAX_IMAGES = 5;
        if (this.portfolioImages.controls.length >= 20) {
            this.apiService.showToast('Image Upload Limit Exceeded');
            return;
        }

        const selectedFiles = event.target.files;

        if (selectedFiles.length > MAX_IMAGES) {
            this.apiService.showToast('You can upload 5 images at once');
            return;
        }

        if (!selectedFiles || selectedFiles.length === 0) {
            return;
        }

        let imagesProcessed = 0; // Counter to track the number of processed images
        let uploadImages: any[] = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const image = selectedFiles[i];
            const reader = new FileReader();

            const fileSizeInMB = image.size / (1024 * 1024);
            if (fileSizeInMB > 35) {
                this.apiService.showToast('File size should not exceed 35 MB');
                return;
            }

            reader.readAsDataURL(image);
            reader.onload = (onLoadEvent) => {
                const imageIndex = this.portfolioImages.controls.length + i;
                const fileName = `business-${this.businessId.toString().toLowerCase()}-portfolioImages-${imageIndex}.png`;
                const localUrl = onLoadEvent.target.result;
                this.sharedService.compressFile(localUrl, fileName).then((compressedImage: any) => {
                    const base64: string = compressedImage.base64;
                    const imageFile: any = compressedImage.imageFile;
                    const oldImages = this.portfolioImages.controls.map(v => v.get('imageUrl').value).filter( v => v != '');
                    this.portfolioImages.push(this.fb.group({
                        imageUrl: '',
                        imageUrlOnUI: '',
                        imageBase64: base64,
                        imageFile: imageFile,
                    }));
                    uploadImages.push(imageFile);
                    imagesProcessed++;

                    // Check if all images have been processed
                    if (imagesProcessed === selectedFiles.length) {
                        let formData = new FormData();
                        formData.append('oldImages', JSON.stringify(oldImages));
                        for (let j = 0; j < uploadImages.length; j++) {
                            formData.append('files', uploadImages[j]);
                        }
                        this.uploadPortFolioMultipleImages(formData);
                    }
                });
            }
        }
    }


    portfolioImageAction(event, index) {
        let reader = new FileReader();
        let image = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            const fileSizeInMB = image.size / (1024 * 1024);
            if (fileSizeInMB > 35) {
                this.apiService.showToast('File size should not exceed 35 MB');
                return;
            }
            if (this.portfolioImages.controls.length >= 20) {
                this.showToast('Image Upload Limit Exceeded');
                return;
            }
            this.sharedService.showSpinner.next(true);
            reader.readAsDataURL(image);
            reader.onload = (onLoadEvent) => {
                const fileName = `business-${this.businessId.toString().toLowerCase()}-portfolioImages-${index}.png`;
                const localUrl = onLoadEvent.target.result;
                this.sharedService.compressFile(localUrl, fileName).then((compressedImage: any) => {
                    const base64: string = compressedImage.base64;
                    const imageFile: any = compressedImage.imageFile;
                    if (index === this.portfolioImages.controls.length) {
                        this.portfolioImages.push(this.fb.group({
                            imageUrl: '',
                            imageUrlOnUI: '',
                            imageBase64: base64,
                            imageFile: imageFile,
                        })); // Add a new FormControl to the FormArray
                    } else {
                        this.portfolioImages.controls[index].get('imageBase64').setValue(base64);
                    }
                    this.portfolioImages.controls[index].get('imageFile').setValue(imageFile);
                    this.updatePortfolioImage(index);
                });
            }
        }
    }

    productImageAction(event, action, index) {
        let reader = new FileReader();
        let image = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            const fileSizeInMB = image.size / (1024 * 1024);
            if (fileSizeInMB > 35) {
                this.apiService.showToast('File size should not exceed 35 MB');
                return;
            }
            if (this.products.controls.length >= 20) {
                this.showToast('Image Upload Limit Exceeded');
                return;
            }
            this.sharedService.showSpinner.next(true);
            reader.readAsDataURL(image);
            reader.onload = (onLoadEvent) => {
                const fileName = `business-${this.businessId.toString().toLowerCase()}-product-${index}.png`;
                const localUrl = onLoadEvent.target.result;
                this.sharedService.compressFile(localUrl, fileName).then((compressedImage: any) => {
                    const base64: string = compressedImage.base64;
                    const imageFile: any = compressedImage.imageFile;
                    this.products.controls[index].get('productImageBase64').setValue(base64);
                    this.products.controls[index].get('productImage').setValue(imageFile);
                    this.products.controls[index].get('productImageUrl').setValue('');
                    this.products.controls[index].get('productImageUrlOnUI').setValue('');
                    this.updateProductImage(index);
                });
            }
        }
    }


    // Function to remove uploaded file
    removeUploadedFile(uploadType) {
        switch (uploadType) {
            case 'businessProfile': {
                this.businessImageUrlControl.setValue('');
                break;
            }
            case 'productSection': {
                // this.productImageUrlControl.setValue('');
                break;
            }
        }
    }

    onRemoveConfirm(i) {
        const title: any = 'Delete Confirmation';
        const confirmLabel: any = 'Delete';
        const message: any = 'delete the portfolio Image permanently?';
        this.openDialogPrompt(title, confirmLabel, message, this.removePortFolioImage.bind(this), this.businessId, i);
    }

    removePortFolioImage(modalData) {
        const data = {
            portfolioIndex: modalData.portfolioId
        };
        this.apiService.deletePortfolioImage(this.businessId, data).subscribe(
            res => this.deletePortfolioImageSuccess(res),
            error => {
                console.log(error);
                // this.apiService.commonError(error);
            }
        );
    }

    deletePortfolioImageSuccess(res) {
        this.getBusinessById(this.businessId);
        this.showToast('Portfolio Image Deleted Successfully.')
    }

    removeProductImage(index) {
        this.products.controls[index].get('productImageUrl').setValue('');
        this.products.controls[index].get('productImageUrlOnUI').setValue('');
        // this.productImageUrlControl.setValue('');
    }

    removeBusinessImage() {
        this.businessImageUrlControl.setValue('');
    }

    onClickLink() {
        if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            window.open(this.sharedService.generatePrototypeUrl(this.keywordUrl), '_blank');
        }
    }

    goToDashboard() {
        const role = this.apiService.userRole;
        if (role === 'admin') {
            this.router.navigate(['dashboard']);
        } else if (role === 'customer')
            this.router.navigate(['customer-dashboard']);
    }

    // Submit Registration Form
    onSubmit() {
        if (this.registrationForm.invalid) {
            const businessFormGroup = this.registrationForm.get('business');
            if (businessFormGroup.invalid) {
                if (businessFormGroup.get('businessName').invalid) {
                    this.showToast('Please enter a valid business name (minimum 2 characters).');
                } else if (businessFormGroup.get('businessType').invalid) {
                    this.showToast('Please select a business type.');
                } else if (businessFormGroup.get('ownerName').invalid) {
                    this.showToast('Please enter a valid owner name (minimum 2 characters).');
                } else if (businessFormGroup.get('email').invalid) {
                    this.showToast('Please enter a valid email address.');
                } else if (businessFormGroup.get('website').invalid) {
                    this.showToast('Please enter a valid website URL.');
                } else if (businessFormGroup.get('contactNumbers').invalid) {
                    this.showToast('Please provide at least one valid contact number.');
                } else {
                    this.showToast('Invalid business details! Please check the required fields.');
                }
                return;
            }

            if (this.registrationForm.get('business.businessName').invalid) {
                this.showToast('Please enter a valid business name (minimum 2 characters).');
            } else if (this.registrationForm.get('business.businessType').invalid) {
                this.showToast('Please select a business type.');
            } else if (this.registrationForm.get('business.ownerName').invalid) {
                this.showToast('Please enter a valid owner name (minimum 2 characters).');
            } else if (this.registrationForm.get('business.email').invalid) {
                this.showToast('Please enter a valid email address.');
            } else if (this.registrationForm.get('business.website').invalid) {
                this.showToast('Please enter a valid website URL.');
            } else if (this.registrationForm.get('address.addressMapLink').invalid) {
                this.showToast('Please enter a valid Google Maps link for the address.');
            } else if (this.registrationForm.get('products').invalid) {
                this.showToast('Please provide details for at least one product.');
            } else if (this.registrationForm.get('bankDetails.accountNumber').invalid) {
                this.showToast('Please enter a valid account number.');
            } else if (this.registrationForm.get('upiPayments').invalid) {
                this.showToast('Please provide details for at least one UPI payment option.');
            } else if (this.registrationForm.get('videoLinks').invalid) {
                this.showToast('Please provide details for at least one video link.');
            } else {
                this.showToast('Invalid form! Please check the required fields.');
            }
            return;
        }
        this.sharedService.showSpinner.next(true);
        // Your logic for form submission
        this.submitted = true;
        if (!this.registrationForm.valid) {
            alert('Please fill all the required fields to create a super hero!')
        } else {
            this.saveBusinessData();
        }
    }

    showToast(message: string): void {
        this.snackBar.open(message, 'Close', {
            duration: 3000,
            verticalPosition: 'top',
        });
    }

    disabledUPILogic(index) {
        return !!(!this.upiPayments.controls[index].get('upiType').value
            || !this.upiPayments.controls[index].get('upiUserName').value
            || !this.upiPayments.controls[index].get('upiContact').value
            || this.upiPayments.controls[index].get('upiContact').invalid)
    }

    disabledProductLogic(index) {
        return !!(!this.products.controls[index].get('productImageUrl').value
            || !this.products.controls[index].get('productName').value
        )
    }

    onLogout() {
        this.apiService.logout().subscribe(
            res => this.logoutSuccess(res),
            error => {
                this.apiService.commonError(error);
            }
        );
    }

    logoutSuccess(res) {
        this.router.navigate(['/login']);
        this.apiService.showToast('User Logged out Successfully.');
    }

}
