import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ApiService} from "../services/api.service";
import {Router} from "@angular/router";
import {PhoneValidator} from "../custom-validators/phone-number-validation";
import {SharedService} from "../services/shared.service";

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit{
  registrationForm: FormGroup;
  roles: any;
  constructor(public sharedService: SharedService, private formBuilder: FormBuilder, public apiService: ApiService, public router: Router) { }

  ngOnInit() {
    this.getRoles();
    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', [Validators.required, PhoneValidator()]],
      password: ['', [Validators.required, Validators.maxLength(10)]],
      confirmPassword: ['', Validators.required],
      role: ['', Validators.required]
    }, {
      validators: this.passwordMatchValidator
    });
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      const data: any = {
        name: this.registrationForm.get('name').value,
        email: this.registrationForm.get('email').value,
        password: this.registrationForm.get('password').value,
        role: this.registrationForm.get('role').value,
        contactNumber: this.registrationForm.get('contactNumber').value,
      };
      this.apiService.register(data).subscribe(
          res => this.registerSuccess(res),
          error => {
            this.apiService.commonError(error);
          }
      );
    } else {
      this.registrationForm.markAllAsTouched();
    }
  }

  registerSuccess(res) {
    this.apiService.showToast('User Registered Successfully.');
    this.router.navigate(['login']);
  }

  getRoles() {
    this.apiService.getRoles().subscribe(
        res => this.getRolesSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getRolesSuccess(res) {
    this.roles = res;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;

    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordMismatch: true };
    }
  }

  goToHome() {
    // Navigate to the home page
  }
}
