<div style="height: 100vh;">
    <router-outlet></router-outlet>
</div>
<app-spinner></app-spinner>
<app-brand-spinner></app-brand-spinner>
<pwa-install
        name="Creative Hand"
        manifest-url="assets/pwa-manifest/manifest.json"
        description="Make Your Business Digital. Create your digital footprints with Us."
        icon="https://creativehand.co.in/assets/company-logo/logo-color.png"
></pwa-install>
<!-- PWA Install Installation -->
<!--We have changed few things in node_modules -->
<!--Mandatory to install - npm i @types/web-app-manifest -->
<!--REMOVED - /// <reference types="dom-chromium-installation-events" />-->
<!--CHANGED protected platforms: any -->
<!--deferredEvent: any | null;-->