import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export function websiteValidator(): ValidatorFn {
    const websiteRegex: RegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

    return (control: FormControl): { [key: string]: any } | null => {
        const website: string = control.value;
        if (website && !websiteRegex.test(website)) {
            return { invalidWebsite: true };
        }
        return null;
    };
}
