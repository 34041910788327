<div class="container">
    <div class="add-title">
        <div>
            <h1 class="primary-header">{{ title }}</h1>
        </div>
        <div class="button-container">
            <button mat-dialog-close mat-raised-button type="button" class="Discard-btn">Cancel</button>
            <button *ngIf="!editMode" (click)="businessAction()" mat-raised-button type="submit" color="primary"
                    class="Update-btn">{{ btnTitle }}</button>
            <button *ngIf="editMode" (click)="businessAction()" mat-raised-button type="submit" color="primary"
                    class="Update-btn">{{ btnTitle }}</button>
        </div>
    </div>
    <hr>
    <div>
        <form [formGroup]="addCustomerForm">
            <mat-grid-list [cols]="getGridColumns()" rowHeight="105px" rowWidth="200px">
                <mat-grid-tile *ngIf="!editMode"  class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Business Name</mat-label>
                            <input #businessInput matInput maxlength="35" placeholder="Business Name" formControlName="businessName" required>
                            <mat-hint align="end">{{businessInput.value.length}} / 35</mat-hint>
                            <mat-error>
                                Please enter Business Name
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Customer Name</mat-label>
                            <mat-select formControlName="customerId" (selectionChange)="onSelectingCustomer($event)"
                                        placeholder="Select Offer Name">
                                <mat-option *ngFor="let customer of customers"
                                            [value]="customer.id">{{ customer.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Email Address</mat-label>
                            <input matInput type="email" placeholder="Email Address" formControlName="email" required>
                            <mat-error>
                                Please enter valid Email Address
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Contact Number</mat-label>
                            <input #phone type="text" maxlength="10" matInput placeholder="Contact Number"
                                   formControlName="contactNumber" required>
                            <mat-hint align="end">{{phone.value.length}} / 35</mat-hint>
                            <mat-error>
                                Please enter valid Contact Number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Executive Name</mat-label>
                            <mat-select placeholder="Select Offer Name"
                                        formControlName="executiveId">
                                <mat-option *ngFor="let executive of executives"
                                            [value]="executive.id">{{ executive.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>URL Keyword</mat-label>
                            <input #keyword type="text" maxlength="30" matInput placeholder="URL Keyword"
                                   formControlName="keywordUrl" required>
                            <mat-hint align="end">{{keyword.value.length}} / 30</mat-hint>
                            <mat-error>
                                Please enter valid URL Keyword
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="editMode">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Subscription Extension</mat-label>
                            <mat-select placeholder="Select Offer Name" formControlName="extension">
                                <mat-option *ngFor="let ext of extensions"
                                            [value]="ext.days">{{ ext.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="text-inside">
                        <mat-form-field class="pb-3" appearance="outline">
                            <mat-label>Subscription Start Date</mat-label>
                            <input formControlName="subscriptionStartDate" matInput [matDatepicker]="subscriptionStartDatePicker">
                            <mat-datepicker-toggle matIconSuffix [for]="subscriptionStartDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #subscriptionStartDatePicker></mat-datepicker>
                            <mat-error
                                    *ngIf="subscriptionStartDate.invalid && (subscriptionStartDate.dirty || subscriptionStartDate.touched)">
                                <ng-container *ngIf="subscriptionStartDate.errors?.['required']">Date is required.
                                </ng-container>

                                <ng-container *ngIf="subscriptionStartDate.errors?.['futureDate']">Future dates are not
                                    allowed.
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="text-inside">
                        <mat-form-field class="pb-3" appearance="outline">
                            <mat-label>Subscription End Date</mat-label>
                            <input formControlName="subscriptionEndDate" matInput [matDatepicker]="subscriptionEndDatePicker">
                            <mat-datepicker-toggle matIconSuffix [for]="subscriptionEndDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #subscriptionEndDatePicker></mat-datepicker>
                            <mat-error
                                    *ngIf="subscriptionEndDate.invalid && (subscriptionEndDate.dirty || subscriptionEndDate.touched)">
                                <ng-container *ngIf="subscriptionEndDate.errors?.['required']">Date is required.
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="!editMode" class="pb-3">
                    <div class="text-inside">
                        <mat-form-field appearance="outline">
                            <mat-label>Offer Name</mat-label>
                            <mat-select placeholder="Select Offer Name" formControlName="offerId">
                                <mat-option value="">Direct Payment</mat-option>
                                <mat-option *ngFor="let offerName of offers"
                                            [value]="offerName.id">{{ offerName.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </div>
</div>
