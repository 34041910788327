import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {PhoneValidator} from "../custom-validators/phone-number-validation";
import {SharedService} from "../services/shared.service";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-add-executive',
  templateUrl: './add-executive.component.html',
  styleUrls: ['./add-executive.component.scss']
})
export class AddExecutiveComponent implements OnInit{
  public registrationForm: FormGroup;
  breakpoint: any;
  offers: any = [
    {
      name: '1 month Free Offer',
      days: 30,
    }
  ];

  roles: any = [];
  payments: any = [
    {
      name: 'Phone Pay',
      key: 'phone_pay',
    },
    {
      name: 'Google Pay',
      key: 'google_pay',
    },
    {
      name: 'Bharat Pay',
      key: 'bharat_pay',
    }
  ];
  executives: any = [
    {
      name: 'Sumegha Upare',
      id: 1
    },
    {
      name: 'Ajay Madke',
      id: 2
    }
  ]
  constructor(
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<AddExecutiveComponent>,
      public dialog: MatDialog,
      public apiService: ApiService,
      public sharedService: SharedService,
      @Inject(MAT_DIALOG_DATA) public userDetails,
      @Inject(PLATFORM_ID) private platformId: object,
  ) {

  }
  public ngOnInit(): void {
    this.getRoles();
    this.registrationForm = this.fb.group({
      name: [this.userDetails.name , [Validators.required]],
      email: [this.userDetails.email, [Validators.required, Validators.email]],
      contactNumber: [this.userDetails.contactNumber, [Validators.required, PhoneValidator()]],
      password: [''],
      role: [this.userDetails.roleId],
    });
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      this.breakpoint = window.innerWidth <= 600 ? 1 : 3;
    }
  }

  getRoles() {
    this.apiService.getRoles().subscribe(
        res => this.getRolesSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getRolesSuccess(res) {
    this.roles = res;
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 3;
  }

  onSubmit() {
    this.apiService.updateUser(this.userDetails.id, this.registrationForm.value).subscribe(
        res => this.createUserSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  createUserSuccess(res) {
    this.dialogRef.close();
  }

}
