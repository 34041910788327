import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ThemeService } from '../services/themes.service';

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent  implements OnInit {
  @ViewChild("colorPallete", { static: true }) colorPallete!: ElementRef;
  themes: any = ['cronus', 'uranus', 'selene'];
  colors: any = [];
  templateImages: any = [];
  // @ViewChild("multiLang", { static: true }) multiLang!: ElementRef;
  constructor(public sharedService: SharedService, private themeService: ThemeService) {

  }

  ngOnInit(): void {
    this.fetchThemes();
  }

  goToLogin() {

  }

  scrollToElement(key) {
    switch(key) {
      case 'colorPallete':
        this.colorPallete.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }

  fetchThemes() {
    this.themeService.fetchThemes().then(themes => {
      this.colors = themes.map((col) => {
        // debugger;
        const primary = col.colors['--primary-color'];
        const secondary = col.colors['--secondary-color'];
        col.colorBall = `linear-gradient(to right, ${primary}, ${primary} 50%, ${secondary} 50%, ${secondary} 100%)`;
        return col;
      });
      const temp = ['cronus', 'uranus', 'selene'];
      this.templateImages = themes.map((val) => {
        return temp.map((val1, index) => {
          const tag = val.name.toLowerCase();
          return `${tag.split(' ').join('_')}_${index + 1}.png`
        });
      });
      this.templateImages = this.templateImages.flat();
    });
  }
}
