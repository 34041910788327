import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApiService} from "../services/api.service";
import {SharedService} from "../services/shared.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AddCustomerComponent} from "../add-customer/add-customer.component";
import {AddPhonebookComponent} from "../add-phonebook/add-phonebook.component";
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit{
  dataSourceFeedback: any = [];
  businessList: any = [];
  customerFeedbacks: any = [];
  customerEnquiries: any = [];
  dataSourceEnquiry: any = [];
  businessId: any = '';
  selectedBusiness: any;
  userDetails: any = null;
  phoneBookDetails: any = null;
  displayedFeedbackColumns: any = ['name', 'contactNumber', 'rating', 'status', 'feedback', 'action'];
  displayedEnquiryColumns: any = ['name', 'contactNumber', 'email', 'message'];
  constructor(@Inject(PLATFORM_ID) private platformId: object, public meta: Meta, public dialog: MatDialog, public apiService: ApiService, public sharedService: SharedService, public router: Router) {

  }
  
  ngOnInit(): void {
    this.userDetails = this.apiService.userDetails;
    this.getBusinessByUserId();
    this.getPhoneBook();
  }

  openPhonebookDialog(id = ''): void {
    // const businessDetails = id ? this.dataSourceBusiness.find(v => v.id === id) : null;
    const dialogRef = this.dialog.open(AddPhonebookComponent,{
      width: '500px', disableClose: false,
      // data: businessDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPhoneBook();
    });
  }

  deletePhoneBookDetails() {
    this.apiService.deletePhoneBookByUserId(this.apiService.userId).subscribe(
        res => this.deletePhoneBookByUserIdSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  deletePhoneBookByUserIdSuccess(res) {
    this.apiService.showToast('PhoneBook Details Deleted Successfully.');
    this.getPhoneBook();
  }

  getPhoneBook() {
    this.apiService.getPhoneBook(this.apiService.userId).subscribe(
        res => this.getPhoneBookSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getPhoneBookSuccess(res) {
    this.phoneBookDetails = res;
  }

  onFeedbackAction(id, action) {
    const data = {
      status: action,
      businessId: this.selectedBusiness,
    };
    this.apiService.updateFeedbackByBusinessId(id, data).subscribe(
        res => this.updateFeedbackStatusSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  updateFeedbackStatusSuccess(res) {
    this.apiService.showToast('Feedback updated Successfully');
    this.getFeedbackByBusinessId();
  }

  getFeedbackByBusinessId() {
    this.apiService.getFeedbackByBusinessId(this.selectedBusiness).subscribe(
        res => this.getFeedbackByBusinessIdSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getFeedbackByBusinessIdSuccess(res) {
    this.dataSourceFeedback = res.data.feedbacks;
  }

  getEnquiryByBusinessId() {
    this.apiService.getEnquiryByBusinessId(this.selectedBusiness).subscribe(
        res => this.getEnquiryByBusinessIdSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  selectBusiness(id) {
    this.selectedBusiness = id;
    this.getFeedbackByBusinessId();
    this.getEnquiryByBusinessId();
  }

  onClickLink(keywordUrl) {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.open(this.sharedService.generatePrototypeUrl(keywordUrl), '_blank');
    }
  }

  onEditBusinessRow(id) {
    this.router.navigate(['create-website', id]);
  }

  getEnquiryByBusinessIdSuccess(res) {
    this.dataSourceEnquiry = res.enquiry;
  }

  getBusinessByUserId() {
    const data = {
      customerId: this.apiService.userId
    };
    this.apiService.getBusiness(this.sharedService.generateQueryString(data)).subscribe(
        res => this.getBusinessByUserIdSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getBusinessByUserIdSuccess(res) {
   this.businessList = res.results;
   if(res.results && res.results.length) {
     this.selectedBusiness = this.businessList[0]._id;
     this.getFeedbackByBusinessId();
     this.getEnquiryByBusinessId();
   }
  }

  onLogout() {
    this.apiService.logout().subscribe(
        res => this.logoutSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  logoutSuccess(res) {
    this.router.navigate(['/login']);
    this.apiService.showToast('User Logged out Successfully.');
  }

  onChangePassword() {
    this.router.navigate(['/change-password']);
  }

  confirmUserAction(businessId, status): void {
    const data = {
      status: status
    };
    this.updateBusiness(businessId, data);
  }

  updateBusiness(businessId, data) {
    this.apiService.updateBusinessDetails(businessId, data).subscribe(
        res => this.updateBusinessDetailsSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  updateBusinessDetailsSuccess(res) {
    this.apiService.showToast('User Updated Successfully');
    this.getBusinessByUserId();
  }
}
