<mat-toolbar class="toolbar">
    <div class="toolbar-content">
        <div class="toolbar-container col-lg-12 col-sm-12 row d-flex justify-content-start align-items-center">
            <div class="left-logo-container col-lg-2 col-sm-12 col-md-12 ">
                <div class="d-flex align-items-center">
                    <div style="width: 55px; margin-right: 10px; border-radius: 50%; object-fit: cover">
                        <img style="border-radius: 50%; object-fit: cover;" width="100%" [src]="sharedService?.companyLogo" alt="Company Logo">
                    </div>
                    <div style="line-height: 1.2">
                        <div class="business-header font-20" >Creative Hand</div>
                        <div class="business-sub-header">Making Your Business Digital</div>
                    </div>
                </div>
                <div class="showMobile login-icon-box">
                    <div [matMenuTriggerFor]="userMenu" #menuTrigger class="d-flex justify-content-center align-items-center">
                        <div class="user-icon">
                            <button style="margin-right: 10px" mat-mini-fab color="primary"
                                    aria-label="Example icon button with a plus one icon">
                                <mat-icon>account_circle</mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-menu #userMenu="matMenu">
                        <ng-template matMenuContent let-id="id">
                            <button mat-menu-item
                                    (click)="onLogout()">Log Out
                                <mat-icon color="primary">logout</mat-icon>
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
            <div class="hideMobile col-lg-8 d-flex">
                <div class="toolbar-select">
                    <mat-label class="toolbar-label">Template Name</mat-label>
                    <select [(ngModel)]="selectedTemplate" (change)="onChangeTemplate($event)" class="form-select" aria-label="Default select example">
                        <option *ngFor="let template of templates" [value]="template">
                            {{template}}
                        </option>
                    </select>
                </div>
                <div class="toolbar-select">
                    <mat-label class="toolbar-label">Theme Name</mat-label>
                    <select [(ngModel)]="selectedTheme" (change)="onChangeTheme($event)" class="form-select" aria-label="Default select example">
                        <option *ngFor="let theme of themes" [value]="theme.keyword">{{theme.name}}</option>
                    </select>
                </div>
                <div class="toolbar-button">
                    <button (click)="onSubmit()" mat-raised-button color="accent">Save</button>
                </div>
                <div class="toolbar-button">
                    <button (click)="onClickLink()" mat-raised-button color="accent">Link </button>
                </div>
                <div class="toolbar-button">
                    <button (click)="goToDashboard()" mat-raised-button color="primary">Dashboard</button>
                </div>
            </div>
            <div class="hideMobile cursor-pointer col-lg-2 col-md-6 col-sm-6 d-flex justify-content-end">
                <div [matMenuTriggerFor]="userMenu" #menuTrigger class="d-flex justify-content-center align-items-center">
                    <div class="user-icon">
                        <button style="margin-right: 10px" mat-mini-fab color="primary"
                                aria-label="Example icon button with a plus one icon">
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>
                    <div style="line-height: 1.4"><b>Hello, <br> {{apiService?.userName}}</b></div>
                </div>
                <mat-menu #userMenu="matMenu">
                    <ng-template matMenuContent let-id="id">
                        <button mat-menu-item
                                (click)="onLogout()">Log Out
                            <mat-icon color="primary">logout</mat-icon>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>
    </div>
</mat-toolbar>

<div class="dashboard-container">
    <div class="dashboard-user-form-container col-lg-12 row">
        <div class="showMobile col-md-12 col-sm-1 col-lg-12 d-flex flex-column">
            <div class="col-lg-12 mt-4 pb-2">
                <mat-label class="toolbar-label">Template Name</mat-label>
                <select [(ngModel)]="selectedTemplate" (change)="onChangeTemplate($event)" class="form-select" aria-label="Default select example">
                    <option *ngFor="let template of templates" [value]="template">
                        {{template}}
                    </option>
                </select>
            </div>
            <div class="col-lg-12 pb-2">
                <mat-label class="toolbar-label">Theme Name</mat-label>
                <select [(ngModel)]="selectedTheme" (change)="onChangeTheme($event)" class="form-select" aria-label="Default select example">
                    <option *ngFor="let theme of themes" [value]="theme.keyword">{{theme.name}}</option>
                </select>
            </div>
            <div class="col-lg-12 pt-2 pb-2">
                <button class="full-width" (click)="onSubmit()" mat-raised-button color="accent">Save</button>
            </div>
            <div class="col-lg-12 pt-2 pb-2">
                <button class="full-width" (click)="onClickLink()" mat-raised-button color="accent">Link </button>
            </div>
            <div class="col-lg-12 pt-2 pb-2">
                <button class="full-width" (click)="goToDashboard()" mat-raised-button color="primary">Dashboard</button>
            </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 dashboard-left">
            <form [formGroup]="registrationForm">
                <mat-card formGroupName="business" class="business-card mat-elevation-z8">
                    <mat-card-header class="justify-content-center pt-4">
                        <mat-card-title><h2>Business Section</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="col-lg-12 row">
                                <div class="col-lg-4 d-flex justify-content-center align-items-center">
                                    <div>
                                        <div class="avatar-upload">
                                            <div class="avatar-edit">
                                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                                                       #fileInput
                                                       (change)="uploadFile($event)"/>
                                            </div>
                                            <div class="avatar-preview">
                                                <label for="imageUpload"
                                                       [ngClass]="['custom-label', 'upload-image']">
                                                    <i class="fa fa-pen"></i>
                                                </label>
                                                <div *ngIf="businessImageBase64?.value" class="d-flex justify-content-center">
                                                    <img class="business-logo"
                                                         [src]="businessImageBase64?.value ?
                                                         businessImageBase64?.value : defaultBusinessImage"
                                                         alt="Business Image">
                                                </div>
                                                <div *ngIf="businessImageUrlControl?.value" class="d-flex justify-content-center">
                                                    <img class="business-logo"
                                                         [src]="businessImageUrlControl?.value ?
                                                         businessImageUrlControl?.value : defaultBusinessImage"
                                                         alt="Business Image">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-12 pb-3">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Business Name</mat-label>
                                                <input #businessInput formControlName="businessName" maxlength="35"
                                                       type="text" matInput placeholder="Enter your Business">
                                                <mat-hint align="end">{{businessInput.value.length}} / 35</mat-hint>
                                                <mat-error>Please enter Business Name</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-8 pb-3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Business Type</mat-label>
                                                <mat-select formControlName="businessType">
                                                    <mat-option *ngFor="let businessType of businessTypes"
                                                                [value]="businessType">{{businessType}}</mat-option>
                                                </mat-select>
                                                <mat-error>Please Select Business Type</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-4">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Established Year</mat-label>
                                                <mat-select formControlName="estYear">
                                                    <mat-option *ngFor="let year of yearsList"
                                                                [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 pb-3">
<!--                                            <mat-form-field appearance="outline" class="full-width">-->
<!--                                                <mat-label>Owner Name</mat-label>-->
<!--                                                <input formControlName="ownerName" #ownerName type="text" maxlength="45"-->
<!--                                                       matInput-->
<!--                                                       placeholder="Enter Owner Name">-->
<!--                                                <mat-hint align="end">{{ownerName.value.length}} / 45</mat-hint>-->
<!--                                                <mat-error>Please Select Owner Name</mat-error>-->
<!--                                            </mat-form-field>-->
                                            <mat-form-field appearance="outline">
                                                <mat-label>Owner Name</mat-label>
                                                <mat-select  (selectionChange)="onSelectingCustomer($event)" formControlName="ownerName">
                                                    <mat-option *ngFor="let cust of customers"
                                                                [value]="cust?.id">{{cust?.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 pb-3">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Qualification</mat-label>
                                                <input formControlName="qualification" #qualification maxlength="45"
                                                       type="text" matInput
                                                       placeholder="Enter Owner Qualification">
                                                <mat-hint align="end">{{qualification.value.length}} / 45</mat-hint>
                                                <mat-error>Please Select Qualification</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 row pb-3">

                                <div class="col-lg-6">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Website</mat-label>
                                        <input #websiteInput maxlength="30" formControlName="website" type="text"
                                               matInput placeholder="Enter Website">
                                        <mat-hint align="end">{{websiteInput.value.length}} / 30</mat-hint>
                                        <mat-error>Please Enter Valid Website</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>Email Address</mat-label>
                                        <input #emailInput maxlength="30" formControlName="email" type="email" matInput
                                               placeholder="Enter Email">
                                        <mat-hint align="end">{{emailInput.value.length}} / 30</mat-hint>
                                        <mat-error>Please Enter Valid Email</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <hr class="full-width">
                            <div class="col-lg-12 row">
                                <div *ngIf="contactNumbers.controls.length === 0" class="col-lg-6">
                                    <button (click)="addContactNumber()" mat-raised-button color="primary">Add Contact</button>
                                </div>
                                <div class="col-lg-6" formArrayName="contactNumbers"
                                     *ngFor="let number of contactNumbers.controls; let i = index">
                                    <ng-container [formGroupName]="i">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex flex-column pb-2 flex-basis-70">
                                                <mat-form-field appearance="outline" class="full-width">
                                                    <mat-label>Contact Number</mat-label>
                                                    <input maxlength="10" formControlName="number" type="text" matInput
                                                           placeholder="Enter your Contact Number">
                                                    <mat-icon
                                                            class="d-flex justify-content-between align-items-center"
                                                            color="primary" matSuffix>
                                                        call
                                                    </mat-icon>
                                                    <mat-error>Please Enter Valid Contact Number</mat-error>
                                                </mat-form-field>
                                                <mat-checkbox (change)="onChangePrimary(i)" class="pb-1 primary-checkbox" color="primary" formControlName="isPrimary">
                                                    This is primary Number
                                                </mat-checkbox>
                                            </div>
                                            <div class="d-flex justify-content-end flex-basis-30">
                                                <div *ngIf="(contactNumbers.length - 1) <= i"
                                                     class="d-flex justify-content-end">
                                                    <button [disabled]="contactNumbers?.controls[i].value === '' || contactNumbers?.controls[i].invalid"
                                                            class="margin-9" (click)="addContactNumber()"
                                                            mat-mini-fab color="primary"
                                                            aria-label="Example icon button with a menu icon">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                                <div *ngIf="(contactNumbers.length - 1) > 0"
                                                     class="d-flex justify-content-end">
                                                    <button class="margin-9" (click)="removeContactNumber(i)"
                                                            mat-mini-fab color="accent"
                                                            aria-label="Example icon button with a menu icon">
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <hr class="full-width">
                            <div class="col-lg-12 row">
                                <ng-container>
                                    <div *ngIf="otherLinks.controls.length === 0" class="col-lg-6">
                                        <button (click)="addOtherLink()" mat-raised-button color="primary">Add Other Link</button>
                                    </div>
                                    <div class="col-lg-6" formArrayName="otherLinks"
                                         *ngFor="let otherLink of otherLinks.controls; let i = index">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex flex-basis-70">
                                                <mat-form-field appearance="outline" class="full-width">
                                                    <mat-label>Other Links</mat-label>
                                                    <input maxlength="80" [formControlName]="i" type="text" matInput
                                                           placeholder="Enter Other Business Links">
                                                    <mat-icon
                                                            class="d-flex justify-content-between align-items-center"
                                                            color="primary" matSuffix>
                                                        <i class="font-16 fa fa-link"></i>
                                                    </mat-icon>
                                                    <mat-error>Please Enter Valid Link</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="d-flex justify-content-end flex-basis-30">
                                                <div *ngIf="(otherLinks.length - 1) <= i"
                                                     class="d-flex justify-content-end">
                                                    <button [disabled]="otherLinks?.controls[i].value === '' || otherLinks?.controls[i].invalid"
                                                            class="margin-9" (click)="addOtherLink()" mat-mini-fab
                                                            color="primary"
                                                            aria-label="Example icon button with a menu icon">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                                <div *ngIf="(otherLinks.length - 1) > 0"
                                                     class="d-flex justify-content-end">
                                                    <button class="margin-9" (click)="removeOtherLinks(i)"
                                                            mat-mini-fab color="accent"
                                                            aria-label="Example icon button with a menu icon">
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <hr class="full-width">
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title><h2>Address Section</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div formGroupName="address" class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                        <div class="col-lg-12 pb-3">
                                            <mat-form-field appearance="outline" class="example-full-width">
                                                <mat-label>Google Map Link</mat-label>
                                                <input formControlName="addressMapLink" type="text" matInput
                                                       placeholder="Enter google Map Link">
                                                <mat-error>Please Enter Valid Google Map Link</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 pb-3">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>Address</mat-label>
                                                <textarea #addressLine maxLength="100" formControlName="addressLine"
                                                          matInput placeholder="Address Line 1"></textarea>
                                                <mat-hint align="end">{{addressLine.value.length}} / 100</mat-hint>
                                                <mat-error>Please Enter Business Address</mat-error>
                                            </mat-form-field>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title><h2>Whats App Catalogue</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                    <div class="col-lg-12 pb-3">
                                        <mat-form-field appearance="outline" class="example-full-width">
                                            <mat-label>Whatsapp Catalogue</mat-label>
                                            <input formControlName="whatsappCatalogue" type="text" matInput
                                                   placeholder="Enter Your Whatsapp Catalogue Link">
                                            <mat-error>Please Enter Valid Link</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card formGroupName="socialMediaLinks" class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title><h2>Social Media Section</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Facebook Link</mat-label>
                                            <input maxlength="100" #facebookInput formControlName="facebook" type="text"
                                                   matInput placeholder="Enter your Business">
                                            <mat-icon color="primary" matSuffix>
                                                facebook
                                            </mat-icon>
                                            <mat-hint align="end">{{facebookInput.value.length}} / 100</mat-hint>
                                            <mat-error
                                                    *ngIf="registrationForm.get('socialMediaLinks.facebook').hasError('invalidWebsite')">
                                                Please enter a valid Facebook link.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Instagram Link</mat-label>
                                            <input #instagramInput maxlength="100" formControlName="instagram"
                                                   type="text" matInput placeholder="Enter your Business">
                                            <mat-icon color="primary" matSuffix>
                                                <i class="fab fa-instagram"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{instagramInput.value.length}} / 100</mat-hint>
                                            <mat-error
                                                    *ngIf="registrationForm.get('socialMediaLinks.instagram').hasError('invalidWebsite')">
                                                Please enter a valid Instagram link.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>LinkedIn Link</mat-label>
                                            <input #linkedInInput maxlength="100" formControlName="linkedin" type="text"
                                                   matInput placeholder="Enter your Business">
                                            <mat-icon color="primary" matSuffix>
                                                <i class="fab fa-linkedin"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{linkedInInput.value.length}} / 100</mat-hint>
                                            <mat-error
                                                    *ngIf="registrationForm.get('socialMediaLinks.linkedin').hasError('invalidWebsite')">
                                                Please enter a valid LinkedIn link.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title><h2>What makes us different (Specialities)</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                    <div class="col-lg-12">
                                        <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"></ngx-editor-menu>
                                        <ngx-editor class="ngx-editor" [editor]="editor"
                                                    formControlName="specialities"></ngx-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title>
                            <h2>Portfolio</h2>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                    <ng-container formArrayName="portfolioImages"
                                                  *ngFor="let portFolioImage of portfolioImages.controls; let i = index">
                                        <div style="margin-bottom: 50px; position: relative"
                                             class="col-lg-4 d-flex justify-content-center align-items-center">
                                            <div class="avatar-upload">
                                                <div class="avatar-edit">
                                                    <input type='file' [id]="'imageUploadPortfolio' + i"
                                                           accept=".png, .jpg, .jpeg" #fileInput
                                                           (change)="portfolioImageAction($event, i)"/>
                                                </div>
                                                <div class="avatar-preview">
                                                    <label [for]="'imageUploadPortfolio' + i"
                                                           [ngClass]="['custom-label', 'upload-image']">
                                                        <i class="fa fa-pen"></i>
                                                    </label>
                                                    <div class="d-flex justify-content-center">
                                                       <ng-container *ngIf="portFolioImage.get('imageBase64')?.value">
                                                           <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                [src]="portFolioImage.get('imageBase64')?.value ?
                                                         portFolioImage.get('imageBase64')?.value : noImageIcon"
                                                                alt="Business Image">
                                                       </ng-container>
                                                        <ng-container *ngIf="portFolioImage.get('imageUrlOnUI')?.value">
                                                            <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                 [src]="portFolioImage.get('imageUrlOnUI')?.value ?
                                                         portFolioImage.get('imageUrlOnUI')?.value : noImageIcon"
                                                                 alt="Business Image">
                                                        </ng-container>
                                                        <ng-container *ngIf="!portFolioImage.get('imageBase64')?.value && !portFolioImage.get('imageUrlOnUI')?.value">
                                                            <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                 [src]="noImageIcon"
                                                                 alt="Business Image">
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="avatar-remove">
                                                    <label (click)="onRemoveConfirm(i)"
                                                           [ngClass]="['custom-label', 'remove-image']">
                                                        <i class="fa fa-times"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div style="margin-bottom: 30px; position: relative"
                                         class="col-lg-4 d-flex justify-content-center align-items-center">
                                        <mat-card
                                                style="cursor: pointer; width: 170px; border-radius: 10px; height: 180px; background-color: #d3d3d32b;"
                                                class="address-card">
                                            <input style="display: none" type='file' id="addPortFolio"
                                                   accept=".png, .jpg, .jpeg" #portfolioImageUpload
                                                   (change)="portfolioMultipleImagesAction($event)" multiple/>
                                            <label for="addPortFolio" style="width: 100%; height: 100%; cursor: pointer"
                                                   class="d-flex justify-content-center align-items-center">
                                                <i style="font-size: 45px; color: #80808054;" class="fa fa-plus"></i>
                                            </label>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
<!--                            <ng-container *ngIf="portfolioImages.controls.length">-->
<!--                                <hr class=full-width>-->
<!--                                <div  style="width: 100%; display: flex; justify-content: center; margin-top: 25px;">-->
<!--                                    <button (click)="uploadMultiImagesOnServer()" style="width: 250px" mat-raised-button color="primary">Upload All Images</button>-->
<!--                                </div>-->
<!--                            </ng-container>-->
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title><h2>Bank Details</h2></mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div formGroupName="bankDetails" class="cards-container">
                            <div class="full-width">
                                <div class="col-lg-12 row">
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Bank Name</mat-label>
                                            <input maxLength="30" type="text" #bankNameInput formControlName="bankName" matInput placeholder="Enter your Business">
                                            <mat-icon  style="font-size: 20px;" color="primary" matSuffix>
                                                <i class="fa fa-university"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{bankNameInput.value.length}} / 30</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Account Name</mat-label>
                                            <input maxLength="30" type="text" #accountNameInput formControlName="accountName" matInput placeholder="Enter your Business">
                                            <mat-icon  style="font-size: 20px;" color="primary" matSuffix>
                                                <i class="fa fa-user"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{accountNameInput.value.length}} / 30</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Account Number</mat-label>
                                            <input maxLength="30" type="text" #accountNumberInput formControlName="accountNumber" matInput placeholder="Enter your Business">
                                            <mat-icon  style="font-size: 20px;" color="primary" matSuffix>
                                                <i class="fa fa-credit-card"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{accountNumberInput.value.length}} / 30</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>IFSC Code</mat-label>
                                            <input maxLength="30" type="text" #IFSCCodeInput formControlName="IFSCCode" matInput placeholder="Enter your Business">
                                            <mat-icon  style="font-size: 20px;" color="primary" matSuffix>
                                                <i class="fa fa-code"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{IFSCCodeInput.value.length}} / 30</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 pb-3">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Account Type</mat-label>
                                            <input maxLength="30" type="text" #accountTypeInput formControlName="accountType" matInput placeholder="Enter your Business">
                                            <mat-icon  style="font-size: 20px;" color="primary" matSuffix>
                                                <i class="fa fa-money-check"></i>
                                            </mat-icon>
                                            <mat-hint align="end">{{accountTypeInput.value.length}} / 30</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-headers">UPI Details</div>
                                <div *ngIf="upiPayments.controls.length === 0" style="margin-left: 30px">
                                    <button (click)="addUpiPayments()" mat-raised-button color="primary">Add</button>
                                </div>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div class="full-width">
                                <div formArrayName="upiPayments"
                                     *ngFor="let payment of upiPayments.controls; let i = index" class="col-lg-12 row">
                                    <ng-container [formGroupName]="i">
                                        <div class="col-lg-3 pb-3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>UPI Type</mat-label>
                                                <mat-select formControlName="upiType">
                                                    <mat-option value="">Select UPI Type</mat-option>
                                                    <mat-option *ngFor="let upi of upiTypes"
                                                                [value]="upi.upiName">{{ upi.upiName }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-7 pb3">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>UPI User Name</mat-label>
                                                <input #upiUserInput maxlength="35" formControlName="upiUserName" type="text" matInput
                                                       placeholder="Enter UPI User Name">
                                                <mat-hint align="end">{{upiUserInput.value.length}} / 35</mat-hint>
                                                <mat-error>Please Enter UPI User Name</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-2 justify-content-center d-flex">
                                            <button *ngIf="(upiPayments.length - 1) <= i" (click)="addUpiPayments()"
                                                    [disabled]="disabledUPILogic(i)"
                                                    class="margin-9" mat-mini-fab color="primary"
                                                    aria-label="Example icon button with a menu icon">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                            <button *ngIf="(upiPayments.length - 1) > 0" (click)="removeUpiPayment(i)"
                                                    class="margin-9" mat-mini-fab color="accent"
                                                    aria-label="Example icon button with a menu icon">
                                                <i class="fa fa-minus"></i>
                                            </button>
                                        </div>
                                        <div class="col-lg-10 pb-3">
                                            <mat-form-field appearance="outline" class="full-width">
                                                <mat-label>UPI Linked Number</mat-label>
                                                <input maxlength="10" formControlName="upiContact" type="text" matInput
                                                       placeholder="Enter your UPI Phone Number">
                                                <mat-icon
                                                        class="d-flex justify-content-between align-items-center"
                                                        color="primary" matSuffix>
                                                    call
                                                </mat-icon>
                                                <mat-error>Please Valid UPI Phone Number</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <hr class="full-width">
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-headers">Videos</div>
                                <div *ngIf="videoLinks.controls.length === 0" style="margin-left: 30px">
                                    <button (click)="addVideoLinks()" mat-raised-button color="primary">Add</button>
                                </div>
                            </div>
<!--                            <div class="d-flex justify-content-between align-items-center">-->
<!--                                <h2>Videos</h2>-->
<!--                                <div *ngIf="videoLinks.controls.length === 0" style="margin-left: 30px">-->
<!--                                    <button (click)="addVideoLinks()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">-->
<!--                                        <mat-icon>add</mat-icon>-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                            </div>-->
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div formArrayName="videoLinks"
                                 *ngFor="let videoControl of videoLinks.controls; let i = index"
                                 class="full-width row">
                                <ng-container [formGroupName]="i">
                                    <div *ngIf="videoControl.get('videoLink').value && videoControl.get('videoId').value"
                                         style="margin-bottom: 30px; position: relative"
                                         class="col-lg-12  d-flex justify-content-center align-items-center">
                                        <mat-card style="width: 100%; border-radius: 10px; padding: 20px;"
                                                  class="address-card d-flex justify-content-start align-items-center">
                                            <youtube-player [width]="330" [height]="180"
                                                            [videoId]="videoControl.get('videoId').value"></youtube-player>
                                        </mat-card>
                                    </div>
                                    <div class="col-lg-8">
                                        <mat-form-field appearance="outline" class="full-width">
                                            <mat-label>Youtube Links</mat-label>
                                            <input formControlName="videoLink" type="text" matInput
                                                   placeholder="Enter Youtube Link">
                                            <mat-icon class="d-flex justify-content-between align-items-center"
                                                      color="primary" matSuffix>
                                                <i class="font-16 fa fa-link"></i>
                                            </mat-icon>
                                            <mat-error>Please enter valid Youtube link</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2 d-flex justify-content-start">
                                        <div style="margin-top: 9px" class="d-flex justify-content-end">
                                            <button (click)="verifyVideo(videoControl.get('videoLink').value, i)"
                                                    mat-raised-button color="primary">Verify Video</button>
                                        </div>
                                    </div>
                                    <div *ngIf="videoControl.get('isVerified').value" class="col-lg-2 d-flex justify-content-start">
                                        <div *ngIf="(videoLinks.length - 1) <= i &&
                                        videoControl.get('isVerified').value"
                                             class="d-flex justify-content-end">
                                            <button class="margin-9" (click)="addVideoLinks()" mat-mini-fab
                                                    color="primary"
                                                    aria-label="Example icon button with a menu icon">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </div>
                                        <div *ngIf="(videoLinks.length - 1) > 0"
                                             class="d-flex justify-content-end">
                                            <button class="margin-9" (click)="removeVideoLinks(i)" mat-mini-fab
                                                    color="accent"
                                                    aria-label="Example icon button with a menu icon">
                                                <i class="fa fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>


                                </ng-container>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="pb-3">
                    <mat-card-header class="justify-content-center">
                        <mat-card-title>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-headers">Services/Products Section</div>
                                <div *ngIf="products.controls.length === 0" style="margin-left: 30px">
                                    <button (click)="addProductsForm()" mat-raised-button color="primary">Add</button>
                                </div>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="cards-container">
                            <div formArrayName="products"
                                 *ngFor="let prd of products.controls; let i = index" class="full-width">
                                <ng-container [formGroupName]="i">
                                    <div class="col-lg-12 row">
                                        <div style="position: relative"
                                             class="col-lg-4 d-flex justify-content-center align-items-start">
                                            <div>
                                                <div class="avatar-upload">
                                                    <div class="avatar-edit">
                                                        <input type='file' [id]="'imageUploadProduct' + i"
                                                               accept=".png, .jpg, .jpeg"
                                                               (change)="productImageAction($event, 'edit', i)"/>
                                                    </div>
                                                    <div class="avatar-preview">
                                                        <label [for]="'imageUploadProduct' + i"
                                                               [ngClass]="['custom-label', 'upload-image']">
                                                            <i class="fa fa-pen"></i>
                                                        </label>
                                                        <div class="d-flex justify-content-center">

                                                            <ng-container *ngIf="products.controls[i].get('productImageBase64')?.value">
                                                                <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                     [src]="products.controls[i].get('productImageBase64')?.value ?
                                                         products.controls[i].get('productImageBase64')?.value : noImageIcon"
                                                                     alt="Address Image">
                                                            </ng-container>
                                                            <ng-container *ngIf="products.controls[i].get('productImageUrlOnUI')?.value">
                                                                <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                     [src]="products.controls[i].get('productImageUrlOnUI')?.value ?
                                                         products.controls[i].get('productImageUrlOnUI')?.value : noImageIcon"
                                                                     alt="Address Image">
                                                            </ng-container>
                                                            <ng-container *ngIf="!products.controls[i].get('productImageBase64')?.value && !products.controls[i].get('productImageUrlOnUI')?.value">
                                                                <img style="padding: 5px; object-fit: contain; width: 100%"
                                                                     [src]="noImageIcon"
                                                                     alt="Address Image">
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="col-lg-12 pb-3">
                                                <mat-form-field appearance="outline" class="full-width">
                                                    <mat-label>Name</mat-label>
                                                    <input formControlName="productName" type="email" matInput
                                                           placeholder="Enter your Business">
                                                    <mat-error>Please Enter Name</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-12 pb-3">
                                                <mat-form-field appearance="outline" class="example-full-width">
                                                    <mat-label>Description</mat-label>
                                                    <textarea formControlName="productDescription" matInput></textarea>
                                                    <mat-error>Please Enter Description</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <button *ngIf="(products.length - 1) > 0" (click)="removeProductsForm(i)"
                                                        class="margin-9" mat-mini-fab color="accent"
                                                        aria-label="Example icon button with a menu icon">
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                                <button *ngIf="(products.length - 1) <= i" (click)="addProductsForm()"
                                                        [disabled]="disabledProductLogic(i)"
                                                        class="margin-9" mat-mini-fab color="primary"
                                                        aria-label="Example icon button with a menu icon">
                                                    <i class="fa fa-plus"></i>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <hr class="full-width">
                            </div>

                        </div>
                    </mat-card-actions>
                </mat-card>
            </form>

        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-start pt-4 mobileFrame">
            <div class="d-flex justify-content-center align-items-center" style="width: 440px">
                <div class="iphone-bezel">
                    <iframe [src]="livePreview"></iframe>
                    <div class="button"></div>
                </div>
            </div>

        </div>
    </div>


</div>
