import {NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {AppComponent, DialogAnimationsExampleDialog} from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DashboardComponent} from './dashboard/dashboard.component';
import { ThemeCronusComponent } from './themes/theme-cronus/theme-cronus.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgxGalleryModule} from "@kolkov/ngx-gallery";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule, MatNavList} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {YouTubePlayerModule} from "@angular/youtube-player";
import {MatDividerModule} from "@angular/material/divider";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatChipsModule} from "@angular/material/chips";
import {NgxEditorModule} from "ngx-editor";
import {NgxVcardModule} from "ngx-vcard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { CreateWebsiteComponent } from './create-website/create-website.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTableModule} from "@angular/material/table";
import { AddCustomerComponent } from './add-customer/add-customer.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatDialogModule} from "@angular/material/dialog";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMenuModule} from "@angular/material/menu";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { PrototypeComponent } from './prototype/prototype.component';
import { LoginComponent } from './login/login.component';
import { AddExecutiveComponent } from './add-executive/add-executive.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import {HttpTokenInterceptorsService} from "./authentication/http-token-interceptors.service";
import { AddPaymentComponent } from './add-payment/add-payment.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {NgxImageCompressService} from "ngx-image-compress";
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import {MatTabGroup, MatTabsModule} from "@angular/material/tabs";
import { AddPhonebookComponent } from './add-phonebook/add-phonebook.component';
import { FeedbackFormComponent } from './shared-components/feedback-form/feedback-form.component';
import { BankDetailsCardComponent } from './shared-components/bank-details-card/bank-details-card.component';
import { ThemeUranusComponent } from './themes/theme-uranus/theme-uranus.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SpinnerComponent } from './shared-components/spinner/spinner.component';
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ThemeSeleneComponent } from './themes/theme-selene/theme-selene.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { OurProductsComponent } from './our-products/our-products.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ShopComponent } from './shop/shop.component';
import { BrandSpinnerComponent } from './shared-components/brand-spinner/brand-spinner.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ThemeCronusComponent,
    CreateWebsiteComponent,
    AddCustomerComponent,
    DialogAnimationsExampleDialog,
    PrototypeComponent,
    LoginComponent,
    AddExecutiveComponent,
    RegistrationPageComponent,
    AddPaymentComponent,
    CustomerDashboardComponent,
    AddPhonebookComponent,
    FeedbackFormComponent,
    BankDetailsCardComponent,
    ThemeUranusComponent,
    LandingPageComponent,
    SpinnerComponent,
    ChangePasswordComponent,
    ThemeSeleneComponent,
    OurProductsComponent,
    ContactUsComponent,
    ShopComponent,
    BrandSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    NgxGalleryModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    YouTubePlayerModule,
    MatDividerModule,
      MatCardModule,
      MatSelectModule,
      MatRadioModule,
      FormsModule,
    MatChipsModule,
    NgxEditorModule,
    NgxVcardModule,
    HttpClientModule,
    MatCheckboxModule,
      MatTableModule,
    MatGridListModule,
      MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
      MatChipsModule,
    MatMenuModule,
      MatSnackBarModule,
      MatPaginatorModule,
    MatTabsModule, // SwiperModule
    ShareButtonsModule,
    ShareIconsModule,
    NgxSkeletonLoaderModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgxImageCompressService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptorsService, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
