import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../services/api.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-phonebook',
  templateUrl: './add-phonebook.component.html',
  styleUrls: ['./add-phonebook.component.scss']
})
export class AddPhonebookComponent implements OnInit{
  phoneBookForm: FormGroup;
  constructor(public fb: FormBuilder, public apiService: ApiService, public dialogRef: MatDialogRef<AddPhonebookComponent>) {

  }

  ngOnInit(): void {
    this.getPhoneBook();
    this.phoneBookForm = this.fb.group({
      name: ['', Validators.required],
      organisation: ['', Validators.required],
      address: ['', Validators.required],
      qualification: ['', Validators.required],
    });
  }

  getPhoneBook() {
    this.apiService.getPhoneBook(this.apiService.userId).subscribe(
        res => this.getPhoneBookSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getPhoneBookSuccess(res) {
   if(res) {
     this.phoneBookForm.get('name').setValue(res.name);
     this.phoneBookForm.get('organisation').setValue(res.organisation);
     this.phoneBookForm.get('qualification').setValue(res.qualification);
     this.phoneBookForm.get('address').setValue(res.address);
   }
  }


  savePhoneBook() {
    if(this.phoneBookForm.valid) {
      this.apiService.savePhoneBook(this.phoneBookForm.value, this.apiService.userId).subscribe(
          res => this.savePhoneBookSuccess(res),
          error => {
            this.apiService.commonError(error);
          }
      );
    } else {
      this.phoneBookForm.markAllAsTouched()
    }
  }

  savePhoneBookSuccess(res) {
    console.log(res);
    this.apiService.showToast('PhoneBook Updated Successfully.');
    this.dialogRef.close();
  }
}
