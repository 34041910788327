import {Component, Inject, OnInit} from '@angular/core';
import {StorageService} from "./services/storage.service";
import {appConstants} from "../assets/constants/app-constants";
import {ApiService} from "./services/api.service";
import {ActivatedRoute, NavigationEnd, Router, RouterState} from "@angular/router";
import { Title } from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";
import { Meta } from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import '@khmyznikov/pwa-install';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'CreativeHand';
  roles: any = [];
  constructor(private meta: Meta, @Inject(DOCUMENT) private document: Document, private titleService: Title, private route: ActivatedRoute, private storageService: StorageService, private apiService: ApiService, public router: Router) {
    this.handleRouteEvents();
  }

  ngOnInit(): void {
    this.storageService.getStoredValue(appConstants.ACCESS_TOKEN_KEY).then((token) => {
      this.apiService.onLoadToken.next(token);
    });
    this.getRoles();
  }


  getRoles() {
    this.apiService.getRoles().subscribe(
        res => this.getRolesSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
    );
  }

  getRolesSuccess(res) {
    this.roles = res;
    this.storageService.storeValue('roles', JSON.stringify(this.roles));
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        if(title && (title.toLowerCase().trim() === 'business')) {
          title = this.extractBusinessUrlFromUrl();
        }
        this.titleService.setTitle(title);
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href
        })
      }
    });
  }

  extractBusinessUrlFromUrl(): string {
    const urlSegments = this.router.routerState.snapshot.url.split('/');
    return urlSegments[urlSegments.length - 1];
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }
}


@Component({
  selector: 'dialog-animations-example-dialog',
  template: `
        <h1 mat-dialog-title>{{ title }}</h1>
        <div mat-dialog-content>
            {{ message }}
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close>Cancel</button>
            <button color="primary" mat-button (click)="onConfirmClick()">{{ confirmLabel }}</button>
        </div>
    `,
})
export class DialogAnimationsExampleDialog {
  title: string;
  message: string;
  confirmLabel: string;
  businessId: string;
  confirmFunction: (id: string) => void;
  modalData: any;
  constructor(
      public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data: {
        title: string,
        message: string,
        confirmLabel: string,
        businessId: string;
        confirmFunction: (id: string) => void
      }
  ) {
    this.modalData = data;
    this.title = data.title;
    this.message = data.message;
    this.confirmLabel = data.confirmLabel;
    this.confirmFunction = data.confirmFunction;
    this.businessId = data.businessId;
  }

  onConfirmClick(): void {
    this.confirmFunction(this.modalData);
    this.dialogRef.close();
  }
}

