import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { SharedService } from "../services/shared.service";
import { ViewportScroller } from "@angular/common";
import { FormBuilder, Validators } from "@angular/forms";
import { EmailValidator } from "../custom-validators/email-validation";
import { PhoneValidator } from "../custom-validators/phone-number-validation";
import { ApiService } from "../services/api.service";
import { ThemeService } from "../services/themes.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import {Platform} from "@angular/cdk/platform";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  @ViewChild("multiLang", { static: true }) multiLang!: ElementRef;
  @ViewChild("contactUs", { static: true }) contactUs!: ElementRef;
  @ViewChild("home", { static: true }) home!: ElementRef;
  @ViewChild('templateElement', { static: false }) templateElementRef: ElementRef;
  currentScrollLeft: number = 0;
  currentScrollTop: number = 0;
  // @ViewChild('templateElement', { static: true }) templateElementRef: ElementRef;
  selectedTab: any = 'tab1';
  scrollBar: any = 0;
  livePreview: any = '';
  selecedThemeName: any = 'Calm Postcard';
  enquiryForm: any;
  regionalForm: any;
  valueChanges: any = false;
  keywordUrl: any = 'creativehand-english';
  themes: any = ['cronus', 'uranus', 'selene'];
  colors: any = [];
  callingNumber: any = '8208690072';
  whatsappNumber: any = '9403733265';
  languages: any = [];
  templateImages: any = [];
  allFeatures: any = [];
  // currentScrollLeft: number = 0;
  // currentScrollTop: number = 0;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.scrollBar = number;
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId: object,
    public apiService: ApiService,
    public platform: Platform,
    private sanitizer: DomSanitizer,
    public sharedService: SharedService,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private themeService: ThemeService,
    public fb: FormBuilder
  ) { }


  onScroll(event: Event) {
    // debugger;
    const templateElement = event.target as HTMLElement;
    this.currentScrollLeft = templateElement.scrollLeft;
    this.currentScrollTop = templateElement.scrollTop;
    console.log(this.currentScrollLeft);
  }

  scrollElement(index, themeName) {
    this.selecedThemeName = themeName;
    const templateElement = this.templateElementRef.nativeElement as HTMLElement;
    const cardElements = templateElement.querySelectorAll('.card');
    const calcIndex = index === 0 ? 0 : index * 3;
    const targetElement = cardElements[calcIndex] as HTMLElement;

    const containerScrollLeft = templateElement.scrollLeft;
    const elementOffsetLeft = targetElement.offsetLeft;
    const elementWidth = targetElement.offsetWidth;

    const containerWidth = templateElement.offsetWidth;

    // const scrollAmount = elementOffsetLeft - containerScrollLeft;
    // Calculate the target scroll position to center the element in the container
    const scrollAmount = elementOffsetLeft - 0 - (containerWidth - (elementWidth * 2)) / 2;

    templateElement.scroll({
      left: scrollAmount,
      behavior: 'smooth',
    });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  ngAfterViewInit(): void {
    const templateElement = this.templateElementRef.nativeElement as HTMLElement;
    templateElement.addEventListener('scroll', this.onScroll.bind(this));
  }


  ngOnInit(): void {
    console.log(this.platform);
    this.allFeatures = [
      'Instant calling option for your customers to connect quickly.',
      'Showcase your business with amazing customer feedback.',
      'Embedded Google Map of your business for easy location access.',
      'Effortlessly customer can save contact details of your business',
      'Unlimited sharing of your website to all social media platform.',
      'Link Your Business Whatsapp catalog with your website',
      'Update your mini-website anytime, without limitations.',
      'Professional business logo representation.',
      'Display up to 20 product or service images.',
      'Highlight 20 stunning works in your portfolio.',
      'Visual product catalog with images.',
      'Upload an unlimited number of YouTube videos.',
      'Support for 8 Indian regional languages.',
      'Enable customer inquiries with ease.',
      'Effortless lead generation for your products/services.',
    ];
    
    this.livePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.sharedService.generatePrototypeUrl(this.keywordUrl));

    this.enquiryForm = this.fb.group({
      name: [''],
      email: ['', [Validators.email]],
      contactNumber: ['', [PhoneValidator()]]
    });

    this.regionalForm = this.fb.group({
      themeName: ['cronus', Validators.required],
      color: ['CalmPostCard', Validators.required],
      language: ['English', Validators.required],
    });
    this.fetchThemes();
    this.fetchLanguages();

    this.regionalForm.valueChanges.subscribe((res) => {
      console.log(res);
      this.valueChanges = true;
    });
  }

  fetchThemes() {
    this.themeService.fetchThemes().then(themes => {
      this.colors = themes.map((col) => {
        // debugger;
        const primary = col.colors['--primary-color'];
        const secondary = col.colors['--secondary-color'];
        col.colorBall = `linear-gradient(to right, ${primary}, ${primary} 50%, ${secondary} 50%, ${secondary} 100%)`;
        return col;
      });
      const temp = ['cronus', 'uranus', 'selene'];
      this.templateImages = themes.map((val) => {
        return temp.map((val1, index) => {
          const tag = val.name.toLowerCase();
          return `${tag.split(' ').join('_')}_${index + 1}.png`
        });
      });
      this.templateImages = this.templateImages.flat();
    });
  }

  fetchLanguages() {
    this.themeService.fetchLanguages().then(themes => {
      this.languages = themes;
    });
  }

  sendEnquiry() {
    if (this.enquiryForm.invalid || !this.enquiryForm.get('contactNumber').value) {
      this.apiService.showToast('Please Enter Valid Number');
      return;
    } else {
      this.apiService.contactUs(this.enquiryForm.value).subscribe(
        res => this.contactUsSuccess(res),
        error => {
          this.apiService.commonError(error);
        }
      );
    }
  }

  contactUsSuccess(res) {
    this.enquiryForm.reset();
    this.apiService.showToast('Thanks for Enquiry! We will get back to you soon!');
  }

  scrollToElement(key) {
    switch (key) {
      case 'multi-lingual':
        this.multiLang.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;

      case 'contactUs':
        this.contactUs.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;

      case 'templateElement':
        this.templateElementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }

  saveRegionalChanges() {
    if (this.regionalForm.invalid) {
      this.apiService.showToast('Please Select Theme, Color and Language');
      return;
    }
    // const businessId =
    const data = {
      template: this.regionalForm.get('themeName').value.toLowerCase(),
      theme: this.regionalForm.get('color').value,
      language: this.regionalForm.get('language').value.toLowerCase()
    };
    this.apiService.renderDemoBusiness(data).subscribe(
      res => this.renderDemoBusinessSuccess(res),
      error => {
        this.apiService.commonError(error);
      }
    );
  }

  renderDemoBusinessSuccess(res) {
    this.valueChanges = false;
    this.keywordUrl = res.keywordUrl;
    this.livePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.sharedService.generatePrototypeUrl(`${res.keywordUrl}?${new Date().getTime()}`));
  }

  applyAnimOnScroll(numberVal) {
    return (this.scrollBar >= numberVal);
  }

  onSelectTab(tabName) {
    this.selectedTab = tabName;
  }

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }


  checkChanges() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.open(this.sharedService.generatePrototypeUrl(this.keywordUrl), '_blank');
    }
  }

  onCallAction() {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      window.location.href = 'tel:' + this.callingNumber;
    }
  }

  initiateWhatsapp() {
    const message = 'Hello, I am interested in your business.'; // The pre-filled message
    this.sharedService.sendMessage(message, this.whatsappNumber);
  }
}
