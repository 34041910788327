<mat-drawer-container style="height: 100vh; background: #3f51b538 !important;" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="push" position="start">
        <div class="menu-list-header">
            Menu List
        </div>
        <mat-nav-list>
            <mat-list class="p-3">
                <mat-list-item *ngIf="apiService?.userRole === 'executive' || apiService?.userRole === 'admin'">
                    <div (click)="onSelectedMenu('dashboard'); drawer.toggle()" class="d-flex align-items-center cursor-pointer menu-item">
                        <mat-icon class="m-2">dashboard</mat-icon> Dashboard
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item *ngIf="apiService?.userRole === 'admin'" >
                    <div (click)="onSelectedMenu('user'); drawer.toggle()" class="d-flex align-items-center cursor-pointer menu-item">
                        <mat-icon class="m-2">people</mat-icon> Users List
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item *ngIf="apiService?.userRole === 'admin'" >
                    <div (click)="onSelectedMenu('enquiry'); drawer.toggle()" class="d-flex align-items-center cursor-pointer menu-item">
                        <mat-icon class="m-2">question_answer</mat-icon> Website Enquiries
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-nav-list>
    </mat-drawer>
    <mat-toolbar style="font-size: 14px; font-weight: 400; height: 75px; box-shadow: 0 3px 11px 0 #6c757d3d;">
        <div class="col-lg-12 col-12 row d-flex justify-content-between align-items-center">
            <div class="cursor-pointer col-lg-6 col-6 d-flex justify-content-start align-items-center">
                <div style="margin-right: 20px">
                    <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
                            aria-label="Example icon-button with menu icon">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
                <div style="width: 55px; margin-right: 10px; border-radius: 50%; object-fit: cover">
                    <img style="min-width: 50px; border-radius: 50%; object-fit: cover;" width="100%" [src]="sharedService?.companyLogo" alt="Company Logo">
                </div>
                <div style="line-height: 1.2">
                    <div class="business-header font-20">Creative Hand</div>
                    <div class="business-sub-header">Making Your Business Digital</div>
                </div>
            </div>
            <div class="cursor-pointer col-lg-6 col-6 d-flex justify-content-end">
                <div [matMenuTriggerFor]="userMenu" #menuTrigger class="d-flex justify-content-center align-items-center">
                    <div class="user-icon">
                        <button style="margin-right: 10px" mat-mini-fab color="primary"
                                aria-label="Example icon button with a plus one icon">
                            <mat-icon>account_circle</mat-icon>
                        </button>
                    </div>
                    <div class="userName"><b>Hello, <br> {{apiService?.userName}}</b></div>
                </div>
                <mat-menu #userMenu="matMenu">
                    <ng-template matMenuContent let-id="id">
                        <button mat-menu-item
                                (click)="onChangePassword()">Change Password
                            <mat-icon color="primary">lock</mat-icon>
                        </button>
                        <button mat-menu-item
                                (click)="onLogout()">Log Out
                            <mat-icon color="primary">logout</mat-icon>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>

    </mat-toolbar>

    <ng-container *ngIf="selectedMenu === 'dashboard'">
        <div class="business dashboard-container">
            <div class="pb-3 table-toolbar d-flex justify-content-between">
                <div class="col-lg-12 col-md-12 col-sm-12 p-sm-0 m-sm-0 row">
                    <div class="row p-sm-0 m-sm-0 col-lg-8 col-lg-9 col-sm-8">
                        <div class="p-sm-0 m-sm-0 col-lg-2 col-md-12 col-sm-12 dash-business-header">
                            <div class="title">Business</div>
                        </div>
                        <div class="p-sm-0 m-sm-0 col-lg-10 col-md-12 col-sm-12" *ngIf="apiService?.userRole === 'admin'">
                            <div class="row dash-form-container d-flex align-items-start justify-content-start">
                                <div class="p-sm-0 m-sm-0 col-lg-12 col-md-12 col-sm-12 row">
                                    <form class="p-sm-0 m-sm-0 col-lg-9 col-md-12 col-sm-12 d-flex row" [formGroup]="businessSearchForm">
                                        <mat-form-field class="col-lg-6 col-sm-12 col-md-6" appearance="outline">
                                            <mat-label>Keyword</mat-label>
                                            <mat-select formControlName="keyword">
                                                <mat-option *ngFor="let filter of filtersList" [value]="filter?.value">{{filter?.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="full-width col-lg-6 col-sm-12 col-md-6" appearance="outline">
                                            <mat-label>Search String</mat-label>
                                            <input formControlName="searchString"
                                                   type="text" matInput placeholder="Enter your Search String">
                                        </mat-form-field>
                                    </form>
                                    <div class="search-btn col-lg-3 col-md-12 col-sm-12">
                                        <button class="width-150" [disabled]="businessSearchForm.invalid" (keyup.enter)="searchBusiness()" (click)="searchBusiness()" mat-raised-button color="primary">
                                            <mat-icon>search</mat-icon>
                                            <div>Search</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 add-btn">
                        <button (click)="openBusinessDialog('', 'add')" style="width: 150px" mat-raised-button color="primary">Add
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="dataSourceBusiness && dataSourceBusiness.data.length > 0" class="full-width">
                <div style="border-bottom: 1px solid lightgray" *ngIf="dataSourceBusiness && dataSourceBusiness.data && dataSourceBusiness.data.length > 0">
                    <mat-paginator
                            [pageSize]="businessPageSize"
                            #paginator
                            (page)="onBusinessPageChange($event)"
                            [length]="businessDataTotalLength">
                    </mat-paginator>
                </div>
                <div class="business-container table-container">
                    <table mat-table [dataSource]="dataSourceBusiness.data" class="mat-elevation-z8">
                        <!-- Position Column -->
                        <ng-container matColumnDef="businessName">
                            <th mat-header-cell *matHeaderCellDef> Business Name </th>
                            <td mat-cell class="table-column-250" *matCellDef="let element"> {{element.businessName}} </td>
                        </ng-container>

                        <!-- Position Column -->
                        <ng-container matColumnDef="ownerName">
                            <th mat-header-cell *matHeaderCellDef> Owner Name </th>
                            <td mat-cell class="table-column-250" *matCellDef="let element"> {{element.ownerName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell class="table-column-200" *matCellDef="let element">
                                <div class="w-75 btn color-primary btn-warning" *ngIf="element.status === 'pending'"
                                     style="color: #FFBF00">
                                    {{element.status | titlecase}}
                                </div>
                                <div class="color-white w-75 btn btn-success" *ngIf="element.status === 'active'"
                                     style="color: green">{{element.status | titlecase}}</div>
                                <div class="color-white w-75 btn btn-danger" *ngIf="element.status === 'de-active'"
                                     style="color: red">{{element.status | titlecase}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email Address </th>
                            <td mat-cell class="table-column-300" *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="contactNumber">
                            <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                            <td mat-cell class="table-column-200" *matCellDef="let element"> {{element.contactNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="executiveName">
                            <th mat-header-cell *matHeaderCellDef> Executive Name </th>
                            <td mat-cell class="table-column-200" *matCellDef="let element"> {{element.executiveName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="siteLink">
                            <th mat-header-cell *matHeaderCellDef> Website Link </th>
                            <td mat-cell class="table-column-300" *matCellDef="let element">
                                <a [href]="element.siteLink" target="_blank">{{element.siteLink}}</a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="subscriptionStartDate">
                            <th mat-header-cell *matHeaderCellDef> Start Date </th>
                            <td mat-cell class="table-column-150" *matCellDef="let element"> {{element.subscriptionStartDate | date: 'd MMM y' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="subscriptionEndDate">
                            <th mat-header-cell *matHeaderCellDef> End Date </th>
                            <td mat-cell class="table-column-150" *matCellDef="let element"> {{element.subscriptionEndDate | date: 'd MMM y' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="offerName">
                            <th mat-header-cell *matHeaderCellDef> Offer Name </th>
                            <td mat-cell class="table-column-250" *matCellDef="let element"> {{element.offerName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="payment">
                            <th mat-header-cell *matHeaderCellDef> Payment </th>
                            <td mat-cell class="table-column-300" *matCellDef="let element">
                                <div *ngIf="element.payment === 'unpaid'" class="w-75 btn btn-outline-danger">
                                    {{element.paymentStatus | titlecase}}
                                </div>
                                <div *ngIf="element.payment === 'paid'" class="w-75 btn btn-outline-success">
                                    {{element.paymentStatus | titlecase}}
                                </div>
                                <div *ngIf="element.payment === 'approval_pending'" class="w-75 btn color-warning btn-outline-warning">
                                    {{element.paymentStatus | titlecase}}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                            <td [matMenuTriggerData]="element"
                                [matMenuTriggerFor]="businessMenu"
                                mat-cell *matCellDef="let element">
                                <div style="cursor: pointer; text-align: center">
                                    <mat-icon>more_vert</mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedBusinessColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedBusinessColumns;"></tr>
                    </table>
                </div>
            </div>

            <mat-menu #businessMenu="matMenu">
                <ng-template matMenuContent let-payment="payment" let-status="status" let-id="id">
                    <!-- Condition: isAdmin() -->

                    <ng-container *ngIf="sharedService.isAdmin">
                        <!-- Button: Deactivate -->
                        <ng-container *ngIf="payment === 'unpaid' && status === 'pending' || payment === 'paid' && status === 'active' || payment === 'unpaid' && status === 'active'">
                            <button mat-menu-item (click)="confirmDeactivateUser(id)">Deactivate
                                <mat-icon color="primary">delete</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Approve -->
                        <ng-container *ngIf="payment === 'unpaid' && status === 'pending' || payment === 'unpaid' && status === 'de-active' || payment === 'paid' && status === 'de-active'">
                            <button mat-menu-item (click)="confirmApproveRequest(id)">Approve
                                <mat-icon color="primary">check</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Approve -->
                        <ng-container *ngIf="payment === 'approval_pending'">
                            <button mat-menu-item (click)="confirmApprovePayment(id)">Approve Payment
                                <mat-icon color="primary">check_circle</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Admin Edit -->
                        <ng-container *ngIf="payment === 'unpaid' && status === 'pending' || payment === 'paid' && status === 'active' || payment === 'unpaid' && status === 'de-active' || payment === 'unpaid' && status === 'active'">
                            <button mat-menu-item (click)="openBusinessDialog(id, 'edit')">Admin Edit
                                <mat-icon color="primary">settings</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Edit -->
                        <ng-container *ngIf="payment === 'paid' && status === 'active' || payment === 'unpaid' && status === 'active'">
                            <button mat-menu-item (click)="onEditBusinessRow(id)">Edit
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Edit -->
                        <ng-container *ngIf="payment === 'paid' && status === 'active'">
                            <button mat-menu-item (click)="openBusinessDialog(id, 'extend')">Add Extension
                                <mat-icon color="primary">extension</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Add Payment -->
                        <ng-container *ngIf="payment === 'unpaid'">
                            <button mat-menu-item (click)="openPaymentDialog(id)">Add Payment
                                <mat-icon color="primary">payment</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>

                    <!-- Condition: isExecutive() -->
                    <ng-container *ngIf="sharedService.isExecutive">
                        <!-- Button: Add Payment -->
                        <ng-container *ngIf="payment === 'unpaid'">
                            <button mat-menu-item (click)="openPaymentDialog(id)">Add Payment
                                <mat-icon color="primary">payment</mat-icon>
                            </button>
                        </ng-container>

                        <!-- Button: Edit -->
                        <ng-container *ngIf="payment === 'paid' && status === 'active' || payment === 'unpaid' && status === 'active'">
                            <button mat-menu-item (click)="onEditBusinessRow(id)">Edit
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </mat-menu>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedMenu === 'user'">
        <div class="executive dashboard-container">
            <div class="pb-3 table-toolbar d-flex justify-content-between">
                <div>
                    <h1>Users</h1>
                </div>
                <div>
                    <button [routerLink]="'/register'" style="width: 150px" mat-raised-button color="primary">Add
                    </button>
                </div>
            </div>
            <div class="table-container business-container">
                <div *ngIf="dataSourceUsers && dataSourceUsers.data && dataSourceUsers.data.length > 0">
                    <mat-paginator
                            [pageSize]="businessPageSize"
                            #userPaginator
                            (page)="onUserPageChange($event)"
                            [length]="userDataTotalLength">
                    </mat-paginator>
                </div>
                <table mat-table [dataSource]="dataSourceUsers.data" class="mat-elevation-z8">

                    <!-- Position Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> User Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email Address </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="contactNumber">
                        <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="userRole">
                        <th mat-header-cell *matHeaderCellDef> User Roll </th>
                        <td mat-cell *matCellDef="let element"> {{element.userRole | titlecase}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.status | titlecase}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td [matMenuTriggerData]="element" [matMenuTriggerFor]="userGridMenu" #menuTrigger mat-cell *matCellDef="let element">
                            <div style="cursor: pointer; text-align: center"><mat-icon>more_vert</mat-icon></div>
                        </td>
                    </ng-container>
                    <mat-menu #userGridMenu="matMenu">
                        <ng-template matMenuContent let-id="id">
                            <button mat-menu-item (click)="confirmDeactivateUser(id)">Deactivate
                                <mat-icon color="primary">delete</mat-icon>
                            </button>
                            <button mat-menu-item (click)="onEditRow(id)">Edit
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </ng-template>
                    </mat-menu>

                    <tr mat-header-row *matHeaderRowDef="displayedUsersColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedUsersColumns;"></tr>
                </table>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedMenu === 'enquiry'">
        <div class="executive dashboard-container">
            <div class="pb-3 table-toolbar d-flex justify-content-between">
                <div>
                    <h1>Website Enquiries</h1>
                </div>
            </div>
            <div class="table-container business-container">
                <table mat-table [dataSource]="dataSourceWebsiteEnquiries" class="mat-elevation-z8">

                    <ng-container matColumnDef="contactNumber">
                        <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> User Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Position Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email Address </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedEnquiriesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedEnquiriesColumns;"></tr>
                </table>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dataSourceBusiness.data.length === 0 || dataSourceUsers.data.length === 0 || dataSourceWebsiteEnquiries.length === 0">
        <div class="d-flex flex-column justify-content-center align-items-center h-70">
            <div>
                <img width="100%" src="assets/public/no_data-rb.png" alt="No Data Found">
            </div>
            <div style="font-size: 25px;" class="p-4">
                No Data Available
            </div>
        </div>
    </ng-container>
</mat-drawer-container>


