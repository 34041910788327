<div style="padding: 30px;">
    <div class="add-title d-flex justify-content-between">
        <div>
            <h1 class="primary">Add Payment</h1>
        </div>
        <div>
            <button mat-dialog-close mat-raised-button type="button" class="Discard-btn">Cancel</button>
            <button (click)="savePayment()" mat-raised-button type="submit" color="primary" class="Update-btn">
                Add
            </button>
        </div>
    </div>
    <hr>
    <div>
        <ng-container>
            <form [formGroup]="paymentForm">
                <div><h3>Payment</h3></div>
                <mat-grid-list cols="3" rowHeight="105px" rowWidth="200px">
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field appearance="outline">
                                <mat-label>Payment Type</mat-label>
                                <mat-select placeholder="Select Payment Type" formControlName="paymentType">
                                    <mat-option *ngFor="let payment of payments"
                                                [value]="payment.key">{{ payment.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field appearance="outline">
                                <mat-label>Payment Amount</mat-label>
                                <input type="text" maxlength="10" matInput placeholder="Payment Amount"
                                       formControlName="paymentAmount" required>
                                <mat-error>
                                    Please enter valid Payment Amount
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile class="pb-3">
                        <div class="text-inside">
                            <mat-form-field class="pb-3" appearance="outline">
                                <mat-label>Payment Date</mat-label>
                                <input formControlName="paymentDate" matInput [matDatepicker]="picker2">
                                <mat-hint>dd/mm/yyyy</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-error *ngIf="paymentDate.invalid && (paymentDate.dirty || paymentDate.touched)">
                                    <ng-container *ngIf="paymentDate.errors?.['required']">Date is required.
                                    </ng-container>
                                    <ng-container *ngIf="paymentDate.errors?.['futureDate']">Future dates are not
                                        allowed.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </ng-container>
    </div>
</div>
